
export interface UTMInterface {
    id: number;
    full_name: string;
    mobile_number: string;
    marketer: string;
}

class UTM {

    id: number;
    full_name: string;
    mobile_number: string;
    marketer: string;

    constructor(data: UTMInterface) {
        this.id = data.id;
        this.full_name = data.full_name;
        this.mobile_number = data.mobile_number;
        this.marketer = data.marketer;
    }

}

export default UTM;