import API from "../services/api";
import {AxiosResponse} from "axios";
import {SearchSettelmentsCredentials} from "../redux/dashboard/settlementOfAccounts/settlementOfAccountAction";

class SettlementOfAccountRequests {



    public static async getAllCheckouts(credentials: SearchSettelmentsCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`username=${encodeURIComponent(credentials.title)}`);
        }
            if (credentials.mobilenumber) {
                queryParams.push(`mobilenumber=${encodeURIComponent(credentials.mobilenumber.toString())}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPrice=${encodeURIComponent(credentials.minPrice)}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPrice=${encodeURIComponent(credentials.maxPrice)}`);
        }

        if (credentials.status) {
            queryParams.push(`status=${encodeURIComponent(credentials.status)}`);
        }

        // Always include the page parameter
        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `checkoutReseller?${queryString}`;

        // Send request to API
        return await API.authGet(url, { isUseLoading: true });
    }



    // public static async getAllCheckouts(credentials: SearchSettelmentsCredentials, i: number): Promise<AxiosResponse> {
    //     if (credentials.status) {
    //         @ts-ignore
            // queryParams['status'] = encodeURIComponent(credentials.status.toString());
        // }
        //
        // queryParams['page'] = i.toString();
        //
        // const queryString = new URLSearchParams(queryParams).toString();
        //
        // const url = `checkoutReseller?${queryString}`;
        // console.log('consol', url)
        //
        // return await API.authGet(url, { isUseLoading: true });
    // }
    static async changeCheckoutResellerStatus(credentials: {status: string, id: number}): Promise<AxiosResponse> {
        return await API.authPut(`changeCheckoutResellerStatus/${credentials.id}`, {
            data: {
                status: credentials.status
            },
            isUseLoading: true,
        });
    }
}



export default SettlementOfAccountRequests;