import API from "../services/api";
import {AxiosResponse} from "axios";
import {
    QuestionCreateCredentials,
    QuestionsCredentials,
    QuestionUpdateCredentials
} from "../redux/dashboard/questions/questionsAction";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";

class QuestionRequests {

    public static async getAllQuestions(id: string, credentials: QuestionsCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];
        // console.log('idididid', id)
        if (credentials.title) {
            queryParams.push(`title=${encodeURIComponent(credentials.title)}`);
        }

        if (credentials.type) {
            queryParams.push(`type=${encodeURIComponent(credentials.type)}`);
        }

        // همیشه پارامتر صفحه را اضافه کنید
        queryParams.push(`page=${i}`);

        // console.log('idididid', id)
        const queryString = queryParams.join('&');
        const url = `form/questions/${id}/getAllByQuestionFormId?${queryString}`;

        // ارسال درخواست به API
        return await API.authGet(url, { isUseLoading: true });
    }

    static async create(credentials: QuestionCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('form/questions', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`form/questions/${id}`, {isUseLoading: true});
    }

    static async update(credentials: QuestionUpdateCredentials): Promise<AxiosResponse> {
        credentials.type = credentials.question_type
        return await API.authPut(`form/questions/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default QuestionRequests;