import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser, convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {CartCheckoutCredentials} from "../redux/dashboard/cart/cartAction";
import {PlanCheckoutCredentials} from "../redux/dashboard/plans/buyPlanAction";
import {ShopCredentials} from "../redux/dashboard/addShop/addShopAction";

class ShopRequests {

    static async create(credentials: ShopCredentials) {

        const userType = checkUser();
        return await API.authPost("users/shop", {
            data: {
                username: credentials.username,
                f_name: credentials.f_name,
                l_name: credentials.l_name,
                mobile_number: credentials.mobile_number,
                // is_super_admin: credentials.is_super_admin,
                domain: credentials.domain,
                plan_id: credentials.plan_id,
                password: credentials.password,
                unique_name: credentials.unique_name,
            },
            isUseLoading: true,
        });
    }

    static async update(id: number) {
        return await API.authPut(`users/shop/${id}`);
    }
    static async blockUserFromShop(id: number) {
        return await API.authPut(`users/blockUser/${id}`);
    }
}


export default ShopRequests;