import API from "../services/api";
import {AxiosResponse} from "axios";
import {SearchResellerCredentials} from "../redux/dashboard/reseller/resellerAction";
import {SearchDiscountCodeCredentials} from "../redux/dashboard/discountCodes/discountCodesAction";

class ResellerRequests {


    public static async getAllResellers(credentials: SearchResellerCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`fullname=${encodeURIComponent(credentials.title)}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPrice=${encodeURIComponent(credentials.minPrice)}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPrice=${encodeURIComponent(credentials.maxPrice)}`);
        }


        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `resellerPurchases?${queryString}`;

        return await API.authGet(url, { isUseLoading: true });
    }
    // public static async getAllResellers(credentials: SearchResellerCredentials, i: number): Promise<AxiosResponse> {
    //     return await API.authGet(`resellerPurchases?page=${i  ?? ''}`);
    // }
    public static async checkout(): Promise<AxiosResponse> {
        return await API.authPost("checkoutReseller");
    }
    public static async getAllCountResellers(): Promise<AxiosResponse> {
        return await API.authGet("resellerPurchasesChart");
    }
    public static async getAllReferral(): Promise<AxiosResponse> {
        return await API.authGet("referral");
    }
    public static async getAllReferralCheckouts(): Promise<AxiosResponse> {
        return await API.authGet("referralCheckouts");
    }
    public static async checkoutReferral(): Promise<AxiosResponse> {
        return await API.authPost("referralCheckout");
    }
}



export default ResellerRequests;