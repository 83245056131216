import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import HighLightRequests from "../../../requests/highLightRequests";
import {parseNumber} from "react-advanced-cropper";

export const getHighlights = createAsyncThunk(
    'highLights/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await HighLightRequests.highLights()
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
export const groupStory = createAsyncThunk(
    'highLights/groupStory',
    async () => {

    }
)
