import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";


class OrderRequests {
    public static async getAnswersQuestion(id: number): Promise<AxiosResponse> {
        return await API.authGet(`form/answers/${id}`, {isUseLoading: true});
    }
    public static async getFormQuestionUsers(id: number, urls: string): Promise<AxiosResponse> {
        // const data = convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(JSON.parse(urls));
        return await API.authGet(`form/userAnswer/${id}/${urls}`, {
            // urls,
            isUseLoading: true,
            // onProgress: onUploadProgress, // Uncomment if needed
        });
    }
    public static async formQuestionAnswers(id: number): Promise<AxiosResponse> {
        return await API.authGet(`form/formQuestionAnswers/${id}`, {isUseLoading: true});
    }
}

export default OrderRequests;