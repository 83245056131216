import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import PlanRequests from "../../../requests/planRequests";


export interface PlanCheckoutCredentials {
    id: number,
    price: number | null,
}
export interface BuyPlanCheckoutResponse extends DefaultResponse {
}

export const buyPlan = async (credentials: PlanCheckoutCredentials): Promise<BuyPlanCheckoutResponse> => {
    try {

        const response: AxiosResponse = await PlanRequests.buyPlan(credentials)
        if (response.status === 200 || response.status === 201 || response.status === 202) {
            window.open(response.data);
            return {status: response.status, data: response.data}
        } else if (response.status === 422) {
            const errors = await response.data as LaravelValidationErrorType;
            toast422(errors);
            return {
                status: response.status,
                data: response.data
            }
        }
        toastError();
        return {
            status: response.status,
            data: response.data
        }
    } catch (e) {
        toastError();
        return {
            status: 500,
            data: {}
        }
    }
}
