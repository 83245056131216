import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {submit} from "redux/user/verifyCode/verifyCodeAction";

interface State extends DefaultStates {
    token: string | null;
}

const initialState: State = {
    token: null,
    loading: false,
    validationErrors: null
};

const VerifyCodeSlice = createSlice({
    name: 'verifyCode',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.loading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.token = response.data;
            }else if (response.status === 422) {
                state.validationErrors = response.data;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.loading = false;
        })
    },
});

export default VerifyCodeSlice.reducer;