import Media from "./media";

interface HighlightInterface {
    id: number;
    name: string;
    created_at: string;
    user_id?: number;
    cover: string;
    updated_at: string;
}

class Highlight {
    id: number;
    name: string;
    updated_at: string;
    cover: string;
    user_id?: number;
    created_at: string;

    constructor(data: HighlightInterface) {
        this.id = data.id;
        this.name = data.name;
        this.user_id = data.user_id;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.cover = data.cover;
    }
}

export default Highlight;