import React, {useState} from 'react';
import {DefaultBooleanField, DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {FormQuestionCreateCredentials, submit} from "../../../redux/dashboard/formQuestions/formQuestionsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {IconArrowRight} from "@tabler/icons-react";


const AddFormQuestionModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.formQuestions);
    const [isStatus, setIsStatus] = useState(false); // Initialize state for radio button selection
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleChangeStatus = () => {
        setIsStatus(!isStatus); // Toggle state on click (true -> false, false -> true)
    };

    return (
        <DefaultModal
            // onSubmit={handleDeleteConfirm}
            isOpen={isModalOpen}
            handleIsOpen={setIsModalOpen}
            label={'addFormQuestion'}  id='addFormQuestion' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    title: '',
                    status: true
                }}
                onSubmit={
                    (values: FormQuestionCreateCredentials, {resetForm, setSubmitting}: FormikHelpers<FormQuestionCreateCredentials>) => {
                        try {
                            dispatch(submit(values));

                            (document.querySelector("[for='addFormQuestion']") as HTMLElement).click();

                            resetForm();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: FormQuestionCreateCredentials) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'عنوان فرم را وارد کنید.';
                    }
                    if (!values.status) {
                        errors.status = 'وضعیت را انتخاب کنید';
                    }
                    return errors;
                }}>
                <Form method="dialog">
                    <label htmlFor={'addFormQuestion'}
                           className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                    <div className='flex-col justify-start md:mt-4  mt-12 items-center w-full flex'>

                        <DefaultInput
                            label='عنوان فرم سوال'
                            name="title"
                            type="text"
                            placeholder='عنوان فرم سوال'
                        ></DefaultInput>

                        {/*<input type="radio" name="status" className="radio radio-primary"/>*/}

                    </div>
                    <div className="modal-action w-full flex justify-start">

                        <button type="submit"
                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                submitLoading ? 'صبر کنید' : 'افزودن '
                            }
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addFormQuestion"
                            className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddFormQuestionModal;
