
interface PlanInterface {
    id: number;
    title: string;
    description: string;
    price: number;
    end_time?: number;
}

class Plan {

    id: number;
    title: string;
    description: string;
    price: number;
    end_time?: number;

    constructor(data: PlanInterface) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.price = data.price;
        this.end_time = data.end_time;
    }

}

export default Plan;