import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import DiscountCode from "../../../models/discountCode";
import {LaravelValidationErrorType} from "../../../utils/types";
import {pagination, toastError, toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/discountCodes/discountCodesAction";
import Payment from "../../../models/payment";
import discountCode from "../../../models/discountCode";

interface State extends DefaultStates {
    discountCodes: DiscountCode[];
    discountCodesList: DiscountCode[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    discountCodes: [],
    discountCodesList: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
    paginate: 1,
    // discountCodes: [],
    isEnd: false,
    loadingMore: false,
};

const DiscountCodesSlice = createSlice({
    name: 'discountCodes',
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.discountCodesList.map(e => e.unshift(new DiscountCode({...data, parentId: data.parent_id})));
                toastSuccess('کد تخفیف با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            } else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const title = response.data[1]
                const discountCodes: DiscountCode[] = [];
                // console.log('console.log', response.data[1])

                for (let i = 0; i < response.data[0].data.length; i++) {
                    // console.log('console.log', i)
                    const discountCode = response.data[0].data[i];
                    discountCodes.push(new DiscountCode({
                        id: discountCode.id,
                        code: discountCode.code,
                        name: discountCode.name,
                        amount: discountCode.amount,
                        category: discountCode.category,
                        product: discountCode.product,
                        type: discountCode.type
                    }));
                    // console.log('console.log', discountCodes)
                }

                if (response.data[0].data.length === 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1 ) {
                    state.discountCodesList.push(discountCodes);
                }
                else if (title && (title.title || title.price || title.product || title.category)) {
                    state.discountCodesList = [discountCodes];
                } else if (state.paginate === 1) {
                    state.discountCodesList = [discountCodes];
                } else {
                    state.discountCodesList.push(discountCodes);
                }

                // state.discountCodesList.push(discountCodes);
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true;
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            state.discountCodesList.forEach((contactList) => {
                const index = contactList.findIndex(contact => contact.id === response.data);
                if (index !== -1) {
                    contactList.splice(index, 1);
                }
            });
                // const index = state.discountCodes.findIndex(discountCode => discountCode.id === response.data);
                // state.discountCodes.splice(index, 1);
                // toastSuccess('کد تخفیف با موفقیت حذف شد');
        })
        builder.addCase(remove.rejected, (state, action) => {
                state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {

                    state.discountCodesList.forEach((contactList) => {
                        const index = contactList.findIndex(contact => contact.id === response.data.id);
                        contactList[index] = new DiscountCode(response.data);
                    })
                    toastSuccess('کد تخفیف با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    // toastError('کد تکراری است')
                    // console.log('errorValidation', response.data.message)
                    state.validationErrors = response.data as LaravelValidationErrorType;
                    // console.log('errorValidation')
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});
export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = DiscountCodesSlice.actions;


export default DiscountCodesSlice.reducer;