import API from "../services/api";
import {AxiosResponse} from "axios";

class LTVRequests {

    public static async getAllLTVs(): Promise<AxiosResponse> {
        return await API.authGet("customers/LTV", {isUseLoading: true});
    }

}



export default LTVRequests;