import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {submitShop, updateShop} from "./addShopAction";
import User from "../../../models/user";
import Contact from "../../../models/contact";

interface State extends DefaultStates {
    products: User[];
    submitLoading: boolean;
}

const initialState: State = {
    products: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const AddShopSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submitShop.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submitShop.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                state.products.unshift(new User({...data,
                    username: data.username,
                    f_name: data.f_name,
                    l_name: data.l_name,
                    mobile_number: data.mobile_number,
                    is_super_admin: data.is_super_admin,
                    user_type: data.user_type,
                    plan_id: data.plan_id,
                }));
                toastSuccess('کاربر فروشگاه با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submitShop.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(updateShop.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(updateShop.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                state.products.unshift(new User({...data, id: response.data.id}));
                toastSuccess('کاربر فروشگاه با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(updateShop.rejected, (state, action) => {
            state.submitLoading = false;
        })

    },
});

export default AddShopSlice.reducer;