import React from 'react';
import DefaultModal from "../../../components/modal";
import {IconArrowRight, IconLockSquareRounded} from "@tabler/icons-react";
const getBetterPlanModal = () => {


    return (
        <DefaultModal id='getBetterPlan'
                      label={'getBetterPlan'}
                      modalBoxClasses={`md:!max-w-3xl md:!w-max lg:min-w-[40%] md:h-max h-full overflow-x-hidden py-10 px-16`}>
            <label htmlFor={'getBetterPlan'} className={'cursor-pointer sticky -top-6 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                {/*<span className={'mr-2'}>افزودن</span>*/}
            </label>
            <div className={'flex flex-col justify-center items-center w-full'}>
                <IconLockSquareRounded color={'#DE0046'} size={56}/>
                <div className={'mt-7'}>
                    برای دسترسی به این قسمت پلن خود را ارتقاء دهید
                </div>
            </div>
        </DefaultModal>
    );
};

export default getBetterPlanModal;
