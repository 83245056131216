import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Category from "../../../models/category";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "./categoriesAction";
import Product from "../../../models/product";

interface State extends DefaultStates {
    categories: Category[];
    submitLoading: boolean;
}

const initialState: State = {
    categories: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
// دیتا رو نگه داری میکنیم
const CategoriesSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.categories.push(new Category({...data, parentId: data.parent_id}));
                toastSuccess('دسته بندی با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const categories: Category[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const category = response.data[i];

                    categories.push(new Category({id: category.id, title: category.title, parentId: category.parent_id, products: category.products, parent: category.parent, children: category.children, products_count: category.products_count}));
                }

                state.categories = categories;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )

        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.categories.findIndex(category => category.id === response.data);
                state.categories.splice(index, 1);
                toastSuccess('دسته بندی با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.categories.findIndex(category => category.id === response.data.id);
                state.categories[index] = new Category({...response.data, parent_id: response.data.parent_id});
                toastSuccess('دسته بندی با موفقیت ویرایش شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export default CategoriesSlice.reducer;