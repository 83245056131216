import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {RFMCredentials} from "../redux/dashboard/RFM/RFMAction";

class RFMRequests {

    public static async getAllRFM(credentials: RFMCredentials): Promise<AxiosResponse> {
        const today = new Date();
        return await API.authPost(`analysis/RFM`,{

            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase({

                "from": credentials.from === undefined ? new Date() : credentials.from,
                "until": credentials.until === undefined ? new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0] : credentials.until,

            }),
            isUseLoading: true,
        });
    }

}

export default RFMRequests;