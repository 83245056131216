import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {EightUserAction} from "redux/dashboard/users/EightUserAction";
import User from "../../../models/user";

interface State extends DefaultStates {
    mainUsers: User[];
    submitLoading: boolean;
}

const initialState: State = {
    mainUsers: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const EightUserSlice = createSlice({
    name: 'mainUsers',
    initialState,

    reducers: {

    },
    extraReducers: (builder) => {

        builder.addCase(EightUserAction.pending, (state) => {
           state.loading = true
        })
        builder.addCase(EightUserAction.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const users: User[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    users.push(new User({
                        l_name: contact.l_name,
                        banner: contact.banner,
                        avatar: contact.avatar,
                        description: contact.description,
                        email: contact.email,
                        f_name: contact.f_name,
                        isReseller: contact.isReseller,
                        is_blocked: contact.is_blocked,
                        user_type: contact.user_type,
                        mobile_number: contact.mobile_number,
                        username: contact.username,
                        video: contact.video,
                        wallet_amount: contact.walletAmount,
                        id: contact.id,
                        domain: contact.domain,
                        payments_count: contact.payments_count,
                    }));
                }
                state.mainUsers = users;

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(EightUserAction.rejected, (state, action) => {
                state.loading = false;
            }
        )
    },
});

export default EightUserSlice.reducer;