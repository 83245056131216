import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Question from "../../../models/question";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/questions/questionsAction";
import FormQuestion from "../../../models/formQuestion";

interface State extends DefaultStates {
    questions: Question[];
    questionsList: Question[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    questions: [],
    questionsList: [],
    submitLoading: false,
    paginate: 1,
    loading: false,
    loadingMore: false,
    isEnd: false,
    validationErrors: null,
};

const QuestionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.questionsList.map(e => e.unshift(new Question({...data, parentId: data.parent_id})));
                toastSuccess('فرم سوال با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            } else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const title = response.data[1]
                const questions: Question[] = [];

                for (let i = 0; i < response.data[0].length; i++) {
                    const question = response.data[0][i];
                    // console.log('console.log', formQuestion)
                    questions.push(new Question(question));
                }if (state.paginate === 1) {
                    state.questionsList = [questions];
                } else if (title && (title.title)) {
                    state.questionsList = [questions];
                } else {
                    state.questionsList.push(questions);
                }

                if (response.data[0].length === 0 || response.data[0].length < 30) {
                    state.isEnd = true;
                }

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.questions.findIndex(question => question.id === response.data);
                state.questions.splice(index, 1);
                toastSuccess('فرم سوال با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                    state.questionsList.forEach((contactList) => {
                        const index = contactList.findIndex(contact => contact.id === response.data.id);
                        contactList[index] = new Question(response.data);
                    })
                    // const index = state.questions.findIndex(question => question.id === response.data.id);
                    // state.questions[index] = new Question(response.data);
                    toastSuccess('فرم سوال با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export default QuestionsSlice.reducer;