import React from "react";
import DefaultModal from "../../../components/modal";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {useParams} from "react-router-dom";

export const UserAnswerInfo: React.FC = () => {

    const { userAnswersWithQuestions, loading} = useAppSelector((state: RootState) => state.userQuestionAnswersSlice);
    const onClose = () => {
        //await to close the modal then go back
        setIsOpen(false);
        setTimeout(() => {
            window.history.back();
        }, 300);
    }

    const [isOpen, setIsOpen] = React.useState(false);

    //on page load open the modal
    React.useEffect(() => {
        setIsOpen(true);
    }, []);

    const { questionId } = useParams(); // Extracts "questionId" from URL path
    //@ts-ignore
    // console.log('datadatas', userAnswersWithQuestions[0].ip.toString())
    //get cart
    return <DefaultModal id={'info'} modalBoxClasses={'transition overflow-x-hidden ease-in-out duration-300 max-w-6xl md:!min-w-[500px] sm:!min-w-[320px]'}  isOpen={isOpen} handleIsOpen={onClose} children={
        <div className={'flex flex-col'}>
                <div className={'flex flex-col'}>
                    {
                        loading ? <div></div> : <div>
                            {/*//@ts-ignore*/}
                            آیپی:   { userAnswersWithQuestions[0] ? userAnswersWithQuestions[0].ip.toString() : ''}
                            {userAnswersWithQuestions?.map((e) =>
                                    <div>
                                        <div className={'flex flex-col py-4 border-t border-t-gray-50'}>
                                            <div> سوال : {e.question?.title}</div>
                                            <div> پاسخ : {e.answer}</div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    }
                </div>
        </div>
    }/>
}