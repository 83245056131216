import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {remove} from 'redux/dashboard/customers/customersAction';
import {DeleteModal} from "../../../components/modal";
import {DefaultResponse} from "../../../redux/mainSlice";
import EditCustomerModal from './edit';
import Customer from "../../../models/customer";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import AddCustomerModal from "./add";
import Filter from "../filters/filter";
import {getAll} from "../../../redux/dashboard/customers/customersAction";
import {IconCirclePlus} from "@tabler/icons-react";
import {setPaginate} from "../../../redux/dashboard/customers/customerSlice";
import {pagination} from "../../../utils/funcions";

const CustomersList = () => {

    const {customersList, loading} = useAppSelector((state: RootState) => state.adminCustomers);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Customer | null>(null);
    const [editingItem, setEditingItem] = useState<Customer | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [title, setTitle] = useState<string>('');


    const lastSearchTimeRef = useRef(Date.now());
    const handleSearch = async () => {
        const currentTime = Date.now();
        if (currentTime - lastSearchTimeRef.current >= 2000) { // 10 seconds
            const data = {
                title: title,
            };

            try {
                // @ts-ignore
                dispatch(getAll(data));
            } catch (error) {
                alert('مشکلی پیش آمد.')
            }

            setTitle(''); // Clear title after search
            lastSearchTimeRef.current = currentTime; // Update the last search time
        } else {
            alert('   2 ثانیه یکبار اقدام به جستجو کنید.')
        }
    };
    const handleDeleteClick = (customer: Customer) => {
        setSelectedItem(customer);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201 && result.status !== 204) {
                setDeletedId(0);
            }
        }
    };

    const handleDeleteSearch = async () => {

        const data = {
            title: "",
        };

        try {
            dispatch(getAll(data))
        } catch (error) {
        }

        setTitle("");
    };

    useEffect(() => {
            pagination(() => {if (!store.getState().adminCustomers.loadingMore && !store.getState().adminCustomers.loading) {
                dispatch(setPaginate());
                // @ts-ignore
                dispatch(getAll(null));
            }})
        });


    return (
        <div className="flex-auto px-0 pt-0 pb-2">
            <DeleteModal
                title="حذف دسته بندی"
                message="آیا از حذف این دسته بندی مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditCustomerModal
                customer={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditCustomerModal>
                <AddCustomerModal/>
            <div
                className="p-4 mb-2 mt-8  md:w-[75%] lg:w-[90%]  border-b-0 border-b-solid rounded-t-2xl border-b-transparent md:flex-row flex flex-col justify-between items-start">
                <h6>کدهای تخفیف</h6>
                <div className={'flex w-max'}>
                    <Filter id={''} isOpen={isFilterOpen} modalBoxClasses={'w-full px-8'}
                            handleIsOpen={(e: boolean) => setIsFilterOpen(e)} children={
                        <div
                            className={`flex justify-start md:mr-64 absolute w-full ${isFilterOpen ? 'h-0' : 'h-max'}`}>
                            <label className="input input-bordered flex items-center gap-2 ml-6 px-0 p-2">
                                <input type="text" placeholder="حداقل 4 کاراکتر بنویسید..."
                                       value={title}
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') {
                                               handleSearch();
                                           }
                                       }}
                                       onChange={(e) => setTitle(e.target.value)}
                                       className={`input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full max-w-xs outline-none focus:outline-none`}/>
                                {
                                    title ? <IconCirclePlus className={'rotate-45 cursor-pointer'} onClick={() => {
                                        handleDeleteSearch();
                                    }}/> : <button
                                        onClick={() => handleSearch()} type={'submit'} className={'px-3'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                             className="w-4 h-4 opacity-70">
                                            <path fillRule="evenodd"
                                                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                                  clipRule="evenodd"/>
                                        </svg>

                                    </button>
                                }
                            </label>
                        </div>
                    }/>
                    <div className={'w-full flex justify-end mt-2 md:w-max'}>
                        <label htmlFor="addCustomer"
                               className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none mr-2 p-3 rounded-xl text-white">
                            <span className={'text-[15px]'}>افزودن</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-plus" width="24"
                                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 5l0 14"/>
                                <path d="M5 12l14 0"/>
                            </svg>
                        </label>
                    </div>
                </div>

            </div>

            <div className={`p-0 md:px-6 overflow-x-auto ps  ${isFilterOpen ? 'mt-2' : "md:mt-16 mt-28"}`}>
                {
                  loading ? <div className={'flex justify-center items-center w-full'}>
                          <LoadingTableShimmer />
                      </div> :
                <table
                    className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره موبایل</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            {/*ویرایش / حذف*/}
                        </th>
                    </tr>

                    </thead>
                    <tbody>

                    {
                        customersList.map(customers => customers.map((customer, i) => (
                        <tr key={customer.id.toString()}
                            className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} ${deletedId === customer.id ? '-translate-x-96 hidetr' : ''}`}>
                            <td className="px-6 py-3 pl-2 font-semibold text-start uppercase align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap ">{i + 1}</td>
                            <td className="px-6 py-3 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{customer.username}</td>
                            <td className="px-6 py-3 font-semibold text-start  align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                {customer.mobile_number}
                            </td>
                            <td className="px-6 py-3 flex font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                <div onClick={() => handleDeleteClick(customer)}
                                     className={'p-2 cursor-pointer bg-[#DE004625] w-10 h-10 duration-300 rounded-xl flex justify-center items-center'}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler text-[#DE0046] icon-tabler-trash" width="36"
                                         height="36" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M4 7l16 0"/>
                                        <path d="M10 11l0 6"/>
                                        <path d="M14 11l0 6"/>
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                    </svg>
                                </div>

                                <div className={'h-2 w-2'}>

                                </div>
                                <div onClick={() => setEditingItem(customer)}
                                     className={'duration-300 cursor-pointer bg-[#00DE0025] w-10 h-10 flex justify-center items-center rounded-xl'}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="group-hover:flex text-green-500 icon icon-tabler icon-tabler-edit"
                                         width="33" height="33" viewBox="0 0 24 24" strokeWidth="1.5"
                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"/>
                                        <path
                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"/>
                                        <path d="M16 5l3 3"/>
                                    </svg>
                                </div>
                            </td>
                        </tr>
                    )))}

                    </tbody>
                </table>}
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>
        </div>
    );
};

export default CustomersList;
