
export interface ShopPlanProperty {
    title: string;
    is_read_more: boolean;

}

export interface ShopPlanInterface {
    id: number;
    name: string;
    description: string;
    price: number;
    end_time: number;
    plan_name: string;
    created_at?: string;
    read_more_title: string;
    properties: ShopPlanProperty[];
}

class ShopPlan {

    id: number;
    name: string;
    description: string;
    price: number;
    end_time: number;
    created_at?: string;
    plan_name: string;
    read_more_title: string;
    properties: ShopPlanProperty[];

    constructor(data: ShopPlanInterface) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.price = data.price;
        this.end_time = data.end_time;
        this.plan_name = data.plan_name;
        this.read_more_title = data.read_more_title;
        this.created_at = data.created_at;
        this.properties = data.properties;
    }

}

export default ShopPlan;