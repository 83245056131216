import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {
    ContactAndCustomerCreateCredentials,
    ContactAndCustomerUpdateCredentials } from "../redux/dashboard/contactsAndCustomers/contactsAndCustomersAction";

class ContactAndCustomerRequests {

    public static async getAllContactsAndCustomers(): Promise<AxiosResponse> {
        return await API.authGet("get-contacts-customers", {isUseLoading: true});
    }

    static async create(credentials: ContactAndCustomerCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('campaign', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`get-contacts-customers/${id}`, {isUseLoading: true});
    }

    static async update(credentials: ContactAndCustomerUpdateCredentials): Promise<AxiosResponse> {
        return await API.authPut(`campaign/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default ContactAndCustomerRequests;