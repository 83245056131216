import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAll} from "redux/dashboard/lTV/lTVAction";
// import User from "../../../models/lTV";
import LTV from "../../../models/lTV";

interface State extends DefaultStates {
    lTV: LTV[];
    submitLoading: boolean;
}

const initialState: State = {
    lTV: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const LTVSlice = createSlice({
    name: 'lTV',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: LTV[] = [];
                for (let i = 0; i < response.data.users.length; i++) {
                    const contact = response.data.users[i];
                    contacts.push(new LTV({
                        l_name: contact.l_name,
                        banner: contact.banner,
                        avatar: contact.avatar,
                        description: contact.description,
                        email: contact.email,
                        f_name: contact.f_name,
                        isReseller: contact.isReseller,
                        mobile_number: contact.mobileNumber,
                        username: contact.username,
                        video: contact.video,
                        wallet_amount: contact.walletAmount,
                        id: contact.id,
                        ltv: contact.ltv,
                        user_type
                            : contact.user_type,
                        is_blocked: contact.is_blocked
                    }));
                }
                state.lTV = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        );
    },
});

export default LTVSlice.reducer;