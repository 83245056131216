import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import DiscountCodeRequests from "../../../requests/discountCodeRequests";
import {MediaType} from "../../../models/media";
// import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import {DiscountType} from "../../../models/discountCode";
import store from "../../store";

/*
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product*/

export interface DiscountCodeCredentials {
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    categoryId?: number,
    productId?: number
}

export interface DiscountCodeCreateCredentials extends DiscountCodeCredentials {
}

export interface DiscountCodeUpdateCredentials extends DiscountCodeCredentials {
    id: number,
}

export const submit = createAsyncThunk(
    'discountCodes/post',
    async (credentials: DiscountCodeCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await DiscountCodeRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)


export interface SearchDiscountCodeCredentials {
    title: string | null;
    price?: string | null; // Added price as optional
    product?: string | null; // Added price as optional
    category?: string | null; // Added price as optional
}

export const getAll = createAsyncThunk(
    'discountCodes/getAll',
    async (credentials?: SearchDiscountCodeCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await DiscountCodeRequests.getAllDiscountCodes(credentials ?? {} as SearchDiscountCodeCredentials, store.getState().adminDiscountCodes.paginate);

            if (response.status === 200) {
                return { status: response.status, data: [response.data, credentials]};
            } else if (response.status === 422) {
                const errors = response.data as LaravelValidationErrorType;
                toast422(errors);
                return { status: response.status, data: errors };
            } else {
                toastError();
                return { status: response.status, data: null };
            }
        } catch (e) {
            toastError();
            return { status: 500, data: null };
        }
    }
);


//remove
export const remove = createAsyncThunk(
    'discountCodes/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await DiscountCodeRequests.remove(id)
            if (response.status === 200 || response.status === 204) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'discountCodes/update',
    async (credentials: DiscountCodeUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await DiscountCodeRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: response.data
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
