import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser, convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {CartCheckoutCredentials} from "../redux/dashboard/cart/cartAction";
import {PlanCheckoutCredentials} from "../redux/dashboard/plans/buyPlanAction";
import {SearchPlanCredentials} from "../redux/dashboard/plans/buyedPlansAction";
import {SearchOrdersCredentials} from "../redux/dashboard/orders/orderAction";

class PlanRequests {


    public static async getAllPlans(): Promise<AxiosResponse> {
        const userType = checkUser();
        // console.log('console.log', userType)
            return await API.authGet(userType != 'super_admin' ? "plans" : "shopPlan", {isUseLoading: true});
    }




    public static async getAllBuyedPlans(credentials: SearchPlanCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.fullname) {
            queryParams.push(`fullname=${encodeURIComponent(credentials.fullname)}`);
        }
        if (credentials.mobilenumber) {
            queryParams.push(`mobilenumber=${encodeURIComponent(credentials.mobilenumber.toString())}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPrice=${encodeURIComponent(credentials.minPrice)}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPrice=${encodeURIComponent(credentials.maxPrice)}`);
        }

        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `buyedPlans?${queryString}`;

        return await API.authGet(url, { isUseLoading: true });
        // const queryString = new URLSearchParams(queryParams).toString();
        // const url = `buyedPlans?page=${i}${queryString}`;
        // return await API.authGet(url, {isUseLoading: true});
    }




    // public static async getAllBuyedPlans(credentials: SearchPlanCredentials, i: number): Promise<AxiosResponse> {
    //         return await API.authGet(`buyedPlans?page=${'page'=}&fullname=${credentials.fullname ?? ''}&mobilenumber=${credentials.mobilenumber ?? ''}&minPrice=${credentials.minPrice ?? ''}&maxPrice=${credentials.maxPrice ?? ''}`, {isUseLoading: true});
    // }
    static async checkout(credentials: PlanCheckoutCredentials): Promise<AxiosResponse> {
        const userType = checkUser();
        return await API.authPost("carts/checkout", {
            isUseLoading: true,
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
        });
    }

    static async create(credentials: { title: string; description: string; price: number, end_time?: number, planName?: string, readMoreTitle?: string, properties?: {
        title: string,
            is_read_more: boolean,
        }[] }): Promise<AxiosResponse> {

        const userType = checkUser();
        return await API.authPost(userType != 'super_admin' ? "plans" : "shopPlan", {
            data: {
                title: credentials.title,
                description: credentials.description,
                price: credentials.price,
                end_time: credentials.end_time,
                plan_name: credentials.planName,
                read_more_title: credentials.readMoreTitle,
                properties: credentials.properties,
            },
            isUseLoading: true,
        });
    }

    static async buyPlan(credentials: PlanCheckoutCredentials): Promise<AxiosResponse> {
        const userType = checkUser();
        return await API.authPost(userType != 'super_admin' ? `buyPlan/${credentials.id}` : `buyShopPlan/${credentials.id}` , {
            isUseLoading: true,
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id'])
            // data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
        });
    }

    // static async buyPlan(id: number): Promise<AxiosResponse> {
    //     console.log('dasffffffffffffasfaewfwsf', id)
    //     return await API.post(`buyPlan/${id}`, {
    //         isUseLoading: true,
    //     });
    // }

    // static async buyPlan(credentials: { description: string}) {
    //     return await API.authPost(`buyPlan/${credentials.description}`, {
    //         isUseLoading: true,
    //     });
    // }

    static async remove(id: number): Promise<AxiosResponse> {
        const userType = checkUser();
        return await API.authDelete(`${userType != 'super_admin' ? "plans" : "shopPlan"}/${id}`, {isUseLoading: true});
    }

    static async update(credentials: { id:number; title: string; description: string; price: number }): Promise<AxiosResponse> {
        const userType = checkUser();
        return await API.authPut(`${userType != 'super_admin' ? "plans" : "shopPlan"}/${credentials.id}`, {
            data: {
                title: credentials.title,
                description: credentials.description,
                price: credentials.price,
            },
            isUseLoading: true,
        });
    }
}


export default PlanRequests;