import React, {useEffect} from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {submit, update} from "../../../redux/dashboard/plans/plansAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Plan from "../../../models/plan";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";


interface values {
    title: string;
    description: string;
    price: number;
}


const EditPlan: React.FC<{ plan: Plan | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            plan,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, plans} = useAppSelector((state: RootState) => state.plans);

    //edit
    const handleEditConfirm = async (plan: Plan) => {
        const result: DefaultResponse = (await dispatch(update(plan))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
                <DefaultModal
                    label={'editPlan'}  id='editPlan' isOpen={!!plan} handleIsOpen={onClose} modalBoxClasses={'px-3'}>
                {plan ? (
                    <Formik
                        initialValues={{
                            title: plan.title || '',
                            description: plan.description || '',
                            price: plan.price || 0,
                        }}
                        onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm({
                                        title: values.title,
                                        id: plan!.id,
                                        description: values.description,
                                        price: values.price,
                                    })
                                );

                                (document.querySelector("[for='editPlan']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: values) => {
                            const errors: any = {};
                            if (!values.title) {
                                errors.title = 'نام پلن را وارد کنید';
                            }
                            if (!values.description
                            ) {
                                errors.description = 'توضیحات را وارد کنید';
                            }
                            if (!values.price) {
                                errors.price = 'قیمت پلن را وارد کنید';
                            }
                            return errors;
                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editPlan'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start items-center md:mt-4  mt-12 w-full flex'>


                                <DefaultInput
                                    label='نام پلن'
                                    name="title"
                                    type="text"
                                    placeholder='نام پلن'
                                ></DefaultInput>

                                <DefaultInput
                                    label='توضیحات'
                                    name="description"
                                    type="text"
                                    placeholder='توضیحات'
                                ></DefaultInput>
                                <DefaultInput
                                    label='قیمت'
                                    name="price"
                                    type="number"
                                    placeholder='قیمت'
                                ></DefaultInput>

                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editPlan"
                                    className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
                </DefaultModal>
    );
};

export default EditPlan;
