import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import CartRequests, {ICartArgument} from "../../../requests/cartRequests";
import store from "../../store";
import {remProgress} from "../../tools/progressSlice";
import discountCode from "../../../models/discountCode";

interface CheckDiscountCodeResponse extends DefaultResponse {
    code: string,
}

export type CartResponse = DefaultResponse & {
    id: number
}
export const changeCart = createAsyncThunk(
    'cart/changeCart',
    async (credentials: ICartArgument): Promise<CartResponse> => {
        try {
            const response: AxiosResponse = await CartRequests.changeCart(credentials)
            // 
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data, id: credentials.productId}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                    id: credentials.productId
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                    id: credentials.productId
                }
            }
            return {
                status: 200,
                data: null,
                id: credentials.productId
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null,
                id: credentials.productId
            }
        }
    }
)

    export interface CartCheckoutResponse extends DefaultResponse {
    }

export interface CartCheckoutCredentials {
    code: string,
    description: string,
    address: string
}

export const checkoutCart =  async (credentials: CartCheckoutCredentials): Promise<CartCheckoutResponse> => {
        try {
            const response: AxiosResponse = await CartRequests.checkout(credentials)
            if (response.status === 200 || response.status === 201 || response.status === 202) {
                window.open(response.data);
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: response.data
                }
            }
            toastError();
            return {
                status: response.status,
                data: response.data
            }
        } catch (e) {
            toastError();
            return {
                status: 500,
                data: {}
            }
        }
    }


export interface CartCheckDiscountCredentials {
    code: string,
    description: string,
    address: string
}

export const checkDiscountCart = createAsyncThunk(
    'cart/discountCodes',
    async (credentials: CartCheckDiscountCredentials): Promise<CheckDiscountCodeResponse> => {
        try {
            const response: AxiosResponse = await CartRequests.checkDiscount(credentials);
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data, code: credentials.code}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                    code: credentials.code
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                    code: credentials.code
                }
            }
            return {
                status: 200,
                data: null,
                code: credentials.code
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null,
                code: credentials.code
            }
        }
    }
)


export const getAll = createAsyncThunk(
    'cart/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CartRequests.getCart()
            if (response.status === 200 || response.status === 201 ) {
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


