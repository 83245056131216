import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {IconChevronDown, IconEdit, IconChevronUp, IconX, IconSearch, IconFilter} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";
import logo from "../../../assets/img/No data-pana-min.png";
import { NetworkImage } from "../../../utils/funcions";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import Product from "../../../models/product";
import EditProductModal from "../products/edit";
import {setPaginateToStart} from "../../../redux/dashboard/products/productsSlice";
import {getAll} from "../../../redux/dashboard/products/productsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import img from '../../../assets/img/No data-pana-min.png'


function WarehouseProductsDetails() {
    const { productsList, loading } = useAppSelector((state: RootState) => state.adminProducts);
    const [copy, setCopy] = useState<boolean>(true);
    const [editingItem, setEditingItem] = useState<Product | null>(null);
    // const [tabBar, setTabBar] = useState<number>(1); // شروع با تب بار 1 (محصولات فعال)
    const [sortOrder, setSortOrder] = useState<string>('desc'); // مرتب‌سازی نزولی
    const [sortColumn, setSortColumn] = useState<string>('quantity'); // ستون مرتب‌سازی
    const [searchTerm, setSearchTerm] = useState<string>(''); // مقدار جستجو

    const [tabBar, setTabBar] = useState<number>(1);
    const [delayedColor, setDelayedColor] = useState<string>('text-white'); // رنگ اولیه برای تاخیر
    const [delayedColorGold, setDelayedColorGold] = useState<string>('text-white'); // رنگ اولیه برای تاخیر

    const handleChangeTabBar = (tab: number) => {
        // رنگ متن را بلافاصله تغییر می‌دهیم
        setTabBar(tab);

        // پس از 1 ثانیه رنگ متن را تغییر می‌دهیم
        setTimeout(() => {
            setDelayedColor(tab === 1 ? 'text-white' : 'text-[#D4AF37]');
            setDelayedColorGold(tab === 1 ? 'text-[#D4AF37]' : 'text-white');
        }, 100); // تغییر رنگ بعد از 1 ثانیه
    };

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('desc');
        }
    };

    const getSortedProducts = (products: Product[]) => {
        return products.sort((a, b) => {
            if (sortOrder === 'asc') {
                //@ts-ignore
                return a[sortColumn] - b[sortColumn];
            } else {
                //@ts-ignore
                return b[sortColumn] - a[sortColumn];
            }
        });
    };

    // فیلتر کردن و مرتب‌سازی محصولات بر اساس تب بار، عنوان و جستجو
    const filteredProducts = productsList.map(products =>
        getSortedProducts(products.filter(product =>
            (tabBar === 1 ? product.is_product_active : !product.is_product_active) &&
            product.title.toLowerCase().includes(searchTerm.toLowerCase()) // فیلتر بر اساس جستجو
        ))
    );

    // محاسبه تعداد محصولات فعال و غیرفعال
    const activeProductsCount = productsList.reduce((acc, products) => acc + products.filter(product => product.is_product_active).length, 0);
    const inactiveProductsCount = productsList.reduce((acc, products) => acc + products.filter(product => !product.is_product_active).length, 0);

    const [title, setTitle] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());

    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleSearch = async () => {
        dispatch(setPaginateToStart())
        const data = { title, minPrice, maxPrice, status };

        try {
            dispatch(getAll({isReturnCache: true, credentials: data}));
        } catch (error) {
            alert('مشکلی پیش آمد.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle("");
        handleSearch()
    };

    const handleDeleteSearchByMinPayment = () => {
        setMinPrice("");
    };
    const handleDeleteSearchByMaxPayment = () => {
        setMaxPrice("");
    };
    const handleDeleteSearchByStatus = () => {
        setStatus("");
    };

    return (
        <div className="flex flex-col pr-4">
            <EditProductModal
                product={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            />
            <div className="flex w-full justify-between">
                <span className="flex mb-5 items-center gap-x-2 md:px-0 relative px-4">
                    <div
                        className={`absolute bg-[#D4AF37] z-2 mt-1 rounded-lg ${tabBar === 1 ? 'w-[160px] h-8' : 'w-[170px] h-8 mr-40'} duration-300`}
                    ></div>
                    <div
                        onClick={() => handleChangeTabBar(1)}
                        className={`px-3 py-3 duration-300 cursor-pointer z-[41] gap-x-2 justify-center items-center flex w-max text-[#D4AF37] p-3 rounded-xl ${
                            tabBar === 1 ? delayedColor : 'text-[#D4AF37]'
                        }`}
                    >
                        <span className="text-[15px] mr-2">محصولات فعال ({activeProductsCount})</span>
                    </div>

                    <div
                        onClick={() => handleChangeTabBar(2)}
                        className={`px-3 py-3 duration-300 cursor-pointer gap-x-2 z-[41] justify-center items-center flex w-max ${
                            tabBar === 2 ? delayedColorGold : 'text-[#D4AF37]'
                        } p-3 rounded-xl`}
                    >
                        <span className="text-[15px]">محصولات غیرفعال ({inactiveProductsCount})</span>
                    </div>
                </span>
                <div className="flex flex-col">
                    <div className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="نام محصول"
                                value={title}
                                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px] border-none input-primary max-h-[20px] min-w-[250px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            <button
                                onClick={handleSearch}
                                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                            >
                                <IconSearch size={'24'}/>
                            </button>
                        </div>
                    </div>
                    <div className="flex mt-2">
                        {filteredProducts.length > 0 && (
                            <span className="text-sm text-gray-500">تعداد نتایج: {filteredProducts.flat().length}</span>
                        )}
                    </div>
                </div>
            </div>

            {/*<div className={'w-[100px] h-[100px] rounded-full'}>*/}
            {/*    <img*/}
            {/*        src={img}*/}
            {/*        alt=""*/}
            {/*        className={'w-full h-full object-cover rounded-full shadow-[0_0_60px_15px_rgba(0,0,255,0.3)] shadow-green-900 bg-transparent'}*/}
            {/*    />*/}
            {/*</div>*/}


            <div className="overflow-x-auto w-full">
                <table className="items-center w-full mt-[60px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            تصویر
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            عنوان
                        </th>
                        <th
                            className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 cursor-pointer flex items-center gap-x-2"
                            onClick={() => handleSort('quantity')}
                        >
                            تعداد
                            {sortColumn === 'quantity' && (
                                sortOrder === 'asc' ? <IconChevronUp/> : <IconChevronDown/>
                            )}
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            وضعیت
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredProducts.map((products, i) =>
                        products.map(product => (
                            <tr
                                key={product.id}
                                className={`group transition-all border-b border-b-gray-200 mt-1 hover:bg-neutral-100 ${
                                    i % 2 === 1 ? 'bg-[#D4AF3712]' : 'bg-white'
                                } items-start justify-center`}
                            >
                                <td className="px-6 py-4 flex items-center font-semibold text-start align-middle shadow-none tracking-none whitespace-nowrap">
                                    {product.media[0] ? (
                                        <NetworkImage
                                            url={product.media[0].thumbnail}
                                            className="w-12 object-cover h-12 rounded-full"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={dLoad}
                                            alt=""
                                            className="w-12 h-12 rounded-full object-cover"
                                        />
                                    )}
                                </td>
                                <td className="px-6 py-2 pl-2 font-semibold text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap">
                                    {product.title}
                                </td>
                                <td className="px-6 py-2 font-semibold text-start uppercase align-middle text-md border-b-solid tracking-none whitespace-nowrap">
                                        <span
                                            className={`${
                                                product.quantity <= 5 && 'text-[#DE0046]'
                                            } rounded-full px-2 py-[1.5px]`}
                                        >
                                            {product.quantity}
                                        </span>
                                </td>
                                <td className="px-6 py-2 font-semibold text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap">
                                        <span
                                            className={`${
                                                product.is_product_active
                                                    ? 'bg-green-500'
                                                    : 'bg-[#DE0046]'
                                            } rounded-xl text-white px-2 py-[1.5px]`}
                                        >
                                            {product.is_product_active ? 'فعال' : 'غیرفعال'}
                                        </span>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
                {productsList.every(e => e.length === 0) && !loading ? (
                    <div className="w-full flex flex-col justify-center items-center">
                        <img src={logo} alt="" className="w-[30%]"/>
                        <span className="text-lg">محصولی وجود ندارد</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default WarehouseProductsDetails;

