import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ShopRequests from "../../../requests/ShopRequests";
import {MediaType} from "../../../models/media";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import HighLightRequests from "../../../requests/highLightRequests";
import Category from "../../../models/category";
import CategoryRequests from "../../../requests/categoryRequests";
import {parseNumber} from "react-advanced-cropper";

export interface ShopCredentials {
    username: string,
    f_name: string,
    l_name: string,
    mobile_number: string,
    // is_super_admin: boolean,
    domain: string,
    plan_id: number,
    unique_name: string,
    password: string,
}

export interface ShopCreateCredentials extends ShopCredentials {
}


export const submitShop = createAsyncThunk(
    'shops/post',
    async (credentials: ShopCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ShopRequests.create(credentials)
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)
export const updateShop = createAsyncThunk(
    'shops/put',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ShopRequests.update(id)
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)