import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import DiscountCode from "../../../models/discountCode";
import {LaravelValidationErrorType} from "../../../utils/types";
import DiscountProducts from "../../../models/discountProducts";
import {getAllDiscountProducts} from "./discountProductsAction";

interface State extends DefaultStates {
    discountProducts: DiscountProducts[];
    submitLoading: boolean;
}

const initialState: State = {
    discountProducts: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};


const DiscountProductsSlice = createSlice({
    name: 'discountProducts',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllDiscountProducts.pending, (state) => {
                state.loading = true;
        })
        builder.addCase(getAllDiscountProducts.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                console.log('errorValidation', response.data)
                const discountProducts: DiscountProducts[] = [];

                for (let i = 0; i < response.data.length; i++) {
                    // console.log('console.log', i)
                    const discountCode = response.data[i];
                    discountProducts.push(new DiscountProducts({
                        id: discountCode.id,
                        title: discountCode.title
                    }));
                }
                state.discountProducts = discountProducts

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllDiscountProducts.rejected, (state, action) => {
                state.loading = false;
            }
        )

    },
});


export default DiscountProductsSlice.reducer;