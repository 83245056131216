import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {ChartDataInterface} from "./AnalyticsDashboard";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {loadingComponent, NetworkImage} from "../../../utils/funcions";

export const AdvancedAnalyticsPage: React.FC<{}> = () => {
    const {data, loading} = useAppSelector((state: RootState) => state.analytics);
    const {shop} = useAppSelector((state: RootState) => state.user);

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    //table of clv and table with tree tab for categorize


    //tabs
    const tabs = [
        {
            title: "عالی",
            data: data.categorize.Best,
        },
        {
            title: "متوسط",
            data: data.categorize.Average,
        },
        {
            title: "ضعیف",
            data: data.categorize.Bad,
        },
    ];

    useEffect(() => {
    //     get first clvtab and set it to checked
        const tab = document.querySelector('.clvtab') as HTMLInputElement;
        tab.checked = true;
    }, []);


    return (
        loading ? loadingComponent() : <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
            <div className="flex flex-col w-full p-10">
                {/*clv ارزش مشتریان*/}
                <div className="flex items-center gap-2 my-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         className="stroke-info shrink-0 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <span>ارزش طول عمر مشتری Customer Lifetime Value یا به اختصار (CLV) معیاری است که به کسب‌وکارها کمک می‌کند تا درآمدی را که از یک مشتری در طول مدت زمان ارتباطش با کسب‌وکار انتظار می‌رود، پیش‌بینی کنند. این شاخص برای تصمیم‌گیری‌های استراتژیک در بازاریابی و مدیریت مشتریان بسیار مهم است. </span>
                </div>
<div className="overflow-x-auto bg-base-100 border-base-300 rounded-box p-6">
                    <table className="table w-full">
                        <thead>
                        <tr>
                            <th>ردیف</th>
                            <th>نام و نام خانوادگی</th>
                            <th>ارزش</th>
                            <th>Lifespan</th>
                            <th>APV</th>
                            <th>Frequency</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.clv.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <div className="flex items-center gap-2">
                                        <NetworkImage url={item.avatar ?? shop!.avatar} alt={item.f_name}
                                             className="w-10 h-10 rounded-full"/>
                                        <span>{item.f_name} {item.l_name}</span>
                                    </div>
                                </td>
                                <td>{item.clv}</td>
                                <td>{item.lifespan}</td>
                                <td>{item.apv}</td>
                                <td>{item.frequency}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div role="alert" className="alert my-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         className="stroke-info shrink-0 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <span>
                    دسته بندی مشتریان شما بر اساس ارزش مشتریان به سه دسته عالی، متوسط و ضعیف تقسیم شده است. این دسته
                    بندی بر اساس میانگین مقدار خرید هر مشتری انجام شده است.
                </span>
                </div>
                <div role="tablist" className="tabs tabs-lifted">
                    {tabs.map((tab, index) => (
                        <>
                            <input type="radio" name="my_tabs_2" role="tab" className="tab clvtab" aria-label={tab.title}/>
                            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">

                                <div className="overflow-x-auto">
                                    <table className="table w-full">
                                        <thead>
                                        <tr>
                                            <th>ردیف</th>
                                            <th>نام</th>
                                            <th>تعداد کل</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tab.data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div className="flex items-center gap-2">
                                                        <NetworkImage url={item.avatar ?? shop!.avatar} alt={'پروفایل'}
                                                             className="w-10 h-10 rounded-full"/>
                                                        <span>{item.f_name} {item.l_name}</span>
                                                    </div>
                                                </td>
                                                <td>{item.total_count}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ))}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}