import { createSlice } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
import Question from "../../../models/question";
import {getFormQuestionUserAnswer} from "./userAnswersOnQuestionAction";
import Answer from "../../../models/answer";

// Interface for the state with additional properties
interface State extends DefaultStates {
    userAnswersWithQuestions: Answer[]|null;
    submitLoading: boolean;
}
const initialState: State = {
    userAnswersWithQuestions: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const UserQuestionAnswersSlice = createSlice({
    name: 'questionsUserAnswers',
    initialState,
    reducers: {}, // No reducers defined in this slice
    extraReducers: (builder) => {
        builder.addCase(getFormQuestionUserAnswer.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFormQuestionUserAnswer.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;

            if (response.status === 200) {
                const contacts: Answer[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    contacts.push(new Answer({
                        id: contact.id,
                        ip: contact.ip,
                        question: contact.question,
                        answer: contact.answer,
                        form_question_id: contact.form_question_id
                    }));
                }
                state.userAnswersWithQuestions = contacts;
            }
            else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });
        builder.addCase(getFormQuestionUserAnswer.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export default UserQuestionAnswersSlice.reducer;
