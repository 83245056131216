import Category from "./category";
import Product from "./product";

export enum DiscountType {
    PERCENT = "percent",
    FIXED = "fixed",
}

interface DiscountCodeInterface {
    id: number,
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product
}

class DiscountCode {

    id: number;
    name: string;
    code: string;
    type: DiscountType;
    amount: number;
    category?: Category;
    product?: Product;

    constructor(data: DiscountCodeInterface) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
        this.type = data.type;
        this.amount = data.amount;
        this.category = data.category;
        this.product = data.product;
    }

}

export default DiscountCode;