import { createSlice } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
import Question from "../../../models/question";
import {getFormQuestionUsers} from "./formQuestionUsersAction";
import Answer from "../../../models/answer";
import LTV from "../../../models/lTV";
import answer from "../../../models/answer";
import * as stream from "node:stream";

interface State extends DefaultStates {
    questionAnswers: Answer[];
    questionAnswersList: Answer[][];
    paginate: number;
    urls: string;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}
const initialState: State = {
    questionAnswers: [],
    questionAnswersList: [],
    urls: '',
    submitLoading: false,
    loading: false,
    validationErrors: null,
    paginate: 1,
    // discountCodes: [],
    isEnd: false,
    loadingMore: false,
};

const FormQuestionUsersSlice = createSlice({
    name: 'questionAnswers',
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFormQuestionUsers.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            } else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getFormQuestionUsers.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Answer[] = [];
                for (let i = 0; i < response.data[0].length; i++) {
                    // console.log('road2', response.data[0][i].ip)
                    const contact = response.data[0][i];
                    contacts.push(new Answer({
                        id: contact.id,
                        ip: contact.ip,
                        question: contact.question,
                        answer: contact.answer,
                        form_question_id: contact.form_question_id
                    }));
                }
                console.log('load3')
                state.questionAnswers = contacts;
            }
            else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getFormQuestionUsers.rejected, (state, action) => {
            console.log('reloadest')
            state.loading = false;
        });

    },
});
export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = FormQuestionUsersSlice.actions;

export default FormQuestionUsersSlice.reducer;
