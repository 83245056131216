import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAll} from "redux/dashboard/lTV/lTVAction";
// import User from "../../../models/lTV";
import LTV from "../../../models/lTV";
import Category from "../../../models/category";
import {getCategoriesForAddProduct} from "./catForAddProdAction";

interface State extends DefaultStates {
    cat: Category[];
    submitLoading: boolean;
}

const initialState: State = {
    cat: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const CatForAddProdSlice = createSlice({
    name: 'getCategoriesForAddProduct',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getCategoriesForAddProduct.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getCategoriesForAddProduct.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const categories: Category[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    const category = response.data.data[i];

                    categories.push(new Category({id: category.id, title: category.title, parentId: category.parent_id, products: category.products, parent: category.parent, children: category.children, products_count: category.products_count}));
                }

                state.cat = categories;

                // console.log('console.log', state.cat)
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getCategoriesForAddProduct.rejected, (state, action) => {
                state.loading = false;
            }
        )
    },
});

export default CatForAddProdSlice.reducer;