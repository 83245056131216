import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ProductRequests from "../../../requests/productRequests";
import { matchPath } from 'react-router'
import { useParams } from 'react-router-dom';
import {parseNumber} from "react-advanced-cropper";
import store from "../../store";


export const getProductById = createAsyncThunk(
    'products/getProductById',
    async (data: {[key: string]: any}): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ProductRequests.getProductById(parseNumber(data.id))
            if (response.status === 200) {
                const {shop} = store.getState().user;
                response.data.data = {...response.data.data, ...{shop: shop}}
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
