import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllCountResellers, getAllResellers} from "./resellerAction";
import UserWithPayments from "../../../models/userWithPayments";
import {useSelector} from "react-redux";
import store, {RootState} from "../../store";
import reseller from "../../../pages/front/reseller";
import Checkout from "../../../models/checkout";

interface State extends DefaultStates {
    resellerPayments: UserWithPayments|null;
    resellerPaymentsList: UserWithPayments[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    resellerPayments: null,
    resellerPaymentsList: [],
    submitLoading: false,
    paginate: 1,
    loading: false,
    loadingMore: false,
    validationErrors: null,
    isEnd: false,
};
const ResellerSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },
        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAllResellers.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAllResellers.fulfilled, (state, action) => {

            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const title = response.data[1]
                const resellerPayments = response.data[0];
                // console.log('response: ', resellerPayments.data)
                const contacts: UserWithPayments[] = [];
                contacts.push(new UserWithPayments({
                    data: resellerPayments.data,
                    // count: resellerPayments.data.count
                }));

                // console.log('response', contacts[0].data)
                if (response.data[0].data.length === 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1 ) {
                    //@ts-ignore
                    state.resellerPaymentsList.push(contacts[0].data);
                }
                else if (title && (title.title || title.price || title.product || title.category)) {

                    //@ts-ignore
                    state.resellerPaymentsList = [contacts[0].data];
                } else if (state.paginate === 1) {

                    //@ts-ignore
                    state.resellerPaymentsList = [contacts[0].data];
                } else {

                    //@ts-ignore
                    state.resellerPaymentsList.push(contacts[0].data.data);
                }
                // console.log('state.resellerPaymentsList', state.resellerPaymentsList)

            } else if (response.status === 422) {
                
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllResellers.rejected, (state, action) => {
                state.loading = false;
            }
        ).addCase("setLoadingMore/pending", (state) => {
            state.loadingMore = true;
        })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });

    },
});

export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = ResellerSlice.actions;

export default ResellerSlice.reducer;