import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import Customer from "../../../models/customer";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/customers/customersAction";
import UserWithPayments from "../../../models/userWithPayments";

interface State extends DefaultStates {
    customers: Customer[];
    customersList: Customer[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    customers: [],
    customersList: [],
    submitLoading: false,
    paginate: 1,
    loading: false,
    isEnd: false,
    loadingMore: false,
    validationErrors: null,
};

const CustomersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.customers.unshift(new Customer({...data, parentId: data.parent_id}));
                toastSuccess('مخاطب با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            const title = response.data[1]
            if (response.status === 200) {
                const customers: Customer[] = [];
                for (let i = 0; i < response.data[0].length; i++ ) {
                    const customer = response.data[0][i];
                    customers.push(new Customer({
                        id: customer.id,
                        f_name: customer.f_name,
                        mobile_number: customer.mobile_number,
                        l_name: customer.l_name,
                        avatar: customer.avatar,
                        email: customer.email,
                        description: customer.description,
                        username: customer.username,
                        is_reseller: false
                    }));
                }
                if (response.data[0].length == 0) {
                    state.isEnd = true
                }
                if (!title) {
                    state.customersList.push(customers);
                }else {
                    state.customersList = []
                    state.customersList.push(customers);

                }
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.customers.findIndex(customer => customer.id === response.data);
                state.customers.splice(index, 1);
                toastSuccess('مخاطب با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                    const index = state.customers.findIndex(customer => customer.id === response.data.id);
                    state.customers[index] = new Customer({
                        id: response.data.id,
                        f_name: response.data.f_name,
                        mobile_number: response.data.mobile_number,
                        l_name: response.data.l_name,
                        avatar: response.data.avatar,
                        email: response.data.email,
                        description: response.data.description,
                        username: response.data.username,
                        is_reseller: response.data.is_reseller,
                    });
                    toastSuccess('مخاطب با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});
export const { setPaginate } = CustomersSlice.actions;

export default CustomersSlice.reducer;