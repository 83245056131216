import React, {useEffect, useRef, useState} from "react";
import {AiOutlineDollar} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import store, {RootState} from "../../../redux/store";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {getSettingByKey, moneyFormat} from "../../../utils/funcions";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowDown, IconArrowRight} from "@tabler/icons-react";
import {getAllResellers} from "../../../redux/dashboard/reseller/resellerAction";
import {ThunkDispatch} from "@reduxjs/toolkit";
import moment from 'jalali-moment'
import {setPaginate} from "../../../redux/dashboard/reseller/resellerSlice";
import ReactApexChart from "react-apexcharts";
export const ResellerChartPartial: React.FC = () => {

    const { resellerChart, loading } = useSelector((state: RootState) => state.resellerChart);

    // useEffect برای رسم نمودار
    // useEffect(() => {
    //     if (resellerChart && resellerChart.length > 0) {
    //         const series = {
    //             prices: resellerChart.map(e => e['count']),
    //             dates: resellerChart.map(e => {moment(e['day'], 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')})
    //         };
    //         const options = {
    //             series: [{
    //                 name: '',
    //                 type: 'area',
    //                 data: series.prices
    //             }],
    //             chart: {
    //                 height: 350,
    //                 type: 'line',
    //             },
    //             stroke: {
    //                 curve: 'smooth'
    //             },
    //             fill: {
    //                 type: 'solid',
    //                 opacity: [0.35, 1],
    //             },
    //             labels: resellerChart.map(e => e['day']),
    //             markers: {
    //                 size: 0
    //             },
    //             yaxis: [
    //                 {
    //                     title: {
    //                         text: '',
    //                     },
    //                 },
    //                 {
    //                     opposite: true,
    //                     title: {
    //                         text: '',
    //                     },
    //                 },
    //             ],
    //             tooltip: {
    //                 shared: true,
    //                 intersect: false,
    //                 y: {
    //                     formatter: function (y: number) {
    //                         if (typeof y !== "undefined") {
    //                             return y.toFixed(0) + "تعداد";
    //                         }
    //                         return y;
    //                     }
    //                 }
    //             }
    //         };
    //         const chart = new ApexCharts(document.querySelector("#chart"), options);
    //         chart.render();
    //     }
    // }, [resellerChart]);
    return (
        loading || resellerChart == null ? <div><LoadingTableShimmer/></div> : <div className={'mt-10'}>
            <ReactApexChart options={
                {
                    //     disabling all labels and titles only showing chart
                    //     and its data
                    chart: {
                        /*toolbar: {
                            show: false,
                        },
                        sparkline: {
                            enabled: true,
                        },*/
                        zoom: {
                            enabled: false,
                        },

                    },
                    labels: resellerChart.map(e => moment(e['day'], 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')),

                    dataLabels: {
                        // enabled: false,
                    },
                    stroke: {
                        curve: "smooth",
                    },
                    colors: ['#00c90d'],
                    // yaxis: {
                    //     labels: {
                    //         show: 720,
                    //         offsetX: -7,
                    //     },
                    // },
                    grid: {
                        // show: false,
                    },
                    tooltip: {
                        // enabled: false,
                    },
                    legend: {
                        // show: false,
                    },
                    xaxis: {
                        labels: {
                            rotate: 20,
                            maxHeight: 60,
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 100],
                        },

                    },
                }
            } series={
                [
                    {
                        data: resellerChart!.map(e => e['count']),
                    }
                ]
            } type="area" height={200}/>
        </div>
    )
}
export default ResellerChartPartial;