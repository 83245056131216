import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import PlanRequests from "../../../requests/planRequests";
import store from "../../store";

export interface SearchPlanCredentials {
    fullname: string | null;
    minPrice?: string | null; // Added price as optional
    maxPrice?: string | null; // Added price as optional
    mobilenumber?: string | null;
}

export const getAllBuyed = createAsyncThunk(
    'buyedPlans/getAll',
    async (credentials?: SearchPlanCredentials): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.getAllBuyedPlans(credentials ?? {} as SearchPlanCredentials, store.getState().buyedPlans.paginate)
            if (response.status === 200) {
                return {status: response.status, data: [response.data, credentials]}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const removePlan = createAsyncThunk(
    'plans/remove',
    async (id: number): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export interface PlanCheckoutResponse extends DefaultResponse {
}

export interface PlanCheckoutCredentials {
    price: string
}

// export const checkoutCart =  async (credentials: PlanCheckoutCredentials): Promise<PlanCheckoutResponse> => {
//     try {
//         const response: AxiosResponse = await CartRequests.checkout(credentials)
//         if (response.status === 200 || response.status === 201 || response.status === 202) {
//             window.open(response.data);
//             return {status: response.status, data: response.data}
//         } else if (response.status === 422) {
//
//             const errors = await response.data as LaravelValidationErrorType;
//             toast422(errors);
//             return {
//                 status: response.status,
//                 data: response.data
//             }
//         }
//         toastError();
//         return {
//             status: response.status,
//             data: response.data
//         }
//     } catch (e) {
//         toastError();
//         return {
//             status: 500,
//             data: {}
//         }
//     }
// }



export const update = createAsyncThunk(
    'plans/update',
    async (credentials: {id: number, title: string, description: string, price: number}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: credentials}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
//update
