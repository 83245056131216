import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import FormQuestion from "../../../models/formQuestion";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/formQuestions/formQuestionsAction";
import formQuestion from "../../../models/formQuestion";
import DiscountCode from "../../../models/discountCode";

interface State extends DefaultStates {
    formQuestions: FormQuestion[];
    formQuestionsList: FormQuestion[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    formQuestions: [],
    formQuestionsList: [],
    submitLoading: false,
    paginate: 1,
    loading: false,
    loadingMore: false,
    isEnd: false,
    validationErrors: null,
};

const FormQuestionsSlice = createSlice({
    name: 'formQuestions',
    initialState,
    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.formQuestionsList.map((e) => e.unshift(new FormQuestion({...data, parentId: data.parent_id})))
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            if (state.paginate === 1) {
            state.loading = true;
        } else {
            state.loadingMore = true;
        }
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const title = response.data[1]
                const formQuestions: FormQuestion[] = [];
                // if (response.data[0].data) {
                    for (let i = 0; i < response.data[0].data.length; i++) {
                        const formQuestion = response.data[0].data[i];
                        // console.log('console.log', formQuestion)
                        formQuestions.push(new FormQuestion({
                            id: formQuestion.id,
                            title: formQuestion.title,
                            status: formQuestion.status,
                            questions: formQuestion.questions,
                            created_at: formQuestion.created_at,
                            // questions: formQuestion.questions,
                        }));
                    }if (state.paginate === 1) {
                    state.formQuestionsList = [formQuestions];
                } else if (title && (title.title || title.price || title.product || title.category)) {
                    state.formQuestionsList = [formQuestions];
                } else {
                    state.formQuestionsList.push(formQuestions);
                }

                if (response.data[0].data.length === 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }


                // state.formQuestions = formQuestions;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        ).addCase("setLoadingMore/pending", (state) => {
            state.loadingMore = true;
        })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {

                const user = state.formQuestions;
                console.log('res;', response.data, user)
                const index = user.findIndex(formQuestion => formQuestion.id === response.data);
                state.formQuestions.splice( index, 1)
                toastSuccess('فرم سوال با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                    state.formQuestionsList.forEach((contactList) => {
                        const index = contactList.findIndex(contact => contact.id === response.data.id);
                        contactList[index] = new FormQuestion(response.data);
                    })
                    // const index = state.formQuestions.findIndex(formQuestion => formQuestion.id === response.data.id);
                    // state.formQuestions[index] = new FormQuestion(response.data);
                    toastSuccess('فرم سوال با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = FormQuestionsSlice.actions;

export default FormQuestionsSlice.reducer;