import Category from "./category";
import Product from "./product";

export enum DiscountType {
    PERCENT = "percent",
    FIXED = "fixed",
}

interface ContactInterface {
    id: number;
    username?: string | null;
    f_name: string;
    l_name: string;
    mobile_number: string;

}

class Contact {

    id: number;
    username?: string | null;
    f_name: string;
    l_name: string;
    mobile_number: string;


    constructor(data: ContactInterface) {
        this.id = data.id;
        this.username = data.username;
        this.f_name = data.f_name;
        this.l_name = data.l_name;
        this.mobile_number = data.mobile_number;
    }

}

export default Contact;