import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllUTMs} from "redux/dashboard/utm/utmAction";
// import User from "../../../models/lTV";
import LTV from "../../../models/lTV";
import AdvancedCategory from "../../../models/advancedCategory";
import ShopPlan from "../../../models/shopPlan";
import UTM from "../../../models/UTM";

interface State extends DefaultStates {
    lTV: UTM[];
    submitLoading: boolean;
}

const initialState: State = {
    lTV: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const UtmSlice = createSlice({
    name: 'lTV',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllUTMs.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllUTMs.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: UTM[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    contacts.push(new UTM({
                        full_name: contact.full_name,
                        mobile_number: contact.mobile_number,
                        marketer: contact.marketer,
                        id: contact.id,
                    }));
                }
                state.lTV = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllUTMs.rejected, (state, action) => {
                state.loading = false;
            }
        );
    },
});

export default UtmSlice.reducer;