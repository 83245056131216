import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {submitLike} from "redux/dashboard/like/likeAction";
import Like from "../../../models/likes";
import {toastSuccess} from "../../../utils/funcions";

interface State extends DefaultStates {
    like: Like[];
    submitLoading: boolean;
}
interface State extends DefaultStates {
    like: Like[];
    submitLoading: boolean;
}

const initialState: State = {
    like: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const LikeSlice = createSlice({
    name: 'like',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submitLike.pending, (state) => {
            state.submitLoading = true;
        });
        builder.addCase(submitLike.fulfilled, (state, action) => {
            state.submitLoading = false;
            state.loading = false;
            const response = action.payload;

            if (response.status === 200) {
                const data = response.data.like; // فرض بر این است که داده لایک در 'like' موجود است
                const existingLikeIndex = state.like.findIndex(
                    (like) => like.product_id === data.product_id && like.user_id === data.user_id
                );

                if (existingLikeIndex !== -1) {
                    state.like[existingLikeIndex] = new Like({
                        ...data,
                        product_id: data.product_id,
                        is_like: data.is_like,
                    });
                } else {
                    state.like.unshift(
                        new Like({
                            ...data,
                            product_id: data.product_id,
                            is_like: data.is_like,
                        })
                    );
                }
            } else if (response.status === 203) {
                toastSuccess('از علاقه‌مندی‌ها حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });
        builder.addCase(submitLike.rejected, (state) => {
            state.loading = false;
            state.submitLoading = false;
        });
    },
});

export default LikeSlice.reducer;