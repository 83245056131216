import React, {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';
import Routes from "../utils/routing/routes";
import Auth from "../services/savedData/auth";

const GuestGuard: React.FC<{children: ReactNode}> = ({ children }) => {



    const toke: string | null = Auth.get();

    if (toke) {
        return <Navigate to={Routes.products.path} />;
    }

    return (
        <>
            {children}
        </>
    );
};

export default GuestGuard;
