import {calculatePercentageDifference, getSettingByKey, loadingComponent, moneyFormat} from "../../../utils/funcions";
import {parseNumber} from "react-advanced-cropper";
import {CgArrowLongLeft} from "react-icons/cg";
import {openModal} from "../../../redux/components/modalSlice";
import {ChartModalData} from "./chartModalData";
import ReactApexChart from "react-apexcharts";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {SiGravatar} from "react-icons/si";
import {FaBuysellads, FaMoneyBillWave} from "react-icons/fa6";
import {FaProductHunt} from "react-icons/fa";
import {IconType} from "react-icons";

export interface ChartDataInterface {
    name: string;
    data: number[],
    icon: IconType,
    color: string,
    labels?: string[],
    isUsePriceFormat?: boolean,
}
export default function AnalyticsDashboard() {
    const [chartData, setChartData] = useState<ChartDataInterface[]>([]);

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const {user, shop, settings} = useAppSelector((state: RootState) => state.user);
    const {data, loading} = useAppSelector((state: RootState) => state.analytics);
    const {openSideBar, show} = useAppSelector((state: RootState) => state.analyticsSidebarSlice);


    useEffect(() => {
        if (data) {
            setChartData([
                {
                    name: "تعداد فروش",
                    data: data['30DaysAgoSoldCount'],
                    icon: FaBuysellads,
                    color: "#4E4BCF",
                },
                {
                    name: "تعداد کاربران",
                    data: data['30DaysAgoUsersCount'],
                    icon: SiGravatar,
                        color: "#7851BD",
                },
                {
                    name: "تعداد محصولات",
                    data: data['30DaysAgoProductsCount'],
                    icon: FaProductHunt,
                    color: "#bd4bcf",
                },
                {
                    name: "مبلغ فروش",
                    data: data['30DaysAgoSoldAmount'].map((item) => parseNumber(item)),
                    icon: FaMoneyBillWave,
                    color: "#1a54e8",
                }
            ])
        }
    }, [data]);


    return (
        <>
            {loading ? loadingComponent() :

                <div
                    className={`py-7 px-7 flex w-full gap-7 overflow-x-hidden transition-all duration-1000 ease-in-out ${
                        openSideBar
                            ? "sm:max-w-[calc(100vw_-_229px)] lg:max-w-[calc(100vw_-_286px)] "
                            : "sm:max-w-[calc(100vw_-_99px)] lg:max-w-[calc(100vw_-_110px)] xl:max-w-[calc(100vw_-_138px)]"
                    }`}
                >
                    <div
                        className={`flex flex-col w-full gap-7 ${
                            openSideBar
                                ? "w-full lg:max-w-[calc(100%_-_320px)]"
                                : "md:max-w-[calc(100%_-_255px)] lg:max-w-[calc(100%_-_420px)]"
                        } xl:max-w-[calc(100%_-_390px)]`}
                    >
                        <div
                            className="bg-gradient-to-tl to-[#7851BD] from-[#4E4BCF] rounded-xl p-5 relative overflow-hidden">
                            <svg
                                width="581"
                                height="239"
                                viewBox="0 0 581 239"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute -top-14 -right-[27rem] sm:-right-64 lg:-top-28"
                            >
                                <path
                                    d="M527.607 1.7273C580.879 -33.0372 543.535 -81.6071 518.204 -101.546C439.881 -162.473 430.701 -91.4797 317.582 -143.722C204.463 -195.965 227.141 -159.2 83.8863 17.2559C-59.3679 193.712 128.456 191.653 151.46 157.066C174.465 122.48 215.973 138.631 267.533 191.898C319.093 245.165 448.712 151.23 426.229 94.617C403.746 38.0046 461.017 45.183 527.607 1.7273Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                                <path
                                    d="M504.037 3.63866C552.037 -27.7193 518.379 -71.544 495.551 -89.5367C424.965 -144.515 416.703 -80.4656 314.764 -127.614C212.825 -174.762 233.264 -141.589 104.2 17.5917C-24.8647 176.773 144.384 174.939 165.109 143.738C185.834 112.537 223.239 127.114 269.707 175.178C316.175 223.243 432.963 138.51 412.696 87.4313C392.43 36.3523 444.038 42.836 504.037 3.63866Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                                <path
                                    d="M485.755 5.0211C529.659 -23.6299 498.882 -63.6584 478.006 -80.0913C413.456 -130.304 405.891 -71.7948 312.664 -114.85C219.438 -157.906 238.127 -127.606 120.065 17.8189C2.00333 163.244 156.797 161.547 175.756 133.043C194.715 104.539 228.924 117.849 271.416 161.749C313.909 205.649 420.734 128.232 402.205 81.5755C383.675 34.9188 430.875 40.8348 485.755 5.0211Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                                <path
                                    d="M468.499 6.88949C508.518 -19.2443 480.459 -55.7637 461.428 -70.7567C402.583 -116.569 395.692 -63.1941 310.707 -102.48C225.722 -141.766 242.761 -114.123 135.153 18.5341C27.5446 151.191 168.648 149.656 185.928 123.654C203.208 97.6522 234.392 109.798 273.13 149.85C311.868 189.902 409.239 119.286 392.344 76.721C375.45 34.1558 418.476 39.5567 468.499 6.88949Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                                <path
                                    d="M449.738 8.32684C485.547 -15.0868 460.432 -47.8173 443.399 -61.2558C390.732 -102.319 384.573 -54.4882 308.515 -89.7062C232.456 -124.924 247.709 -100.15 151.43 18.7138C55.1508 137.577 181.423 136.222 196.883 112.923C212.343 89.6242 240.251 100.513 274.924 136.411C309.596 172.309 396.722 109.041 381.597 70.8944C366.473 32.7477 404.977 37.5939 449.738 8.32684Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                                <path
                                    d="M430.012 9.82216C461.401 -10.6816 439.392 -39.336 424.464 -51.1002C378.306 -87.047 372.903 -45.1683 306.242 -75.9951C239.581 -106.822 252.947 -85.1321 168.546 18.9493C84.1452 123.031 194.822 121.83 208.375 101.429C221.928 81.0282 246.389 90.5588 276.775 121.985C307.161 153.412 383.533 98.0083 370.281 64.6106C357.028 31.213 390.777 35.4519 430.012 9.82216Z"
                                    stroke="white"
                                    strokeOpacity="0.13"
                                />
                            </svg>

                            <div
                                className="flex gap-4 sm:flex-wrap ssm:gap-12 xl:gap-6 flex-col xl:flex-row xl:justify-between xl:items-end lg:h-fit">
                                <div className="flex flex-col gap-3">
                    <span className="text-lg font-semibold text-[#FDFDFF]">
                      آنالیز پیشرفته
                    </span>
                                    <div className="flex gap-1 items-center">
                      <span className="font-medium text-sm text-white/80">
                          آنالیز پیشرفته پلن اختصاصی {settings.find(e => e.name === 'shop_name')?.value}
                      </span>

                                    </div>
                                    <span className="text-[22px] font-bold text-[#FDFDFF]">
                      کل فروش : {moneyFormat(parseNumber(data.totalSoldAmount))} ریال
                    </span>
                                    <span className="text-sm font-medium text-[#FDFDFF] tracking-[1.4px]">
                       تعداد محصولات فروخته شده : {data.totalSoldCount} عدد
                    </span>
                                </div>

                                <div
                                    className="flex gap-7 flex-col ssm:flex-row h-full sm:justify-start self-start ssm:self-auto z-10">
                                    <div className="flex flex-col gap-3 h-fit">
                                        <div
                                            className="py-2.5 rounded-[10px] font-semibold text-white text-center  text-lg hover:scale-105">
                                            ماه گذشته
                                        </div>
                                        <div className="flex flex-col pl-1">
                        <span className="text-sm font-semibold text-white/80 tracking-[1.5px]">
                          {moneyFormat(parseNumber(data["30DaysAgoSoldAmount"][1]))} ریال
                        </span>
                                            <span className="text-lg font-semibold text-[#FDFDFF]">
                            {data["30DaysAgoSoldCount"][1]} عدد
                        </span>
                                        </div>
                                    </div>
                                    {/*arrow top top and green if amount increased amount to bottom and red if amount decreased*/}
                                    <div className="flex flex-col items-center justify-center">
                                        <CgArrowLongLeft
                                            className={`text-[#45CB85] text-2xl transform rotate-[30deg] ${data["30DaysAgoSoldAmount"][1] >
                                            data["30DaysAgoSoldAmount"][0] ? "rotate-[320deg] text-red-600" : ""
                                            }`}
                                        />
                                        <span
                                            className={`font-semibold text-lg ${
                                                data["30DaysAgoSoldAmount"][1] < data["30DaysAgoSoldAmount"][0]
                                                    ? "text-[#45CB85]"
                                                    : "text-[#DC3545]"
                                            }`}
                                        >
                        {moneyFormat(data["30DaysAgoSoldAmount"][1] > data["30DaysAgoSoldAmount"][0]
                            ? parseNumber(data["30DaysAgoSoldAmount"][1]) - parseNumber(data["30DaysAgoSoldAmount"][0])
                            : parseNumber(data["30DaysAgoSoldAmount"][0]) - parseNumber(data["30DaysAgoSoldAmount"][1]))}{" "}
                                            ریال
                        </span>
                                    </div>
                                    <div className="flex flex-col gap-3 h-fit">

                                        <div
                                            className="py-2.5 rounded-[10px] font-semibold text-white text-center text-lg hover:scale-105">
                                            این ماه
                                        </div>
                                        <div className="flex flex-col">
                        <span className="text-sm font-semibold text-white/80 tracking-[1.5px]">
                            {moneyFormat(parseNumber(data["30DaysAgoSoldAmount"][0]))} ریال
                        </span>
                                            <span className="text-lg font-semibold text-[#FDFDFF]">
                            {data["30DaysAgoSoldCount"][0]} عدد
                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={` block ${openSideBar ? " lg:hidden" : "md:hidden"}`}
                        >
                            <CryptoCoin/>
                        </div>
                        <div className="bg-[#FDFDFF] rounded-md ">
                            <div
                                className="p-5 flex flex-col overflow-hidden overflow-x-auto gap-5 scrollbar-thin !scrollbar-thumb-[#7851BD] scrollbar-track-[#EDEDED] rounded-xl">
                  <span className="text-[#212B36] text-lg font-semibold">
                    آمار چارت
                  </span>
                                <div className="flex flex-col sm:flex-row gap-5">
                                    {chartData.map((data, index) => (
                                        <div
                                            onClick={() => {
                                                dispatch(openModal(
                                                    {
                                                        content: <ChartModalData data={
                                                            {...data, name: data.name + " طی 12 ماه گذشته"}
                                                        }
                                                                                 isUseDate={true}/>,
                                                        outerClassName: "md:max-w-[60vw] md:mx-[10px] h-full !p-0",
                                                        className: "w-full max-w-full h-full",
                                                    }
                                                ))
                                            }}
                                            className="cursor-pointer flex flex-col gap-5 border border-[#7851bd33] p-5 rounded-xl w-full sm:max-w-xs"
                                            key={index}
                                        >
                                            <div
                                                className="flex justify-between w-full sm:min-w-[200px] items-center">
                          <span className="text-sm font-medium text-[#212B36] !capitalize">
                            {data?.name}
                          </span>

                                            </div>
                                            <div>
                                                <ReactApexChart options={
                                                    {
                                                        //     disabling all labels and titles only showing chart
                                                        //     and its data
                                                        chart: {
                                                            toolbar: {
                                                                show: false,
                                                            },
                                                            zoom: {
                                                                enabled: false,
                                                            },
                                                            sparkline: {
                                                                enabled: true,
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false,
                                                        },
                                                        stroke: {
                                                            curve: "smooth",
                                                        },
                                                        colors: [data.color],
                                                        yaxis: {
                                                            labels: {
                                                                show: false,
                                                            },
                                                        },
                                                        grid: {
                                                            show: false,
                                                        },
                                                        tooltip: {
                                                            enabled: false,
                                                        },
                                                        legend: {
                                                            show: false,
                                                        },
                                                        fill: {
                                                            type: "gradient",
                                                            gradient: {
                                                                shadeIntensity: 1,
                                                                opacityFrom: 0.7,
                                                                opacityTo: 0.9,
                                                                stops: [0, 100],
                                                            },

                                                        }
                                                    }
                                                } series={
                                                    [
                                                        {
                                                            name: data.name,
                                                            data: data.data,
                                                        }
                                                    ]
                                                } type="area" height={100}/>
                                            </div>
                                            <div className="flex justify-between flex-wrap sm:flex-nowrap">
                                                <div className="flex gap-2.5">
                                                    <div className="p-2 rounded-lg" style={{
                                                        backgroundColor: data.color,
                                                    }}>
                                                        <data.icon
                                                            color="white"
                                                            className="h-5 w-5 "
                                                        />
                                                    </div>
                                                    <div className="flex flex-col">
                              <span className="text-sm font-medium text-[#212B36]">
                                {data["data"][0]}
                              </span>
                                                        <span className="text-xs font-medium text-[#637381]">
                                {data["data"][1]}
                              </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-1 self-end">
                                                    <CgArrowLongLeft
                                                        className={`text-[#45CB85] text-2xl transform rotate-[30deg] ${
                                                            data["data"][0] <
                                                            data["data"][1]
                                                                ? "rotate-[320deg] text-red-600" : ""}`}
                                                    />
                                                    <span
                                                        className={`font-inter text-xs font-medium ${
                                                            data["data"][0] >
                                                            data["data"][1]
                                                                ? "text-[#45CB85]"
                                                                : "text-[#DC3545]"
                                                        }`}
                                                    >
                              {/*                              percentage of increase or decrease*/}
                                                        {
                                                            calculatePercentageDifference(
                                                                data["data"][0],
                                                                data["data"][1]
                                                            )
                                                        } %
                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#FDFDFF] rounded-2xl block md:hidden">
                            <Chat/>
                        </div>
                        <div className="overflow-hidden">
                            <div className="flex flex-col gap-4">
                  <span className="text-lg font-semibold text-[#212B36]">
                    پر فروشترین محصولات 12 ماه اخیر (مبلغ)
                  </span>
                                <div
                                    className="w-full overflow-x-auto scrollbar-thumb-[#7851BD] scrollbar-track-[#EDEDED] scrollbar-thin md:overflow-auto max-w-xl xs:max-w-xl sm:max-w-xl md:max-w-7xl 2xl:max-w-none mt-1">
                                    <table
                                        className="table-auto overflow-scroll md:overflow-auto w-full text-right font-inter border-separate border-spacing-y-1.5">
                                        <thead
                                            className="bg-[#222E3A]/[6%] rounded-lg text-base text-white font-semibold w-full">
                                        <tr className="">
                                            <th className="py-3 pr-3 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap rounded-r-lg">
                                                مشخصات
                                            </th>
                                            <th className="py-3 pr-2 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap">
                                                زمان
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.mostProductSoldAmountOf30DaysAgo.map((data, index) => (
                                            <tr
                                                key={index}
                                                className="drop-shadow-[0_0_10px_rgba(34,46,58,0.02)] hover:drop-shadow-2xl cursor-pointer bg-[#f6f8fa]"
                                            >
                                                <td className="py-5 pr-3 text-sm font-normal text-[#637381] rounded-r-lg border-y border-r border-[#7851BD]/20">
                                                    <div className="relative flex gap-3 items-center">
                                                        <div className="">
                                                            <img
                                                                src={data?.product_image}
                                                                alt={data?.product_name}
                                                                className="min-w-[42px] min-h-[42px]"
                                                            />
                                                        </div>
                                                        <div className="flex flex-col whitespace-nowrap">
                                  <span className="text-xs md:text-sm text-[#212B36]">
                                    {data?.product_name}
                                  </span>
                                                            <span
                                                                className="text-xs md:text-sm text-[#637381] mt-1">
                                    {moneyFormat(parseNumber(data.product_amount))} ریال
                                  </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="py-5 px-2 text-xs md:text-sm font-normal text-[#637381]  border-y  border-x-0 border-[#7851BD]/20">
                                                    در {
                                                    data.month_index == 0 ? "این ماه" : data.month_index == 1 ? "ماه گذشته" : data.month_index + " ماه پیش"
                                                }
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded-2xl block md:hidden">
                            {/*<Chart/>*/}
                        </div>
                    </div>
                    <div
                        className={`flex-col gap-7 w-full max-w-[230px] ${
                            openSideBar ? " lg:max-w-[336px]" : "lg:max-w-[400px]"
                        } xl:max-w-[390px] ${
                            openSideBar ? "hidden lg:flex" : "hidden sm:flex"
                        }`}
                    >
                        <div className="">
                            <CryptoCoin/>
                        </div>

                        <div className="bg-[#FDFDFF] rounded-2xl">
                            <Chat/>
                        </div>

                        <div className="bg-white rounded-2xl">
                            {/*<Chart/>*/}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}



export const CryptoCoin = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {data, loading} = useAppSelector((state: RootState) => state.analytics);
    return (
        <div className="flex flex-col gap-3.5 w-ful justify-between h-full">
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-[#212B36]">آمار کاربران 2 ماه اخیر</span>
                <span
                    onClick={() => {
                        dispatch(openModal(
                            {
                                content: <ChartModalData data={
                                    {
                                        name: "کاربران",
                                        data: data['30DaysAgoUsersCount'],
                                        icon: SiGravatar,
                                        color: "#7851BD",
                                    }
                                }/>,
                                outerClassName: "md:max-w-[60vw] md:mx-[10px] h-full !p-0",
                                className: "w-full max-w-full h-full",
                            }
                        ))}
                    }
                    className="text-sm font-medium text-[#637381] cursor-pointer hover:text-[#7851BD] mr-2">
          مشاهده چارت
        </span>
            </div>
            <div
                className="overflow-hidden overflow-x-auto flex gap-5 pb-2 scrollbar-thumb-[#7851BD] scrollbar-track-[#EDEDED] scrollbar-thin">
                <div
                    className="py-5 px-3.5 flex gap-3.5 flex-col min-w-[183px] rounded-lg bg-white border border-[#7851bd33]"
                >
                    <div className="flex gap-2.5 items-center">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRElEQVR4nO2Z7UtTURzHr1ij3lSWzHskXYZRan+BYWjUNCQQTSJ9Ecyw8h6ZJpqvKlBKy8JUFJs0wR5IECFI3UbUxDIUQjJL264vLHthaPSAmqjfuHNz4tO2u3mvwX7whcF2f/fzOeds3HPGMP7yl7/85Sh9DtiqVOhuxGP0ShRmtOFAngrzwuub8fhak4b6lgIomc1WzdFQ3D2FR5f3Y54SYL0In6k9jZbmdCiYzVBlCYgpicWEK/DlKYnFeNlRRMkKX5MBVf4+zHoK74hWhbnb6YiQTaAwEn/EwjtSeAC/ZYEvjcMTb+GpPaVxaJJcQBsufuksT54Ks5LCN2QieSlAVy3AGz2LcA1d0qNRA7VkAlUpeOi4cdEhwNLmuYClHSg66BSoToFeMoGKRPQtjlyW5/CONGY5Be4k4p1kAuUJGBJuWhwNDLSKFxhoXegh9Co/hk+SCdxSo18bBryqFA/vyMtKQOhVoUa/ZAJ1Z9BhrvIenrfHfA+oO4t2SeB5E5J5IyZ8Bc/bYzViwmrAyY0XMOKLr+F5Z0b8Aq5KmOYNmoURiwlJjFRlNaLTh+vfzEhdFhNyfTb6BuRILsCbsNNqwA9f/Pp8bsMOyQVsEh3QeC1gwDlGrgIQwBuh92L0G2SDX5RoRqDVAJ0IgXrhWsmBL4UjiCPIpQTPKYsRymJKeBgrUH/Dm6Zpl+Cvm6aRf3x04SmUxZSth9CLgAq9Nww8m8FWjuA6R/BrtV3VhZAZHFb0QJMwBF3xGDr1k/jwbNYW84NJ3C8egyZ+EFGKHmSHzKy6M+MIfnIsrl5jsMWn8BdDoKQEb11tDdODxhHBdK2btKDvLreYHEF3NkGwT+Dz92I3JXjv7v42dtvgmvDCe+724Vj0aVXY5bUAJXjqyQZdWB7Rgb0r4GMCe9dcOnRticdeweewSBJzypClnLaNdmRAty1Htg/ivPKvqBMLjuCEaAGO4IW3RyZeh4VRHHwo9nAEc3ILcARzor7QlEWq3PDUOQupngsQ1MgOTuyzwKJajMBHucGpcxkNeASfFwxCies/KySVCEOo2wK5BJlyA9OVs5DhtgDHokFuYLo8LHTuCxAMb8IZGHZbwF/+8hfz39Q/qlKUg0y4ZOYAAAAASUVORK5CYII="/>
                        <span className="text-lg font-medium tracking-[1.8px] text-[#637381] uppercase">
                کاربران
              </span>
                    </div>
                    <span className="text-lg font-semibold text-[#212B36] tracking-[1.8px]">
              کاربران ماه پیش
            </span>
                    <span
                        className={`text-sm font-medium ${
                            data['30DaysAgoUsersCount'][1] < data['30DaysAgoUsersCount'][2] ? "text-[#DA1818]" : "text-[#32A953]"
                        } `}
                    >
                {data['30DaysAgoUsersCount'][1]}
            </span>
                </div>

                <div
                    className="py-5 px-3.5 flex gap-3.5 flex-col min-w-[183px] rounded-lg bg-white border border-[#7851bd33]"
                >
                    <div className="flex gap-2.5 items-center">

                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFFUlEQVR4nO1ZbUxTVxi+mdt+7Oc+fokm6v6pPxYzFYeJZqUQHIy5MRhGGGRgzNjEIZl8iLoFKVO3AUppmd1EF1gXpqaWrwKSJbI5M2SoyzZEV0rvgXFvweI9yga8y7kE6Cc9py31T9/kSW7Ox3ue5z3vOT3nlOPCFrawBWwAsEwcxpHCMC4UEW4UkXRbQNgm8NK/MuRvfIvUkTbCsLQZAJ7gHreJQ3iFwEsqEeEhEWFghEXgpTLBIkWEnLidt78gIKwRkDTpB3EnCEiaFBBW263250NC3jaMUwWExUCJuwvBgohwypIRB4CnBB7XBpu46C6khowVVPJWKzwjINy01OTFBRFGMmbwIh9C8uIceMkEt+DpgAWwpk1/71/QqFbD5/uy4fDuN6AgKVZGXfkxf2ZCHRB5mxXvoh6Ml+BibS0Up7w2T9oRRclxcPVyG4yYx1iFJPtF/v7Q/ecEhEdpydepSj0Sd8XR9Leg64KBZRZEv7ZYss/TDtJ07lsq8o7o0DeyiDjNRt4iRdD+SA3+ycOh1ARmAYd2vQ7mP4YoBUiTNiteSS9g9nhA5dyg+3pRouXvxoCxKAbM1dHQe1IJhQ51TWfr6GeBl8qoyJNDFjmn0DquyMtxI30iIxZaipVg0Shh5rtoAP0CrpUpofDt2XbqonyWxTxEDo0+BZBTJcsucSRtp0zmeEYMGApi4F61O2lXXC1Vzs7O3nSmHUlA0kbf6TN7JKZ2Ohf1xQh7AulzePdOJgE2hA/6ngGEfwiVgIKkWCYBIsLf+56B2YsHtdPmz/Y7CVCnrYGatDVuhF3LSR9D2T62FOJxn28BjEflsYpoaD+inCdGSNakv+gmwLWc9BmrVDKuATxKIYDtkmJrPe1z0XoC6WNrqWZdxI+CLoBgyvges4ApYxZr/tMKYL9tPfxJwywA/6z1QwBNCjEuYnkdmK0w05hAnz6NCTBm5tkF0Cxi1m10DhN9rQD6hcXsHUqY6Gtj9i9Sb6OMP2TOItpgpkHhPfINCpi4afKXPIgIf0whQNocwAAw/uVWePjVNpg6r5B3GgLyTcpIXSC+R3npZarDnICkQX8HGTsZtSj89SsgyUz9mkeOrizO+QE7lOztBMXaM8CrXvVKntRFr9PBJ+93Abo3wSaCl45RkWe90LSeH4C82HaIWq2Fl5ZXQtsHmV4FtOVkym22rtZC3o4OMNXfpY3+I2FQWk4tQBaBsHoxpyODD6DmYA/s2dIsI3F9vUwudUMp/HN8mxt5UkbqSJvE9Q3z/bTFN2DE8sBH9HEVx2rjg+PPervUCzx2Ik+QvaUJXlmlkQmmb/wUug+kAq9SyCDfpIzUkTakrWPfmsIbi0QfC36/m5K3Sk9Ojbo7TgTmkLHJMC/CE6JWaSBzk8FjX6PujjcRSVwgRt4qHR0O/z0BeXHtHknIMxHZJKcTyfMNEadkkO/EdfWQFekc+T0OyFWYgL9rd02dU1xQ/rxA+MJ89L/xHP1goLluwDFNLwPAk1wQH3eNxPEXH15bMgEVudfnyBuC9rjr8sirLki8smQCCt7sktMmaJH3ZOdUN1UH4jung03+o7j2ad3R305woTDT2YGVFbm/9OZsbw2YeM72Vqjaf72nRde/ggu1XdL2r9UU/nolf0fHFCvx/PiO/7RFPZ3EB/e4Ta+HZfqq31POlPReKs/utpS886OUH985TaJLQL5JWXlWt6W2pPeivvJ2MunDhS1sYeMCtf8Bod3Dj2QRXnMAAAAASUVORK5CYII="/>

                        <span className="text-lg font-medium tracking-[1.8px] text-[#637381] uppercase">
                کاربران
              </span>
                    </div>
                    <span className="text-lg font-semibold text-[#212B36] tracking-[1.8px]">
              کاربران این ماه
            </span>
                    <span
                        className={`text-sm font-medium ${
                            data['30DaysAgoUsersCount'][0] < data['30DaysAgoUsersCount'][1] ? "text-[#DA1818]" : "text-[#32A953]"
                        } `}
                    >
                {data['30DaysAgoUsersCount'][0]}
            </span>
                </div>
            </div>
        </div>
    );
};

export const Chat = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {data, loading} = useAppSelector((state: RootState) => state.analytics);
    return (
        <div className="py-6 flex flex-col gap-5 ">
            <div className="flex flex-col gap-3 px-5">
                <div className="flex justify-between md:flex-col gap-3 lg:flex-row lg:justify-between">
          <span className="text-lg font-semibold text-[#212B36] whitespace-nowrap">
            برترین خریداران 360 روز اخیر
          </span>
                    <button
                        onClick={
                            () => {
                                dispatch(openModal(
                                    {
                                        content: <ChartModalData data={
                                            {
                                                name: "خریداران",
                                                data: data.mostUserBuyAmountOf30DaysAgo.map((data) => parseNumber(data?.user_amount ?? '0')),
                                                icon: SiGravatar,
                                                color: "#7851BD",
                                                labels: data.mostUserBuyAmountOf30DaysAgo.map((data, i) => (data?.user.f_name ?? '' as string) + ' ' + (data?.user.l_name ?? '' as string) + ` (${i+1})`),
                                            }
                                        }/>,
                                        outerClassName: "md:max-w-[60vw] md:mx-[10px] h-full !p-0 !p-0",
                                        className: "w-full max-w-full h-full",
                                    }
                                ))
                            }
                        }
                        className="px-5 py-1.5 text-[#637381] hover:bg-gradient-to-tl hover:to-[#7851BD] hover:from-[#4E4BCF] text-base tracking-[1.6px] bg-[#EDEDED] rounded-[40px] text-center h-fit w-fi hover:text-white whitespace-nowrap self-end">
                        بیشتر
                    </button>
                </div>
                <div className="flex justify-between flex-wrap gap-2 lg:gap-0 lg:flex-nowrap ">
                    <div className="flex gap-7 overflow-scroll">
                        {data.mostUserBuyAmountOf30DaysAgo.map((data, index) => (
                            <img src={(data?.user.avatar ?? '')} alt={(data?.user.f_name ?? '') + ' ' + (data?.user.l_name)} key={index}
                                 className="h-9 w-9"/>
                        ))}
                    </div>
                </div>
            </div>
            <div className="border-t border-[#7851bd33] px-5 pt-5 flex gap-7 flex-col">
                <div className="flex flex-col gap-2 xl:flex-row xl:justify-between w-full">
          <span className="font-semibold text-lg text-[#212B36] tracking-[1px]">
            بیشترین حجم فروش محصولات 12 ماه اخیر
          </span>

                </div>
                <div className="">
                    {
                        data.mostProductSoldCountOf30DaysAgo.map((i) => {
                            if (i.product_id == null) {
                                return <></>;
                            }
                            return (
                                <div className="mt-1">
                                    <span className="text-sm text-[#637381]">
                                        {i.product_name}
                                    </span>
                                    <div
                                        className="py-2 pl-1.5 bg-[#EDEDED] flex justify-between flex-wrap gap-3 rounded-lg">

                                        <div className="flex gap-4 items-center mr-4 ml-1.5">
                <span className="text-[#212B36] font-semibold text-sm tracking-[1.5px]">
                  {i.product_count} عدد
                </span>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                    }
                </div>
                <button
                    onClick={
                        () => {
                            dispatch(openModal(
                                {
                                    content: <ChartModalData data={
                                        {
                                            name: "خریداران",
                                            data: data.mostProductSoldCountOf30DaysAgo.map((data) => parseNumber(data?.product_count ?? '0')),
                                            icon: SiGravatar,
                                            color: "#7851BD",
                                            labels: data.mostProductSoldCountOf30DaysAgo.map((data, i) => (data?.product_name ?? '' as string)),
                                        }
                                    }/>,
                                    outerClassName: "md:max-w-[60vw] md:mx-[10px] h-full !p-0 !p-0",
                                    className: "w-full max-w-full h-full",
                                }
                            ))
                        }
                    }
                    className="px-5 py-5 bg-gradient-to-tl to-[#7851BD] from-[#4E4BCF] rounded-xl text-lg font-semibold mt-3 text-white hover:scale-105">
                    مشاهده بیشتر
                </button>
            </div>
        </div>
    );
};

