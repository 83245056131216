import {createSlice, current} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import Cart from "../../../models/cart";
import {LaravelValidationErrorType} from "../../../utils/types";
import {CartResponse, changeCart, checkDiscountCart, getAll} from "./cartAction";
import CartItem from "../../../models/cartItem";
import {toastSuccess} from "../../../utils/funcions";
import {parseNumber} from "react-advanced-cropper";
import store from "../../store";

export var cartItems: any = null;

interface State extends DefaultStates {
    cart: Cart|null;
    priceFromServer: number|null,
    submitLoading: boolean;
    code?: string,
}

const initialState: State = {
    loading: false,
    submitLoading: false,
    validationErrors: null,
    cart: null,
    priceFromServer: null,
};

const CartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // Todo
        setCartQuantity(state, action: { payload: { productId: number, quantity: number } }) {
            const {productId, quantity} = action.payload;
            state.cart!.items!.find((item: CartItem) => item.product.id === productId)!.quantity  = quantity;
            state.cart!.items!.find((item: CartItem) => item.product.id === productId)!.newQuantity = quantity;
        },
        removeDiscount(state) {
            state.priceFromServer = null;
            state.code = undefined;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(checkDiscountCart.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(checkDiscountCart.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;

            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                if (data) {
                    state.priceFromServer = parseNumber(data);
                }
                state.code = response.code;
                toastSuccess('کد تخفیف با موفقیت اعمال شد.');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
                toastSuccess('کد تخفیف اعمال نشد.');
            }
        })

        builder.addCase(changeCart.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(changeCart.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                // if (data.items[0].quantity == 0 ) {
                //
                // }
                state.cart!.items = state.cart!.items!.filter((item) => {
                   return item.quantity != 0;
                })

                toastSuccess('موفقیت آمیز بود.');
            } else if (response.status === 422) {
                state.cart!.items!.find((item: CartItem) => item.product.id === response.id)!.newQuantity = state.cart!.items!.find((item: CartItem) => item.product.id === response.id)!.quantity;
                state.validationErrors = response.data as LaravelValidationErrorType;
                toastSuccess('مشکلی پیش آمد.');
            }
        })
        builder.addCase(changeCart.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                if (response.data.data.items) {
                    const cartItems: CartItem[] = (response.data.data as Cart).items!.map((item: CartItem) => {
                        item.newQuantity = item.quantity;
                        return item;
                    });

                    state.cart = new Cart({...response.data.data, items: cartItems});
                }
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )

    },
});

export default CartSlice.reducer;
export const {setCartQuantity, removeDiscount} = CartSlice.actions;