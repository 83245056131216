import {NeshanMapLeaflet} from "./components/NeshanMapLeaflet";
import {setModalReturnData} from "../modalSlice";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useEffect, useState} from "react";

export const NeshanMap = (props?: {
    lat?: number,
    lng?: number,
}) => {

    let options = {
        key: 'web.333a847c2d214d5f9ef0acd862f8d9a2',
        zoom: 12,
    }

    if (props?.lat && props?.lng) {
        options = {...options, ...{
            center: [props.lat, props.lng]}}
    }


    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const [isShowingNeshan, setIsShowingNeshan] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShowingNeshan(true);
        }, 1000)
    }, [])

    return (
        isShowingNeshan ? <NeshanMapLeaflet
            options={options}
            location={(e: any) => {
                dispatch(setModalReturnData(e));
            }
            }
            fullAddress={(e: any) => {
            }
            }
        /> : ''
    )
}