import React, {useRef, useState} from "react";
import {useAppSelector} from "redux/hooks";
import {submit} from "redux/user/login/loginAction";
import {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {Formik, Form, FormikHelpers} from 'formik';
import DefaultModal from "../../../components/modal";
import {IconArrowRight, IconFilter, IconSearch, IconX} from "@tabler/icons-react";
// import {Moment} from "jalali-moment";
import {setPaginateToStart} from "../../../redux/dashboard/products/productsSlice";
import {getAll} from "../../../redux/dashboard/products/productsAction";

interface values {
    mobileNumber: string;
    password: string;
    isReseller: boolean;
}

export const ProductFilterModal: React.FC = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const [isUsePassword, setIsUsePassword] = useState(false);

    const handleLogin = async (data: values) => {
        try {
            dispatch(submit({...data, isUsePassword}));
        } catch (error) {
            console.error(error);
        }
    };


    const [title, setTitle] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());

    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [status, setStatus] = useState<string>('');


    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart())
        if (currentTime - lastSearchTimeRef.current >= 2000) {
            const data = { title, minPrice, maxPrice, status };

            try {
                // console.log('console.log', data)
                dispatch(getAll({isReturnCache: true, credentials: data}));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime; // Update the last search time
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle("");
    };

    const handleDeleteSearchByMinPayment = () => {
        setMinPrice("");
    };
    const handleDeleteSearchByMaxPayment = () => {
        setMaxPrice("");
    };
    const handleDeleteSearchByStatus = () => {
        setStatus("");
    };

    return (
        <DefaultModal
            label={'productFilterModal'} id={'productFilterModal'}
            modalBoxClasses={'p-4 max-w-7xl overflow-x-hidden px-3 z-[50]'}>
            <label htmlFor={"productFilterModal"}>
                <div className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={'mr-2'}>فیلتر محصولات</span>
                </div>
            </label>
            <div className={'flex flex-col items-start w-full'}>
                <div
                    className="w-full duration-300 py-10 overflow-hidden grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-2">
                    {/* Name Search */}
                    <div className="flex flex-col">
                        <label className="mb-2">نام محصول</label>
                        <div className="input input-bordered flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="search"
                                    placeholder="نام محصول"
                                    value={title}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="input placeholder:text-[14px] border-none input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                />
                                {title ? (
                                    <button
                                        onClick={handleDeleteSearch}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Min Purchase */}
                    <div className="flex flex-col mt-6 md:mt-0">
                        <label className="mb-2">کمترین قیمت</label>
                        <div className="input input-bordered flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="number"
                                    placeholder="کمترین قیمت"
                                    value={minPrice}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                    onChange={(e) => setMinPrice(e.target.value)}
                                    className="input placeholder:text-[14px] border-none input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                />
                                {minPrice ? (
                                    <button
                                        onClick={handleDeleteSearchByMinPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Max Purchase */}
                    <div className="flex flex-col mt-6">
                        <label className="mb-2">بیشترین قیمت</label>
                        <div className="input input-bordered flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="number"
                                    placeholder="بیشترین قیمت"
                                    value={maxPrice}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    className="input placeholder:text-[14px] border-none input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                />
                                {maxPrice ? (
                                    <button
                                        onClick={handleDeleteSearchByMaxPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mt-6">
                        <label className="mb-2">وضعیت</label>
                        <div className="input input-bordered flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="input placeholder:text-[14px] text-[14px] border-none input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                                >
                                    <option value="">جستجو براساس وضعیت</option>
                                    <option value="true">فعال</option>
                                    <option value="false">غیرفعال</option>
                                </select>

                                {
                                    status ?
                                        <button
                                            onClick={handleDeleteSearchByStatus}
                                            className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                        >
                                            <IconX size={'24'}/>
                                        </button> :

                                        <button
                                            onClick={handleSearch}
                                            className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                        >
                                            <IconSearch size={'24'}/>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>

                </div>


                <label className={` flex items-center w-max`}>
                    <button onClick={() => {
                        handleSearch();

                        const addProductElement = document.querySelector("[for='productFilterModal']") as HTMLElement;
                        if (addProductElement) {
                            addProductElement.click();
                        }
                    }}

                            className={`w-full w-max py-4 px-12 group flex justify-center duration-300 rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 `}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>
        </DefaultModal>

    );
}

export default ProductFilterModal;


