import Routes from "../../../utils/routing/routes";
import {Link, useLocation} from "react-router-dom";
import {IconType} from "react-icons";
import {
    AiOutlineContacts,
    AiOutlineInfo
} from "react-icons/ai";
import {FaAngleLeft} from "react-icons/fa6";
import React, {useEffect, useState} from "react";
import {checkUser, getSettingByKey, NetworkImage} from "../../../utils/funcions";
import {IPath} from "../../../utils/types";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {EditProfileModal} from "../profile/edit";
import Auth from "../../../services/savedData/auth";
import {signUpAsDefault} from "../../../redux/user/login/loginSlice";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import logo from 'assets/img/coloop-logo-44-min.-copy.png'
import empty from 'assets/img/prof.webp'
import {
    IconAdjustments, IconBell,
    IconBox,
    IconBuildingStore,
    IconCategory,
    IconChartDots2, IconChartTreemap, IconCreditCardPay, IconDatabase,
    IconDiscount2,
    IconDots, IconFilter, IconFilterUp,
    IconHeartHandshake, IconLock,
    IconMailOpened,
    IconPhoneCall, IconPlus,
    IconSettings,
    IconSpeakerphone,
    IconStar,
    IconTextPlus,
    IconTruckDelivery,
    IconUser,
    IconUsers, IconWallet
} from '@tabler/icons-react';
import AddProductModal from "../products/add";
// import GetBetterPlan from "../cart/getBetterPlan";
import GetBetterPlanModal from "../cart/getBetterPlan";
import {getAllUnreadNotifications} from "../../../redux/dashboard/notifications/unreadNotificationsAction";

interface IItem {
    title: string,
    icon: IconType,
    link?: IPath | string,
    type?: 'function' | 'IPath' | 'link',
    subItems?: IItem[],
    onClick?: () => void,
    planId?: number,
    spicial?: boolean,
    // itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest',
    isFor?: boolean
    itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest' | 'super_admin',
}

// interface NavigationIItem {
//     title: string,
//     icon: IconType,
//     link?: IPath|string,
//     type?: 'function'|'IPath',
//     subItems?: IItem[],
//     itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest',
//     isFor?: boolean
// }

function Sidebar() {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    let index: number | undefined = undefined;
    let indexw: number | undefined = undefined;
    let navigationIndex: number | undefined = undefined;
    // let navigationIndex: number|undefined = undefined;
    let subIndex: number | undefined = undefined;

    const [openItem, setOpenItem] = useState<number | null>(null);
    const [subMenuHeight, setSubMenuHeight] = useState<number | null>(null);

    const {
        user,
        shop,
        settings,
        storage,
        plan,
        isMerchantFilled,
        plan_id
    } = useAppSelector((state: RootState) => state.user);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isGetBetterPlanModalOpen, setIsGetBetterPlanModalOpen] = useState(false);
    const handleOpenSubMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
        if (index === openItem) {
            setOpenItem(null);
            setSubMenuHeight(null);
            return;
        }
        e.preventDefault();
        setSubMenuHeight(e.currentTarget?.querySelector('.submenu')?.clientHeight ?
            (e.currentTarget?.querySelector('.submenu')?.clientHeight ?? 0) * e.currentTarget?.querySelectorAll('.submenu').length
            : null
        );
        setOpenItem(index);
    }
    const items: IItem[] = [


        {

            title: 'محصولات',
            planId: 1,
            icon: IconBuildingStore,
            link: Routes.home,
            itemVisibilityType: 'all',
        },
        {

            title: 'اعلان ها',
            planId: 1,
            icon: IconBell,
            link: Routes.notifications,
            spicial: true,
            itemVisibilityType: 'shop',
        },

        {
            title: 'افزودن محصول',
            planId: 1,
            icon: IconPlus,
            type: 'function',
            link: 'addProduct',
            itemVisibilityType: 'shop',
        },
        {
            title: 'دسته بندی',

            planId: 1,
            icon: IconCategory,
            link: Routes.categories,
            itemVisibilityType: 'all',
        },
        {
            title: 'کد های تخفیف',
            planId: 1,
            icon: IconDiscount2,
            link: Routes.discountCodes,
            itemVisibilityType: 'shop',
        },
        {
            title: 'سفارشات من',
            planId: 1,
            //
            icon: IconTruckDelivery,
            link: Routes.orders,
            itemVisibilityType: 'user',
        },
        {
            title: 'کاربران',
            planId: 1,
            //
            icon: IconUsers,
            link: Routes.shopUsers,
            itemVisibilityType: 'shop',
        },
        {
            title: 'مخاطبین',

            planId: 1,
            icon: AiOutlineContacts,
            link: Routes.contacts,
            itemVisibilityType: 'shop',
        },
        {
            title: 'سفارشات',
            planId: 1,
            icon: IconTruckDelivery,
            link: Routes.shoporders,
            itemVisibilityType: 'shop',
        },
        {
            title: 'تعرفه ها',
            planId: 1,
            icon: IconBox,
            link: Routes.plans,
            itemVisibilityType: 'all',
        },
        {
            title: 'تعرفه ها',
            planId: 1,
            icon: IconBox,
            link: Routes.plans,
            itemVisibilityType: 'guest',
        },
        {
            title: 'تعرفه های خریداری شده',
            planId: 1,
            icon: IconCreditCardPay,
            link: Routes.buyedPlans,
            itemVisibilityType: 'shop',
        },
        {
            title: 'تسویه حساب ها',
            planId: 2,
            icon: IconWallet,
            link: Routes.settlementOfAccounts,
            itemVisibilityType: 'shop',
        },
        {
            title: 'ریسلر',
            planId: 2,
            icon: IconHeartHandshake,
            link: Routes.resellerInfo,
            itemVisibilityType: 'shop',
        },
        {
            title: 'ریفرال',
            planId: 2,
            icon: IconMailOpened,
            link: Routes.referralInfo,
            itemVisibilityType: 'shop',
        },
        // {
        //     title: 'پلن های فروشگاهی',
        //     planId: 2,
        //     icon: IconMailOpened,
        //     link: Routes.referralInfo,
        //     itemVisibilityType: 'super_admin',
        // },
        {
            title: 'ریسلر',
            planId: 2,
            icon: IconHeartHandshake,
            link: Routes.resellerInfoUser,
            itemVisibilityType: 'user',
        },
        {
            title: 'ریفرال',
            planId: 2,
            icon: IconMailOpened,
            link: Routes.referralInfoUser,
            itemVisibilityType: 'user',
        },
        {
            title: 'پیشرفته',
            planId: 1,
            icon: IconDots,
            itemVisibilityType: 'shop',
            subItems: [
                {
                    title: 'نظرسنجی',
                    planId: 3,
                    icon: IconTextPlus,
                    link: Routes.formQuestions,
                    itemVisibilityType: 'shop',
                },
                {
                    title: 'افزودن کمپین',

                    planId: 1,
                    icon: IconSpeakerphone,
                    link: Routes.contactsAndCustomersRoute,
                    itemVisibilityType: 'shop',
                },
            ]
        },
        {
            title: 'تحلیل ها',

            planId: 3,
            icon: IconChartDots2,
            itemVisibilityType: 'shop',
            //link is current domain + /analytics
            onClick: () => {
                window.location.href = window.location.origin + '/analytics';
            },
        },
        {
            title: 'تماس با ما',
            icon: IconPhoneCall,
            link: Routes.contactUs,
            itemVisibilityType: 'all',
        },
        {
            title: 'درباره ما',

            icon: AiOutlineInfo,
            link: Routes.aboutUs,
            itemVisibilityType: 'all',
        },
        {
            title: 'تنظیمات',

            icon: IconSettings,
            link: Routes.editMainSettings,
            itemVisibilityType: 'shop',
        },
    ];
    const itemsw: IItem[] = [
        {
            title: 'فروشگاه ها',
            icon: IconBuildingStore,
            link: Routes.products,
            itemVisibilityType: 'super_admin',
        },
        {
            title: 'پلن ها',
            icon: IconBuildingStore,
            link: Routes.shopPlans,
            itemVisibilityType: 'super_admin',
        },
        {
            title: 'فروشندگان ما',
            icon: IconBuildingStore,
            link: Routes.utms,
            itemVisibilityType: 'super_admin',
        },

    ];
    const location = useLocation();
    const {pathname} = location;
    index = pathname === 'profile' ? items.length : items.findIndex(item => (item.link as IPath | null)?.path === pathname);
    indexw = pathname === 'profile' ? itemsw.length : itemsw.findIndex(item => (item.link as IPath | null)?.path === pathname);
    // navigationIndex = pathname === 'profile' ? navigationItems.length : navigationItems.findIndex(item => (item.link as IPath | null)?.path === pathname);
    items.forEach((item, i) => {
        if (item.subItems) {
            const subSelectedIndex: number = item.subItems?.findIndex(subItem => (subItem.link as IPath | null)?.path === pathname);
            if (subSelectedIndex !== -1) {
                /*setTimeout(() => {
                }, 100);*/
                subIndex = subSelectedIndex;
                index = i;
            }
        }
    });
    itemsw.forEach((item, i) => {
        if (item.subItems) {
            const subSelectedIndex: number = item.subItems?.findIndex(subItem => (subItem.link as IPath | null)?.path === pathname);
            if (subSelectedIndex !== -1) {
                /*setTimeout(() => {
                }, 100);*/
                subIndex = subSelectedIndex;
                indexw = i;
            }
        }
    });

    function checkVisibility(item: IItem): boolean {

        const userType = checkUser(item.link as IPath);

        if (item.itemVisibilityType === 'all') {
            return true;
        }
        // if (user!.id == shop!.id && item.itemVisibilityType === 'guest') {
        //     // return userType === 'all';
        // }
        // @ts-ignore
        if (user!.is_super_admin && item.itemVisibilityType === 'super_admin') {
            return userType === 'super_admin';
        }
        // @ts-ignore
        if (user!.is_super_admin && item.itemVisibilityType === 'super_admin') {
            return userType === 'super_admin';
        }
        if (user!.id != 0 && item.itemVisibilityType === 'user') {
            return userType === 'user';
        }
        if (item.itemVisibilityType === 'shop') {
            return userType === 'shop';
        }
        return false;
    }

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const handleClick = () => {
        // Simulate a click on the target element associated with the "for" attribute
        const targetElement = document.querySelector("[for='addProduct']");
        if (targetElement) {
            (targetElement as HTMLElement).click();
        }

    };

    // const {unreadNotifications} = useAppSelector()

    useEffect(() => {
        dispatch(getAllUnreadNotifications());

    }, [dispatch]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined;

        if (user && shop && user.id === shop.id) {
            intervalId = setInterval(() => {
                dispatch(getAllUnreadNotifications());
            }, 11000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [dispatch]);

    const {unreadNotifications} = useSelector((state: RootState) => state.unreadNotificationsSlice);
    //
    return (
        <>

            <AddProductModal/>
            <div className="drawer-side z-[2] no-scrollbar h-screen mb-6 overflow-x-hidden">

                {/*<EditProfileModal handleIsOpen={(e: boolean) => setIsProfileModalOpen(e)} isOpen={isProfileModalOpen}/>*/}
                <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                <div className='w-60 border-l  no-scrollbar bg-white h-full'>
                    <div className='flex flex-col justify-between items-start h-full px-3 bg-white my-8 mb-20'>
                        <div className='flex flex-col items-start justify-start w-full md:pt-0 pt-10'>
                            <div className="flex justify-start w-full">
                                <label
                                    htmlFor={!Auth.get() || user!.id === 0 ? 'loginModal' : 'profileModal'}
                                    onClick={() => {
                                        if (!Auth.get()) {
                                            return dispatch(signUpAsDefault());
                                        }
                                        if (user!.id != 0) {
                                            setIsProfileModalOpen(true)
                                        }
                                    }}
                                    className={`${items.length === index ? "bg-black bg-opacity-5" : ''} flex flex-col py-2 w-full items-center hover:bg-black hover:bg-opacity-5 rounded-lg cursor-pointer`}>
                                    {
                                        user!.avatar == null ?
                                            <img src={empty} className={'w-[80px] h-[80px] rounded-full object-cover'}
                                                 alt=""/> :
                                            <NetworkImage url={user!.avatar} alt="profile img"
                                                          className='w-[80px] h-[80px] object-cover rounded-full'/>
                                    }
                                    <span className='text-base lg:flex text-black font-normal mt-4'><div>{user!.id == 0 || user!.id == undefined || user!.id == null ?
                                        <div>
                                            <label
                                                className={'cursor-pointer text-[#4192ef] text-[16px] font-medium'}
                                                htmlFor="loginModal">ورود / ثبت نام</label>
                                        </div> : <div>{user!.f_name} {user!.l_name}</div>}</div></span>
                                </label>
                            </div>

                            <div className="my-0 mt-4"></div>

                            {
                                user!.is_super_admin ? itemsw.map((item, i) => {
                                        // @ts-ignore
                                        return (
                                            checkVisibility(item) &&
                                            <div
                                                key={i + item.title}
                                                className={`${i === indexw ? "bg-[#4192EF]" : ''} ${i === indexw ? "bg-[#4192EF]" : item.subItems ? null : 'hover:bg-[#4192EF33]'} flex  w-full mt-1 items-center rounded-lg px-3`}
                                                onClick={(e) => handleOpenSubMenu(e, i)}
                                            >
                                                {
                                                    typeof item.link === 'string' ?
                                                        <label htmlFor={item.link?.toString() || '#'}
                                                               className={`${i === indexw ? "font-bold" : 'font-normal'} flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                            <div className="flex w-full ">
                                                                <item.icon
                                                                    color={i === indexw ? "white" : '#121212'}
                                                                    style={i === indexw ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === indexw ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === indexw ? "text-white" : 'text-[#121212]'}`
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </label>
                                                        :
                                                        <Link to={item.link?.path || '#'}
                                                              className={`${i === indexw ? "font-bold" : 'font-normal'} flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                            <div className="flex w-full ">
                                                                <item.icon
                                                                    color={i === indexw ? "white" : '#121212'}
                                                                    style={i === indexw ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === indexw ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === indexw ? "text-white" : 'text-[#121212]'}`
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                            {item.subItems && (
                                                                <ul className={`flex flex-col pl-0 mb-0 overflow-hidden w-full transition-all duration-300 ease-soft mr-4 mt-1`}
                                                                    style={{height: openItem === i ? subMenuHeight + 'px' : "2px"}}>
                                                                    {item.subItems.map((subItem, is) => (
                                                                        <li
                                                                            key={is + item.title}
                                                                            className="submenu flex w-full items-center">
                                                                            {/*@ts-ignore*/}
                                                                            <Link to={subItem.link?.path || '#'}
                                                                                  className={`${i === subIndex ? "font-semibold" : 'font-medium'} hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                <subItem.icon
                                                                                    className={`${i === indexw ? "text-white" : 'text-[#121212]'}`}
                                                                                    style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                    size={24}
                                                                                />
                                                                                <span
                                                                                    className={`text-black text-sm mr-4 duration-300 ${i === indexw ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </Link>

                                                }
                                            </div>
                                        )
                                    })
                                    : items.map((item, i) => {
                                        return !item.planId || shop!.plan_id! >= item.planId ? (

                                            checkVisibility(item) &&
                                            <div
                                                key={i + item.title}
                                                className={`${i === index ? "bg-[#4192EF]" : ''} ${i === index ? "bg-[#4192EF]" : item.subItems ? null : 'hover:bg-[#4192EF33]'} flex  w-full mt-1 items-center rounded-lg px-3`}
                                                onClick={(e) => item.onClick != null ? item.onClick() : handleOpenSubMenu(e, i)}
                                            >
                                                {
                                                    typeof item.link === 'string' ?
                                                        checkVisibility(item) &&
                                                        <label/* htmlFor={item.link?.toString() || '#'}*/
                                                            htmlFor="addProduct"
                                                            onClick={handleClick}
                                                               className={`${i === index ? "font-bold" : 'font-normal'} cursor-pointer flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                            <div className="flex w-full ">
                                                                <item.icon
                                                                    color={i === index ? "white" : '#121212'}
                                                                    style={i === index ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </label>
                                                        :
                                                        checkVisibility(item) && <Link to={item.link?.path || '#'}
                                                                                       className={`${i === index ? "font-bold" : 'font-normal'} flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                            <div className="flex w-full ">
                                                                <item.icon
                                                                    color={i === index ? "white" : '#121212'}
                                                                    style={i === index ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                                                    {
                                                                        item.spicial && <div className={`px-2 ${i === index ? 'bg-white text-[#4192EF]' : 'text-white bg-[#4192EF]'} w-max h-max rounded-full`}>{unreadNotifications.length}</div>
                                                                    }
                                    </span>
                                                            </div>
                                                            {
                                                                item.subItems && (
                                                                    <ul className={`flex flex-col pl-0 mb-0 overflow-hidden w-full transition-all duration-300 ease-soft mt-1`}
                                                                        style={{height: openItem === i ? subMenuHeight + 'px' : "2px"}}>
                                                                        {item.subItems.map((subItem, is) => (
                                                                            checkVisibility(subItem) && <li
                                                                                key={is + item.title}
                                                                                className="submenu flex w-full items-center">
                                                                                {/*@ts-ignore*/}
                                                                                {
                                                                                    // typeof item.type === 'string' ?
                                                                                    subItem.type === 'function' ? <label
                                                                                        htmlFor={subItem.link?.toString()}
                                                                                        className={`${i === subIndex ? "font-semibold" : 'font-medium'} cursor-pointer pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </label> : subItem.type == "link" ? <label
                                                                                        htmlFor={subItem.link?.toString() || '#'}
                                                                                        className={`${i === subIndex ? "font-semibold" : 'font-medium'} pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </label> : !subItem.planId || shop!.plan_id! >= subItem.planId ?
                                                                                        <Link to={
                                                                                            //@ts-ignore
                                                                                            subItem.link?.path || '#'}
                                                                                              className={`${i === subIndex ? "font-semibold" : 'font-medium'} pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                            <subItem.icon
                                                                                                className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                                style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                                size={24}
                                                                                            />
                                                                                            <span
                                                                                                className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                        </Link> :
                                                                                        <label
                                                                                            htmlFor={'getBetterPlan'}
                                                                                            // show={showModal}
                                                                                            // onClick={toggleModal}

                                                                                            className={`${i === subIndex ? "font-semibold" : 'font-medium'} pr-3 bg-gray-300 opacity-45 cursor-not-allowed w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                            <IconLock
                                                                                                className={`#DE0046`}
                                                                                                style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                                size={24}
                                                                                            />
                                                                                            <span
                                                                                                className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
        {subItem.title}
    </span>
                                                                                        </label>

                                                                                }
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )
                                                            }
                                                        </Link>
                                                }
                                            </div>
                                        ) : <div
                                            key={i + item.title}
                                            className={`${i === index ? "bg-gray-300" : 'bg-gray-300'} ${i === index ? "bg-gray-300" : item.subItems ? null : 'hover:bg-gray-300'} opacity-45 cursor-not-allowed flex  w-full mt-1 items-center rounded-lg px-3`}

                                        >
                                            {
                                                typeof item.link === 'string' ?
                                                    checkVisibility(item) && <label
                                                        className={`${i === index ? "font-bold" : 'font-normal'} cursor-not-allowed flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                        <div className="flex w-full ">
                                                            <item.icon
                                                                color={i === index ? "white" : '#121212'}
                                                                style={i === index ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                size={24}/>
                                                            <span
                                                                className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                {item.subItems && (
                                                                    <FaAngleLeft
                                                                        className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                            + (openItem === i ? " transform rotate-90" : "")
                                                                        }/>
                                                                )}
                                    </span>
                                                        </div>
                                                    </label>
                                                    :
                                                    checkVisibility(item) && <label
                                                        htmlFor={'getBetterPlan'}
                                                        className={`${i === index ? "font-bold" : 'font-normal'} cursor-not-allowed flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                        <div className="flex w-full ">
                                                            <IconLock
                                                                color={'#DE0046'}
                                                                size={24}/>
                                                            <span
                                                                className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                {item.subItems && (
                                                                    <FaAngleLeft
                                                                        className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                            + (openItem === i ? " transform rotate-90" : "")
                                                                        }/>
                                                                )}
                                    </span>
                                                        </div>
                                                        {
                                                            item.subItems && (
                                                                <ul className={`flex flex-col pl-0 mb-0  overflow-hidden w-full transition-all duration-300 ease-soft mt-1`}
                                                                    style={{height: openItem === i ? subMenuHeight + 'px' : "2px"}}>
                                                                    {item.subItems.map((subItem, is) =>

                                                                        (
                                                                            checkVisibility(subItem) && <li
                                                                                key={is + item.title}
                                                                                className="submenu flex w-full items-center">
                                                                                {/*@ts-ignore*/}
                                                                                {
                                                                                    // typeof item.type === 'string' ?
                                                                                    subItem.type === 'function' ? <label
                                                                                        // htmlFor={subItem.link?.toString()}
                                                                                        className={`${i === subIndex ? "font-semibold" : 'font-medium'} cursor-pointer pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </label> : subItem.type == "link" ? <label
                                                                                        // htmlFor={subItem.link?.toString() || '#'}
                                                                                        className={`${i === subIndex ? "font-semibold" : 'font-medium'} pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </label> : <div
                                                                                        className={`${i === subIndex ? "font-semibold" : 'font-medium'} pr-3 hover:bg-[#4192EF33] w-full rounded-lg flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </div>
                                                                                }
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            )
                                                        }
                                                    </label>
                                            }
                                        </div>
                                    })
                            }
                            {/**/}
                            <div className="divider my-1.5"></div>
                            <div className={'flex justify-between w-full mb-4'}>
                                {
                                    user!.is_super_admin ?

                                        <div></div> : !isMerchantFilled ?
                                            <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}
                                               className='flex mt-2 px-4 mr-2 py-2 bg-[#4192EF] rounded-lg w-full items-center'>
                                                <IconPhoneCall
                                                    color="white"
                                                />
                                                <span
                                                    className='text-base lg:flex text-white font-normal mr-4'>تماس با ما</span>
                                            </a> :

                                            user!.id === shop!.id ? '' : <Link
                                                to={Routes.cart.path}
                                                state={{previousLocation: location}}
                                                className='flex mt-2 px-4 mr-1 py-2 hover:bg-[#4192EF33] bg-[#4192EF] duration-300 hover:text-[#4192EF] text-white justify-between rounded-lg w-full items-center'>
                                                <div className={'flex indicator'}>
                                                    <div className="indicator">
                                                        <div
                                                            className="flex place-items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-basket"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2"
                                                                 stroke="currentColor"
                                                                 fill="none" strokeLinecap="round"
                                                                 strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                <path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                                                                <path
                                                                    d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z"/>
                                                                <path d="M17 10l-2 -6"/>
                                                                <path d="M7 10l2 -6"/>
                                                            </svg>
                                                            <span
                                                                className='lg:flex font-normal mr-4'>سبد خرید</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>

                                }
                            </div>

                            {
                                user!.id === shop!.id ? '' :
                                    <div className="divider my-1.5"></div>
                            }

                            <a href={'https://coloop.ir'} target="_blank" className={'mb-8 items-center flex mt-4 px-2'}>

                                <img src={logo} alt="" className={'w-[30px] cursor-pointer'}/>
                                <div className={'flex flex-col items-start justify-center mr-1'}>

                                    <span className={'text-[13px]'}><a href="https://coloop.ir">coloop.ir</a></span>
                                    <span className={'text-[13px]'}> گروه نرم افزاری کلوپ </span>

                                </div>
                            </a>

                        </div>
                    </div>
                </div>

            </div>
            <GetBetterPlanModal/>
            <EditProfileModal handleIsOpen={(e: boolean) => setIsProfileModalOpen(e)} isOpen={isProfileModalOpen}/>

        </>
    );
}

export default Sidebar;
