import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ContactRequests from "../../../requests/contactRequests";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import ContactAndCustomerRequests from "../../../requests/contactsAndCustomersRequests";

/*
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product*/

export interface ContactAndCustomerCredentials {
    id: number;
    username: string;
    f_name: string;
    l_name: string;
    mobile: string;
    mobile_number: string;

}

export interface ContactAndCustomerCreateCredentials {
    message: string,
    price: number,
    user_ids: number[]
}

export interface ContactAndCustomerUpdateCredentials extends ContactAndCustomerCredentials {
    id: number,
}

export const submit = createAsyncThunk(
    'campaign/post',
    async (credentials: ContactAndCustomerCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactAndCustomerRequests.create(credentials);
            // console.log('response', response.data.original)
            if (response.status === 200 || response.status === 201) {
                window.open(response.data.original);
                return {status: response.status, data: response}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const getAll = createAsyncThunk(
    'contactsAndCustomers/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactAndCustomerRequests.getAllContactsAndCustomers()
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'contactsAndCustomers/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactAndCustomerRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'contactsAndCustomers/update',
    async (credentials: ContactAndCustomerUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
