import API from "../services/api";
import {AxiosResponse} from "axios";

class AnalyticsRequests {

    public static async getAllAnalytics(): Promise<AxiosResponse> {
        return await API.authGet("analytics/all", {isUseLoading: true});
    }


}



export default AnalyticsRequests;