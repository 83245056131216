import API from "../services/api";
import {AxiosResponse} from "axios";
import {
    FormCredentials,
    FormQuestionCreateCredentials,
    FormQuestionUpdateCredentials
} from "../redux/dashboard/formQuestions/formQuestionsAction";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {SearchDiscountCodeCredentials} from "../redux/dashboard/discountCodes/discountCodesAction";

class FormQuestionRequests {

    public static async getAllFormQuestions(credentials: FormCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`title=${encodeURIComponent(credentials.title)}`);
        }
        // Always include the page parameter
        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `form/formQuestions?${queryString}`;

        // Send request to API
        return await API.authGet(url, { isUseLoading: true });
    }


    // public static async getAllFormQuestions(credentials: FormCredentials, i: number): Promise<AxiosResponse> {
    //     return await API.authGet(`form/formQuestions${`?title=${credentials.title ?? ''}&page=${i}`}`, {
    //         data: {
    //             title: credentials.title,
    //         },
    //         isUseLoading: true,
    //     })
    // }

    static async create(credentials: FormQuestionCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('form/formQuestions', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: string): Promise<AxiosResponse> {
        return await API.authDelete(`form/formQuestions/${id}`, {isUseLoading: true});
    }

    static async update(credentials: FormQuestionUpdateCredentials): Promise<AxiosResponse> {
        return await API.authPut(`form/formQuestions/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }

    static async getFormQuestionById(id: string): Promise<AxiosResponse>  {
        return await API.get("form/formQuestions/" + id, {isUseLoading: true});
    }

    static async getAllAnswersByQuestion(id: string): Promise<AxiosResponse>  {
        return await API.get("form/answers/" + id, {isUseLoading: true});
    }
}



export default FormQuestionRequests;