import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ShopRequests from "../../../requests/ShopRequests";
import {MediaType} from "../../../models/media";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import HighLightRequests from "../../../requests/highLightRequests";
import Category from "../../../models/category";
import CategoryRequests from "../../../requests/categoryRequests";
import {parseNumber} from "react-advanced-cropper";
import Api from "../../../services/api";

export interface onlineState {
    isServerOnline: boolean;
    status: number;
}


export const checkOnline = createAsyncThunk(
    'checkOnline',
    async (): Promise<onlineState> => {
        try {
            const response: AxiosResponse = await Api.request(process.env.REACT_APP_BASE_URL + '/api/alive', 'GET');
            if (response.status < 500) {
                return {
                    isServerOnline: true,
                    status: response.status,
                }
            } else {
                return {
                    isServerOnline: false,
                    status: response.status,
                }
            }
        } catch (e: any) {
            return {
                isServerOnline: false,
                status: e.response.status ?? 500,
            }
        }
    }
)