import React, {useEffect} from "react";
import {useAppSelector} from "redux/hooks";
import {submit, verifyCodeCredentials} from "redux/user/verifyCode/verifyCodeAction";
import {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {Formik, Field, Form, FormikHelpers} from 'formik';
import DefaultModal from "components/modal";
import {DefaultInput} from "../../../components/fieilds";
import {IconArrowRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {setMobileNumber} from "../../../redux/user/login/loginSlice";

function VerifyCodeModal() {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    //useSelector is a hook that allows you to extract data from the Redux store state, using a selector function.
    const {loading} = useAppSelector((state: RootState) => state.verifyCode);

    const {mobileNumber} = useAppSelector((state: RootState) => state.login);
    const mobileNumberR = useAppSelector((state: RootState) => state.registerSlice.mobileNumber);

    const handleVerifyCode = async (data: verifyCodeCredentials) => {
        try {
            dispatch(submit({...data, mobileNumber: mobileNumber ?? mobileNumberR}));
        } catch (error) {
            console.error(error);
        }
    };

    console.log('mobileNumber', mobileNumber, mobileNumberR)
    return (
        <DefaultModal
            label={'verifyCodeModal'} id='verifyCodeModal' modalBoxClasses={'px-3'}>
            <label htmlFor="verifyCodeModal">

                <div className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={''}>کد ورود</span>
                </div>
            </label>
            <Formik
                initialValues={{
                    mobileNumber: mobileNumber ?? mobileNumberR,
                    code: '',
                }}
                onSubmit={
                    (values: verifyCodeCredentials, {setSubmitting}: FormikHelpers<verifyCodeCredentials>) => {
                        handleVerifyCode(values);
                    }
                }

                //validation
                validate={(values: verifyCodeCredentials) => {
                    const errors: any = {};
                    if (!values.code) {
                        errors.code = 'کد را وارد کنید';
                    } else if (!/^[0-9]{4}$/.test(values.code)) {
                        errors.code = 'کد معتبر نیست';
                    }
                    return errors;
                }}
            >
                <Form method="dialog">
                    <h3 className="font-bold text-lg">تایید شماره موبایل</h3>
                    <div className='flex-col justify-start items-center w-full flex'>


                        <DefaultInput name='mobile_number' type='text' placeholder='شماره موبایل' value={mobileNumber ?? mobileNumberR}
                                      disabled={true}
                                      label='شماره موبایل'/>

                        <DefaultInput name='code' type='number' placeholder='کد ارسال شده'
                                        label='کد'/>

                    </div>
                    <div className="modal-action w-full flex justify-start">

                        <button type="submit"
                                className={`btn hover:text-[#4192EF] text-white ${loading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                loading ? 'صبر کنید' : 'ورود '
                            }
                        </button>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
}

export default VerifyCodeModal;
