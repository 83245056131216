import React from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {getAll, submit} from "../../../redux/dashboard/contacts/contactsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {DiscountType} from "../../../models/contact";
import Contact from "../../../models/contact";
import Product from "../../../models/product";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";



interface values {
    f_name: string;
    l_name: string;
    mobile_number: string;
}


const AddContactModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminContacts);

    return (
        <DefaultModal
            label={'addContact'} id='addContact' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    f_name: '',
                    l_name: '',
                    mobile_number: '',
                }}
                onSubmit={

                    (values: values, {resetForm, setSubmitting}: FormikHelpers<values>) => {

                        
                        try {

                            dispatch(submit(values));

                            (document.querySelector("[for='addContact']") as HTMLElement).click();

                            resetForm();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.f_name) {
                        errors.f_name = 'نام را وارد کنید';
                    }
                    if (!values.l_name) {
                        errors.l_name = 'نام خانوادگی را وارد کنید';
                    }
                    if (!values.mobile_number) {
                        errors.mobile_number = 'شماره موبایل را وارد کنید';
                    }
                    return errors;
                }}>
                    <Form method="dialog">
                        <label htmlFor={'addContact'} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                        <div className='flex-col justify-start items-center md:mt-4  mt-12 w-full flex'>


                            <DefaultInput name="f_name"
                                          placeholder='نام'
                                            label='نام'
                                            type="text"></DefaultInput>

                            <DefaultInput name="l_name"
                                            placeholder='نام خانوادگی'
                                            label='نام خانوادگی'
                                            type="text"></DefaultInput>

                            <DefaultInput name="mobile_number"
                                            placeholder='شماره موبایل'
                                            label='شماره موبایل'
                                            type="text"></DefaultInput>


                        </div>

                        <div className="modal-action w-full flex justify-start">
                            <button type="submit"
                                    className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                افزودن
                            </button>
                            <div className={'w-0.5 h-2'}></div>
                            <label
                                htmlFor="addContact"
                                className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                            </label>
                        </div>
                    </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddContactModal;
