import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Plan from "../../../models/plan";
import {LaravelValidationErrorType} from "../../../utils/types";
import Payment from "../../../models/payment";
import {getAllBuyed} from "./buyedPlansAction";

interface State extends DefaultStates {
    buyedPlans: Payment[];
    buyedPlansList: Payment[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    buyedPlans: [],
    buyedPlansList: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
    paginate: 1,
    // discountCodes: [],
    isEnd: false,
    loadingMore: false,
};

const BuyedPlansSlice = createSlice({
    name: 'buyed',
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1; // Update loadingMore state
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.paginate = 1;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllBuyed.pending, (state) => {
            if (state.paginate === 1) {
            state.loading = true;
        }else {
            state.loadingMore = true;
        }
        })
        builder.addCase(getAllBuyed.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const plans: Payment[] = [];
                const title = response.data[1]
                for (let i = 0; i < response.data[0].data.length; i++) {
                    const planList = response.data[0].data[i];
                    // console.log('console.log', category.id)
                    plans.push(new Payment({

                        id: planList.id,
                        status: planList.status,
                        description: planList.description,
                        final_price: planList.final_price,
                        full_name: planList.full_name,
                        mobile_number: planList.mobile_number,
                        result_code: planList.result_code,
                        success_url: planList.success_url,
                        total_price: planList.total_price,
                        user_ip: planList.user_ip,
                        paymentable: planList.paymentable,
                        created_at: planList.created_at,
                        user: planList.user,
                        address: planList.address,
                        user_status: planList.user_status
                    }));
                }
                if (response.data[0].data.length == 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1 ) {
                    state.buyedPlansList.push(plans);
                }
                else if (title && (title.title || title.price || title.product || title.category)) {
                    state.buyedPlansList = [plans];
                } else if (state.paginate === 1) {
                    state.buyedPlansList = [plans];
                } else {
                    state.buyedPlansList.push(plans);
                }
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllBuyed.rejected, (state, action) => {
                state.loading = false;
            }
        )
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true;
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });

    },
});

export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = BuyedPlansSlice.actions;

export default BuyedPlansSlice.reducer;