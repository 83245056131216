interface DiscountProductsInterface {
    id: number;
    title: string;
}

class DiscountProducts {

    id: number;
    title: string;
    constructor(data: DiscountProductsInterface) {
        this.id = data.id;
        this.title = data.title;
    }
}

export default DiscountProducts;