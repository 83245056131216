export interface INameSetting {
}

export interface SettingFieldInterface {
    name: string;
    family: string,
    type: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean",
    placeholder: string,
    plan_id: number,
    title: string,
    classList: string,
}

class SettingField {

    name: string;
    family: string;
    type: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";
    placeholder: string;
    plan_id: number;
    title: string;
    classList?: string;

    constructor(data: SettingFieldInterface) {
        this.name = data.name;
        this.family = data.family;
        this.plan_id = data.plan_id;
        this.type = data.type;
        this.placeholder = data.placeholder;
        this.title = data.title;
        this.classList = data.classList;
    }

}

export default SettingField;