import React from 'react';
import {Field, useField} from 'formik';
// import values = formatters.values;


interface Props {
    name: string;
    className?: string;
    border?: string;
    parentClassName?: string;
    placeholder?: string;
    type?: string;
    label?: string;
    label2?: string;
    addDiv?: any;
    value?: any;
    disabled?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DefaultInput: React.FC<Props> = ({name, addDiv, className, border, type, label, placeholder, disabled, value, parentClassName, onChange}) => {
    const [field, meta] = useField({
        name: name,
        disabled: false,
        onChange: onChange,
    });
    return (
        disabled ? <div className={`mb-4 w-full md:border-b-transparent border-b border-b-[#e7e7e7]  ${parentClassName ? parentClassName : ""}`}>
            <label className="flex justify-between label h-1 md:h-max">
                <span className="label-text text-[#737373]">{label}</span>
                <span className="label-text text-[#4192EF] text-[12px]">{addDiv ? addDiv : ''}</span>
            </label>
            <div
                {...field}
                id={name}
                className={`disabledField focus:outline-none md:border-b-none md:input py-2 md:input-bordered w-full ${className ? className : ""}`}
                // @ts-ignore
                placeholder={placeholder} aria-label={name} aria-describedby="email-addon">
                {value || ''}
            </div>
        </div> : <div className={`mb-4 w-full md:border-b-transparent border-b border-b-[#e7e7e7] ${parentClassName ? parentClassName : ""}`}>
            <label className="label md:h-max h-1">
                <span className="label-text text-[#737373] text-[13px]">{label}</span>
                <span className="label-text text-[#737373] text-[13px]">{addDiv ? addDiv : ''}</span>
            </label>
            <Field
                {...field}
                type={type}
                id={name}
                name={name}
                value={field.value || value || ''}
                className={`focus:outline-none md:input py-1.5 md:border-b-none border-b border-b-[#e7e7e7] md:input-bordered w-full ${className ? className : ""}`}
                placeholder={placeholder}
                aria-label={name}
                aria-describedby="email-addon"/>
            {meta.touched && meta.error ? (
                <div className="text-[#DE0046]">{meta.error}</div>
            ) : null}
        </div>
    );
};

export const DefaultBooleanField: React.FC<{name: string, placeholder: string, parentClassName?: string, bool?:  {
    yesTitle?: string,
        noTitle?: string,
    }}> = ({name, placeholder, parentClassName, bool}) => {
    const [field, meta] = useField({
        name: name,
        disabled: false,
    });
    return (
        <div className={`mb-4 w-full  ${parentClassName ? parentClassName : ""}`}>
            <label className="label">
                <span className="label-text">{placeholder}</span>
            </label>
            <div className="flex">
                <div className="form-control mr-2">
                    <label className="label cursor-pointer">
                        <Field
                            {...field}
                            type="radio" value={'true'} name={name} className="radio checked:bg-[#4192EF]"  />
                        <span className="label-text mr-2">{bool?.yesTitle ?? "بله"}</span>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <Field
                            {...field} type="radio" value={'false'} name={name} className="radio checked:bg-[#DE0046]" />
                        <span className="label-text mr-2">{bool?.noTitle ?? "خیر"}</span>
                    </label>
                </div>
            </div>
            {meta.touched && meta.error ? (
                <div className="text-[#DE0046]">{meta.error}</div>
            ) : null}
        </div>
    );
};


//formik textarea by using useField to get errors
export const DefaultTextArea: React.FC<Props> = ({name, className, label, placeholder, parentClassName, disabled}) => {
    const [field, meta] = useField(name);
    return (
        disabled ? <div className={`w-full  ${parentClassName ? parentClassName : ""}`}>
            <label className="label md:h-max h-1">
                <span className="label-text text-[#737373] text-[13px]">{label}</span>
            </label>
            <textarea
                // disabled={}
                {...field}
                disabled={disabled}
                value={field.value || ''}
                className={`md:textarea disabledField focus:outline-none md:textarea-bordered py-3 border-b border-b-[#e7e7e7] w-full ${className ? className : ""}`} placeholder={placeholder}
                aria-label={name} aria-describedby="email-addon"/>
            {meta.touched && meta.error ? (
                <div className="text-[#DE0046]">{meta.error}</div>
            ) : null}
        </div> :
    <div className={`w-full  ${parentClassName ? parentClassName : ""}`}>
            <label className="label md:h-max h-1">
                <span className="label-textG text-[#737373] text-[13px]">{label}</span>
            </label>
            <textarea
                // disabled={}
                {...field}
                value={field.value || ''}
                className={`md:textarea focus:outline-none md:textarea-bordered py-3 border-b border-b-[#e7e7e7] w-full ${className ? className : ""}`} placeholder={placeholder}
                aria-label={name} aria-describedby="email-addon"/>
            {meta.touched && meta.error ? (
                <div className="text-[#DE0046]">{meta.error}</div>
            ) : null}
        </div>
    );
}

//select input
type SelectProps = Props & {
    options: { value: string; label: string }[] | any;
    defaultValue?: string;
}
export const DefaultSelect: React.FC<SelectProps> = ({name, addDiv, className, label, label2, placeholder, options, defaultValue}) => {
    const [field, meta, helpers] = useField({
        name,
        value: defaultValue,
        type: 'select',
    });

    const selectOptions: { value: string; value2?: string | null; label: string; label2?: string | null }[] = options.map((option: { label: any; value: any; label2?: any; value2?: any; }) => {
        return {
            label: option.label,
            label2: option.label2,
            value: option.value,
            value2: option.value2
        }
    });

    selectOptions.unshift({
        label: placeholder || "انتخاب کنید",
        label2: placeholder || "انتخاب کنید",
        value: "",
        value2: "",
    });

    return (
        <div className="w-full mb-2">
            <label className="flex justify-between label">
                <span className="label-text text-[14px] text-[#121212]">{label}</span>
                <span className="label-text text-[#4192EF] text-[12px]">{label2 ? label2 : addDiv}</span>
            </label>
            <Field
                as="select" {...field}
                className={`select focus:border-none md:border md:border-[#d2d2d2] border-b border-b-[#f2f2f2] outline-none rounded-lg focus:outline-none w-full pr-7 ${className ? className : ""}`}
                placeholder={placeholder}>
                {selectOptions.map((option) => (
                    <option className={' cursor-pointer'} key={option.value2 == null ? option.value : option.value2} value={option.value2 == null ? option.value : option.value2} selected={option.value === defaultValue}>
                        {option.label2 == null ? option.label : option.label2}
                        {/*{add!}*/}
                    </option>

                ))}
            </Field>
            {meta.touched && meta.error ? (
                <div className="text-[#DE0046]">{meta.error}</div>
            ) : null}
        </div>
    );
}
