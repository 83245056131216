import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {AnswerCredentials} from "../redux/dashboard/answers/answersAction";

class AnswerRequests {

    public static async getAllAnswers(): Promise<AxiosResponse> {
        return await API.authGet("form/answers", {isUseLoading: true});
    }

    static async create(credentials: AnswerCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('form/answers', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

}



export default AnswerRequests;