import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";
import store, {RootState} from "../../../redux/store";
import DefaultModal from "components/modal";
import {useAppSelector} from "../../../redux/hooks";
import {checkUser} from "../../../utils/funcions";


const LoginRedirect = () => {
    const {user, shop} = useAppSelector((state: RootState) => state.user);
    const userType = checkUser();

    return (
        <div className={'w-72 h-72 px-3'}>
            <Link to={user!.id != shop!.id ? Routes.chat(shop!.id).path : Routes.users.path}
                  className='flex flex-1 py-2 justify-center items-center bg-[#DE0046] rounded-lg cursor-pointer border border-[#9999999] text-[14px]'>
                <span className='text-black'>{userType == 'shop' ? "لیست پیام ها " : "ارسال پیام"}</span>


                <input type="checkbox" id="my_modal_7" className="modal-toggle"/>
                <div className="modal" role="dialog">
                    <div className="modal-box">

                        <h3 className="font-bold text-lg">پیام رسانی</h3>
                        <p className="py-3">این بخش در بروزرسانی های بعدی اضافه خواهد شد.</p>
                    </div>
                    <label className="modal-backdrop" htmlFor="my_modal_7">بستن</label>
                </div>

            </Link>
        </div>

    );
}

export default LoginRedirect;