import API from "../services/api";
import { AxiosResponse } from "axios";
import { convertObjectOfCamelCaseToObjectOfKeyBySnakeCase } from "../utils/funcions";

class LikeRequests {
    static async create(
        credentials: { product_id: number, is_like: boolean },
        onUploadProgress?: (progressEvent: number) => void
    ): Promise<AxiosResponse> {
        return await API.authPost(`productLike/${credentials.product_id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }
}

export default LikeRequests;
