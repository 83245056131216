import React, {ReactNode, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Routes from "../utils/routing/routes";
import Auth from "../services/savedData/auth";
import {EditProfileModal} from "../pages/front/profile/edit";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

const ShopGuard: React.FC<{children: ReactNode}> = ({ children }) => {

    // Auth.del();

    const token: string | null = Auth.get();

    const {user, shop} = useSelector((state: RootState) => state.user);
    if (user!.id !== shop!.id) {
        return <Navigate to={Routes.products.path}/>
    }

    return (
        <>
            {children}
        </>
    );
};

export default ShopGuard;
