import Modals from "./modals";
import {closeModal} from "../../redux/components/modalSlice";
import React, {ReactNode, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {remRoute, setRoute} from "../../redux/tools/routeSlice";
import ImageCropper from "../../utils/media/crop";
import {FullscreenMedia} from "../../utils/media/media_select";

export default function RootComponents() {

    const dispatch = useAppDispatch();
    const route = useAppSelector(state => state.route.route);
    const crop = useAppSelector(state => state.crop.img);
    const {media, selectedIndex} = useAppSelector(state => state.mediaView);
    const progressPercentage: number = useAppSelector(state => state.progress.progress);
    const {content, className, isOpen, onConfirm, outerClassName} = useAppSelector(state => state.modal);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (route) {
            navigate(route);
            dispatch(remRoute());
        }
    }, [route]);

    useEffect(() => {
        if (crop) {
            document.getElementById('cropImage')?.click();
        }
    }, [crop]);

    return (
        <>

            <FullscreenMedia
                media={media}
                selectedIndex={selectedIndex}
            />

            {isOpen &&
                <Modals
                   onConfirm={onConfirm}
                   classNames={className}
                   outerClassName={outerClassName}
                    onClose={() => dispatch(closeModal())}>
                    {content}
                </Modals>
            }


            <ImageCropper
                file={crop}
            />

            {/*progress percentage on top of page if progress not equal to 0*/}
            {progressPercentage !== 0 &&
                <div className='fixed top-0 left-0 w-full h-2 bg-green-500 z-50 transition-width duration-500 ease-in-out'
                        style={{width: `${progressPercentage}%`}}/>
            }


            <div><Toaster/></div>
        </>
    );
}