import User from "./user";
import payment from "./payment";

export interface IUserWithPayments {
    total_price?: string;
    final_price?: string;
    payments_sum_total_price?: string;
    full_name?: string;
    username?: string;
    f_name?: string;
    data: payment[];
    payments?: payment[];
    tedad?: number;
    created_at?: string,

}

class UserWithPayments {

    public total_price?: string;
    public final_price?: string;
    public payments_sum_total_price?: string;
    public f_name?: string;
    public full_name?: string;
    public username?: string;
    public payments?: payment[];
    public data: payment[];
    public tedad?: number;
    public created_at?: string;

    constructor(data: IUserWithPayments) {
        this.total_price = data.total_price;
        this.final_price = data.final_price;
        this.payments_sum_total_price = data.final_price;
        this.f_name = data.f_name;
        this.full_name = data.full_name;
        this.username = data.username;
        this.payments = data.payments;
        this.data = data.data;
        this.tedad = data.tedad;
        this.created_at = data.created_at;

    }

}

export default UserWithPayments;