import AddContactModal from './add';
import ContactsList from "./list";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";
function Index() {


    return (
        <>


            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 px-4 z-[40] py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>مخاطبین</span>
            </Link>
            <ContactsList></ContactsList>



        </>
    );
}

export default Index;