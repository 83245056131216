import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import Auth from "services/savedData/auth";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {goToRoute} from "utils/routing/baseRoutes";
import Routes from "utils/routing/routes";
import {DefaultResponse} from "../../mainSlice";
import SavedData from "../../../services/savedData/savedData";
import api from "../../../services/api";

type loginResponse = DefaultResponse & {
    mobileNumber?: string;
}

export const submit = createAsyncThunk(
    'login/post',
    async (credentials: {mobileNumber: string, password: string, isUsePassword: boolean }): Promise<loginResponse>=> {
        try {
            const response: AxiosResponse = await UserRequests.login(credentials);
            if (response.status === 200) {
                const token = await response.data;
                await Auth.set(token);
                //reload

                (new SavedData( api.getUrlFromEndpoint('users/get'))).del();
                window.location.reload();
                return {status: response.status, data: token}
            }else if (response.status === 201) {
                (document.querySelector('#verifyCodeModal') as HTMLInputElement).checked = true;
                return {status: response.status, data: null, mobileNumber: credentials.mobileNumber}
            }else if (response.status === 203) {
                (document.querySelector('#registerModal') as HTMLInputElement).checked = true;
                return {status: response.status, data: null, mobileNumber: credentials.mobileNumber}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            console.error(e);
            toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)