import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import UserRequests from "../../../requests/userRequests";
import store from "../../store";
import ShopRequests from "../../../requests/ShopRequests";
import {remProgress} from "../../tools/progressSlice";


// export interface ContactAndCustomerCredentials {
//     id: number;
//     username: string;
//     f_name: string;
//     l_name: string;
//     avatar: string;
//     clv: string;
//
// }

export interface SearchUsersCredentials {
    title: string | null,
    mobilenumber: string | null,
    minPrice: string | null,
    maxPrice: string | null,
    status: string | null,
}
export const getAllUsersForSingleMainPage = createAsyncThunk(
    'mainUsers/getAll',
    async (credentials?: SearchUsersCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await UserRequests.getUsers(credentials ?? {} as SearchUsersCredentials, store.getState().mainUsers.paginate);
            if (response.status === 200) {
                return {status: response.status, data: [response.data, credentials ]}
            } else if (response.status ===
                422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const blockUserFromShop = createAsyncThunk(
    'mainUsers/changeStatus',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ShopRequests.blockUserFromShop(id)
            if (response.status === 200 || response.status === 201) {
                // const data = response.data;
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove