import API from "../services/api";
import {AxiosResponse} from "axios";
import {MediaType} from "../models/media";
import {
    ContactCreateCredentials, ContactCredentials,
    ContactUpdateCredentials, SearchContactCredentials
} from "../redux/dashboard/contacts/contactsAction";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {FormCredentials} from "../redux/dashboard/formQuestions/formQuestionsAction";
import {SearchDiscountCodeCredentials} from "../redux/dashboard/discountCodes/discountCodesAction";

class ContactRequests {

    public static async getAllContacts(credentials: SearchContactCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`title=${encodeURIComponent(credentials.title)}`);
        }
        if (credentials.mobilenumber) {
            queryParams.push(`mobilenumber=${encodeURIComponent(credentials.mobilenumber.toString())}`);
        }

        // Always include the page parameter
        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `dashboard/contacts?${queryString}`;

        // Send request to API
        return await API.authGet(url, { isUseLoading: true });
    }

        // public static async getAllContacts(credentials: SearchContactCredentials, i: number): Promise<AxiosResponse> {
        //     return await API.authGet(`dashboard/contacts${`?username=${credentials.title ?? ''}&page=${i}`}`, {
        //     data: {
        //         username: credentials.title,
        //     },
        //     isUseLoading: true,
        // })
        // }

    static async create(credentials: ContactCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('dashboard/contacts', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`dashboard/contacts/${id}`, {isUseLoading: true});
    }

    static async update(credentials: ContactUpdateCredentials): Promise<AxiosResponse> {
        return await API.authPut(`dashboard/contacts/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default ContactRequests;