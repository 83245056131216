import {ReactNode} from 'react';
import {useParams} from 'react-router-dom';

interface ModalProps {
    onClose: () => void;
    children: ReactNode;
    classNames?: string;
    onConfirm?: () => void;
    outerClassName?: string;
}

export default function Modals({onClose, children, classNames, onConfirm, outerClassName}: ModalProps) {
    return (
        <>
            <dialog open onClose={onClose} id="my_modal_5"
                    className={`modal modal-middle opacity-50 bg-[#00000060] w-full h-full flex flex-col justify-start items-center ` }>
                <div onClick={onClose} className='h-1/6 w-full'></div>
                <form method="dialog" className={`modal-box flex flex-col justify-center items-center bg-white p-6 rounded-xl ${outerClassName}`}>
                    <div className={` ${classNames}`}>
                        {children}
                    </div>
                    <div className="modal-action flex justify-center items-center w-full">
                        {onConfirm && <button onClick={onConfirm} className="btn bg-[#4192EF] text-white">تایید</button>}
                        <button onClick={onClose} className="btn text-white bg-[#DE0046]">بستن</button>
                    </div>
                </form>
            </dialog>
        </>
        // <div open onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
        //     <div className="flex items-center justify-center min-h-screen">
        //         {/* eslint-disable-next-line react/jsx-no-undef */}
        //         <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
        //
        //         <div className="relative bg-white rounded max-w-sm mx-auto">
        //             hello
        //         </div>
        //     </div>
        // </div>
    );
}