import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {submit} from "redux/user/login/loginAction";
import {LaravelValidationErrorType} from "../../../utils/types";
import {setMobileNumber} from "../login/loginSlice";
import store from "../../store";

interface State extends DefaultStates {
    token: string | null;
    mobileNumber: string,
    isReseller: boolean
}

const initialState: State = {
    token: null,
    loading: false,
    validationErrors: null,
    mobileNumber: '',
    isReseller: false,
};

const RegisterSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.loading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.token = response.data as string;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            } else if (response.status === 201 || response.status === 203) {
                state.mobileNumber = response.mobileNumber as string;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.loading = false;
        })
    },
});

export default RegisterSlice.reducer;