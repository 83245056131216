import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
import { getHighlights } from "redux/dashboard/highLights/highLightsAction";
import Highlight from "../../../models/highLightedProducts";

interface State extends DefaultStates {
    highLights: Highlight[] | null;
    loadingHighlight: boolean;
    submitLoading: boolean;
    validationErrors: LaravelValidationErrorType | null;
}

const initialState: State = {
    highLights: null,
    submitLoading: false,
    loading: false,
    loadingHighlight: false,
    validationErrors: null,
};

const HighLightSlice = createSlice({
    name: 'highLight',
    initialState,
    reducers: {
        setHighlights(state, action: PayloadAction<Highlight[]>) {
            state.highLights = action.payload;
        },
        setLoadingHighlight(state, action: PayloadAction<boolean>) {
            state.loadingHighlight = action.payload;
        },
        clearHighLights(state) {
            state.highLights = null;
        },
        setValidationErrors(state, action: PayloadAction<LaravelValidationErrorType | null>) {
            state.validationErrors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHighlights.pending, (state) => {
            state.loadingHighlight = true;
        });
        builder.addCase(getHighlights.fulfilled, (state, action) => {
            state.loadingHighlight = false;
            const response = action.payload;
            if (response.status === 200) {
                const highLights: Highlight[] = [];
                const keys = Object.keys(response.data.data);

                keys.forEach((key) => {
                    const item = response.data.data[key];
                    if (item && item.id) {
                        const newHighlight = new Highlight({
                            id: item.id,
                            created_at: item.created_at,
                            updated_at: item.updated_at,
                            name: item.name,
                            cover: item.cover,
                        });
                        highLights.push(newHighlight);
                    }
                });

                state.highLights = highLights;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }

        });
        builder.addCase(getHighlights.rejected, (state, action) => {
            state.loadingHighlight = false;
        });
    },
});

export const {
    setHighlights,
    setLoadingHighlight,
    clearHighLights,
    setValidationErrors,
} = HighLightSlice.actions;

export default HighLightSlice.reducer;
