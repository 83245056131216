import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAppSelector} from "../redux/hooks";
import {RootState} from "../redux/store";
import Routes from "../utils/routing/routes";

interface VerifyCodeGuardProps {
    children: React.ReactNode;
}

const VerifyCodeGuard: React.FC<VerifyCodeGuardProps> = ({ children }) => {
    const { mobileNumber } = useAppSelector((state: RootState) => state.login);

    if (!mobileNumber) {
        return <Navigate to={Routes.login.path} />;
    }

    return (
        <>
            {children}
            </>
        );
};

export default VerifyCodeGuard;
