import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {RFMCredentials} from "../redux/dashboard/RFM/RFMAction";

class TBPRequests {

    public static async getAllTBP(credentials: RFMCredentials): Promise<AxiosResponse> {
        return await API.authGet(`analysis/TBP`,{
            isUseLoading: true,
        });
    }

}

export default TBPRequests;