import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import Plan from "../../../models/plan";
import PlanRequests from "../../../requests/planRequests";
import {parseNumber} from "react-advanced-cropper";
import CartRequests from "../../../requests/cartRequests";
import {CartCheckoutResponse} from "../cart/cartAction";

export const submit = createAsyncThunk(
    'plans/add',
    async (credentials: {
        title: string,
        description: string,
        price: number,
        planName?: string,
        readMoreTitle?: string,
        end_time?: number,
        properties?: {
            title: string,
            is_read_more: boolean,
        }[]
    }): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.create(credentials)
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


export const getAllPlans = createAsyncThunk(
    'plans/getAll',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.getAllPlans()
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const removePlan = createAsyncThunk(
    'plans/remove',
    async (id: number): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export interface PlanCheckoutResponse extends DefaultResponse {
}

export interface PlanCheckoutCredentials {
    price: string
}

// export const checkoutCart =  async (credentials: PlanCheckoutCredentials): Promise<PlanCheckoutResponse> => {
//     try {
//         const response: AxiosResponse = await CartRequests.checkout(credentials)
//         if (response.status === 200 || response.status === 201 || response.status === 202) {
//             window.open(response.data);
//             return {status: response.status, data: response.data}
//         } else if (response.status === 422) {
//             
//             const errors = await response.data as LaravelValidationErrorType;
//             toast422(errors);
//             return {
//                 status: response.status,
//                 data: response.data
//             }
//         }
//         toastError();
//         return {
//             status: response.status,
//             data: response.data
//         }
//     } catch (e) {
//         toastError();
//         return {
//             status: 500,
//             data: {}
//         }
//     }
// }



export const update = createAsyncThunk(
    'plans/update',
    async (credentials: {id: number, title: string, description: string, price: number}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await PlanRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: credentials}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
//update
