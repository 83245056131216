import {IUser} from "./user";

interface ReferralCheckoutsInterface {
    referral_inv_count: number;
    id: number;
    shop_count: string;
    user: IUser;
}

class ReferralCheckouts {
    id: number;
    shop_count: string;
    referral_inv_count: number;
    user: IUser;

    constructor(data: ReferralCheckoutsInterface) {
        this.id = data.id;
        this.shop_count = data.shop_count;
        this.referral_inv_count = data.referral_inv_count;
        this.user = data.user;
    }
}

export default ReferralCheckouts;