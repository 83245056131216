import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllInventoryProducts} from "./inventoryProductsAction";
import Payment from "../../../models/payment";

interface State extends DefaultStates {
    productsLength: number,
    productsPrice: number,
    ordersPrice: number,
    orders: Payment[],
    submitLoading: boolean;
}

const initialState: State = {
    productsLength: 0,
    productsPrice: 0,
    ordersPrice: 0,
    orders: [],
    loading: false,
    submitLoading: false,
    validationErrors: null,
};

const InventoryProductsSlice = createSlice({
    name: "inventoryProducts",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllInventoryProducts.pending, (state) => {
                state.loading = true;
        })
        builder.addCase(getAllInventoryProducts.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                // console.log('console.log', response.data
                // )
               state.productsLength = response.data.productsLength
               state.productsPrice = response.data.productsPrice
               state.ordersPrice = response.data.ordersPrice
               state.orders = response.data.orders
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllInventoryProducts.rejected, (state, action) => {

            state.loading = false;
        })
    },
});

export default InventoryProductsSlice.reducer;