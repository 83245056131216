import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Routes, useLocation } from 'react-router-dom';
import { indexRoutes, modalRoutes } from '../../utils/routing/routes';
import { isIndexRoute } from '../../utils/funcions';
import LoginModal from './auth/login';
import RegisterModal from './auth/sign-up';
import VerifyCodeModal from './auth/verifyCode';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import ProductFilterModal from './products/filter';
import Sidebar from 'pages/front/partials/sidebar';

function Index() {
    const location = useLocation();
    const { pathname } = location;
    const { shop,settings } = useAppSelector((state: RootState) => state.user);
    const previousLocation = location.state?.previousLocation;
    // const isAnalytics = window.location;
    // Ensure that pageTitle is always a string
    const pageTitle = settings.find(e => e.name === 'shop_name')?.value  ?? 'باشگاه مشتریان کلوپ';
    const faviconUrl = shop?.avatar ?  `https://back.coloop.ir/${shop.avatar}` : 'https://back.coloop.ir/uploads/avatars/coloop-logo-44-min.-copy.png';
    console.log(
        'cons', faviconUrl
    )

    return (
        isIndexRoute(pathname) ? (
            <HelmetProvider>
                <div className="flex w-full justify-center">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{pageTitle}</title>
                        <link rel="icon" href={faviconUrl} />
                        {/*<link rel="canonical" href="http://mysite.com/example"/>*/}
                    </Helmet>
                    <div className="drawer md:drawer-open w-full h-full flex max-w-[1366px] mb-12 overflow-x-hidden">
                        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                        <LoginModal />
                        <ProductFilterModal />
                        {/*<EditProfileModal/>*/}
                        <RegisterModal />
                        <VerifyCodeModal />

                        <Sidebar />
                        <div className="drawer-content w-full h-full mr-0 md:mr-64 transition-all">
                            <Routes location={previousLocation || location}>
                                {indexRoutes()}
                            </Routes>
                            {previousLocation && (
                                <Routes>
                                    {modalRoutes()}
                                </Routes>
                            )}
                        </div>
                    </div>
                </div>
            </HelmetProvider>
        ) : null
    );
}

export default Index;
