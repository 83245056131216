import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Category from "../../../models/category";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getProductsCategory} from "./productsCategoryAction";
import Product from "../../../models/product";

interface State extends DefaultStates {
    category: Category|null;
    categoryList: Category[][];
    submitLoading: boolean;
    paginate: number;
    catId: number | null;
    loadingMore: boolean,
    isEnd: boolean,
}

const initialState: State = {
    category: null,
    categoryList: [],
    submitLoading: false,
    loading: false,
    paginate: 1,
    catId: 1,
    loadingMore: false,
    isEnd: false,
    validationErrors: null,
};

const CategoriesSlice = createSlice({
    name: 'productsCategory',
    initialState,
    reducers: {
        clearCategoryData: (state) => {
            state.paginate = 1;
            state.catId = null;
            state.categoryList = [];
            state.isEnd = false;
        },
        setPaginate: (state) => {
            state.paginate = state.paginate + 1;
        },
        setPaginateToStart: (state) => {
            state.paginate = 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductsCategory.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getProductsCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const category = response.data[0];
                const categories: Category[] = [];
                if (response.data[1]) {
                    state.catId = response.data[1]
                }
                    categories.push(new Category({id: category.id, title: category.title, parentId: category.parent_id, products: category.products}));

                if (response.data[0].products.length === 0 || response.data[0].products.length < 30) {
                    state.isEnd = true;
                }
                if (state.paginate === 1) {
                    state.categoryList = [categories];
                } else {
                    state.categoryList.push(categories);
                }
                // state.categoryList = []
                // state.categoryList.push(categories)
                // console.log('state.categoryList', state.categoryList)

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        //منم بش کردم عادت الان چندوقته که دوری
        builder.addCase(getProductsCategory.rejected, (state, action) => {
                state.loading = false;
                // state.loadingMore = false;
            }
        ) .addCase("setLoadingMore/pending", (state) => {
            state.loadingMore = true;
        })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });
    },
});

export const { setPaginate, setPaginateToStart, clearCategoryData } = CategoriesSlice.actions;
export default CategoriesSlice.reducer;