import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import AdvancedCategory from "../../../models/advancedCategory";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAll} from "redux/dashboard/advancedCategory/advancedCategoryAction";
import Product from "../../../models/product";
import {toastSuccess} from "../../../utils/funcions";

interface State extends DefaultStates {
    advancedCategories: AdvancedCategory[];
    submitLoading: boolean;
}

const initialState: State = {
    advancedCategories: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const AdvancedCategorySlice = createSlice({
    name: 'advancedCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                state.advancedCategories.unshift(new AdvancedCategory({...data, lowValue: data.lowValue, highValue: data.highValue, midValue: data.midValue,}));
                }
            else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
    },
});

export default AdvancedCategorySlice.reducer;