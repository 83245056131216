
interface NotificationInterface {

    id: number;
    message?: string;
    title?: string;
    user_id?: number;
    user_ip?: string;
    shop_id?: number;
    shop_seened?: boolean;
    user_seened?: boolean;
    created_at?: string;
}

class Notification {

    id: number;
    message?: string;
    title?: string;
    user_id?: number;
    user_ip?: string;
    shop_id?: number;
    shop_seened?: boolean;
    user_seened?: boolean;
    created_at?: string;

    constructor(data: NotificationInterface) {
        this.id = data.id;
        this.message = data.message;
        this.title = data.title;
        this.user_id = data.user_id;
        this.user_ip = data.user_ip;
        this.shop_id = data.shop_id;
        this.shop_seened = data.shop_seened;
        this.user_seened = data.user_seened;
        this.created_at = data.created_at;

    }
}

export default Notification;