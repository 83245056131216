import PlansList from "./list";
import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";


function UsersIndex() {

    const {shop, user} = useSelector((state: RootState) => state.user);

    // const [showDropDown, setDropDown] = useState(false);

    // const location = useLocation();
    return (
        <>
            <PlansList></PlansList>
        </>
    );
}

export default UsersIndex;