import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllShops} from "./ShopUsersAction";
import User from "../../../models/user";
import Product from "../../../models/product";

interface State extends DefaultStates {
    shops: User[];
    paginate: number;
    lastPage: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}


const initialState: State = {
    shops: [],
    paginate: 1,
    lastPage: 1,
    submitLoading: false,
    loading: false,
    isEnd: false,
    validationErrors: null,
    loadingMore: false,
};
const ShopUsersSlice = createSlice({
    name: 'RFM',
    initialState,

    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllShops.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAllShops.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                state.shops.push(response.data.data);
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllShops.rejected, (state, action) => {

                state.loading = false;
            }
        )
    },
});

export default ShopUsersSlice.reducer;