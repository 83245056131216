import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ContactRequests from "../../../requests/contactRequests";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import {FormCredentials} from "../formQuestions/formQuestionsAction";

export interface ContactCredentials {
    f_name: string;
    l_name: string;
    mobile_number: string;
}

export interface ContactCreateCredentials extends ContactCredentials {
}

export interface ContactUpdateCredentials extends ContactCredentials {
    id: number,
}

export const submit = createAsyncThunk(
    'contacts/post',
    async (credentials: ContactCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

export interface SearchContactCredentials {
    title: string | null,
    mobilenumber: string | null,
}

export const getAll = createAsyncThunk(
    'contacts/getAll',
    async (credentials?: SearchContactCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactRequests.getAllContacts(credentials ?? {} as SearchContactCredentials, store.getState().adminContacts.paginate);
            if (response.status === 200) {
                return {status: response.status, data: [response.data.data, credentials]}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'contacts/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'contacts/update',
    async (credentials: ContactUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ContactRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
