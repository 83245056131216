import CartItem from "./cartItem";


export type cartStatus = 'pending' | 'paid' | 'failed' | 'posted' | 'cancelled' | 'delivered' | 'returned' | 'refunded' | 'settled';
// Define the mapping object with translations for each cart status
export const cartStatusMap: Record<cartStatus, string> = {
    pending: "پرداخت نشده",
    paid: "پرداخت شده",
    cancelled: "لغو شده",
    delivered: "تحویل داده شده",
    returned: "بازگشت داده شده",
    posted: "ارسال شده",
    refunded: "بازپرداخت شده",
    settled: "پرداخت شده",
    failed: "لغو شده"
};
interface CartInterface {
    id: number;
    items?: CartItem[];
    cart_items?: CartItem[];
    status?: cartStatus;
    description?: string;
}

class Cart {

    id: number;
    items?: CartItem[];
    cart_items?: CartItem[];
    status?: cartStatus;
    description?: string;

    constructor(data: CartInterface) {
        this.id = data.id;
        this.items = data.items;
        this.cart_items = data.cart_items;
        this.status = data.status;
        this.description = data.description;
    }
}

export default Cart;