import AddFormQuestionModal from './add';
import FormQuestionsList from "./list";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";


function Index() {


    return (
        <>


            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] p-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>فرم سوال</span>
            </Link>
            {/*end admin using*/}
            <FormQuestionsList></FormQuestionsList>
        </>
    );
}

export default Index;