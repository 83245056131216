import React from 'react';
import {DefaultInput, DefaultSelect} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {update} from "../../../redux/dashboard/contacts/contactsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Contact, {DiscountType} from "../../../models/contact";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";


interface values {
    f_name: string;
    l_name: string;
    mobile_number: string;
}


const EditContactModal: React.FC<{ contact: Contact | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            contact,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, contacts} = useAppSelector((state: RootState) => state.adminContacts);

    //edit
    const handleEditConfirm = async (contact: Contact) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(contact))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
            <DefaultModal
                label={'editContact'} id='editContact' isOpen={!!contact} handleIsOpen={onClose} modalBoxClasses={'px-3'}>
                {contact ? (
                    <Formik
                        initialValues={{

                            f_name: contact.f_name,
                            l_name: contact.l_name,
                            mobile_number: contact.mobile_number,
                        }}
                        onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm({...values, id: contact.id})
                                );

                                (document.querySelector("[for='editContact']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: values) => {
                            const errors: any = {};

                            if (!values.f_name) {
                                errors.f_name = 'نام را وارد کنید';
                            }
                            if (!values.l_name) {
                                errors.l_name = 'نام خانوادگی را وارد کنید';
                            }
                            if (!values.mobile_number) {
                                errors.mobile_number = 'شماره موبایل را وارد کنید';
                            }

                            return errors;
                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editContact'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start items-center md:mt-4  mt-12 w-full flex'>

                                <DefaultInput name='f_name' label='نام' placeholder='نام' type='text'/>
                                <DefaultInput name='l_name' label='نام خانوادگی' placeholder='نام خانوادگی'
                                              type='text'/>
                                <DefaultInput name='mobile_number' label='شماره موبایل' placeholder='شماره موبایل'
                                              type='text'/>
                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editContact"
                                    className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditContactModal;
