import API from "../services/api";
import {AxiosResponse} from "axios";

class NotificationRequests {

    public static async getAllUnreadNotifications(): Promise<AxiosResponse> {
        return await API.authGet("unreadNotifs", {isUseLoading: true});
    }

    public static async getAllNotifications(): Promise<AxiosResponse> {
        return await API.authGet("notifications", {isUseLoading: true});
    }

}



export default NotificationRequests;