import React, {useEffect, useState} from "react"; // Import React for functional components
import Chart from "react-apexcharts";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../redux/store";
import Routes from "../../../utils/routing/routes";
import {IconAlertOctagon, IconArrowRight, IconHome, IconInfoCircle} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {getAllRFM} from "../../../redux/dashboard/RFM/RFMAction";
import RFMChart from "../../../assets/img/63fd3dc3654b3dac7de0adb0_EfoIC2A16A_rYUuX_z9qX4zZt01UBta9wffUyBygyVCc2t2H_e6CXX03h3Eko8odx0A_FTioKIt7d9TM0ZH-8XtxeU7vQb5WS_RqgcU4Scu_eget1D_PryMAOlSFRims24qttiZ7XVfEOZCG5Cn7_Dc.png";
import Plan from "../../../models/plan";
import {BsInfo, BsInfoCircle} from "react-icons/bs";

const ChartRFM = () => {
    // const {RFM} = useSelector((state: RootState) => state.RFM)
    // @ts-ignore

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { RFM, loading } = useSelector((state: RootState) => state.RFM);
    const [startDate, setStartDate] = useState("");

    // const handleSelectChange = (event) => {
    //     const value = event.target.value;
    //     const today = new Date();
    //     let from = today.toISOString().split('T')[0]; // تاریخ امروز
    //
    //     let until = ""; // تاریخ مورد نیاز برای گزینه‌های مختلف
    //
    //     switch (value) {
    //         case "هفته":
    //             // تنظیم تاریخ until به 7 روز پیش از امروز
    //             until = new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0];
    //             break;
    //         case "ماه":
    //             // تنظیم تاریخ until به 30 روز پیش از امروز
    //             until = new Date(today.setDate(today.getDate() - 30)).toISOString().split('T')[0];
    //             break;
    //         case "سال":
    //             // تنظیم تاریخ until به 1 سال پیش از امروز
    //             const oneYearAgo = new Date(today);
    //             oneYearAgo.setFullYear(today.getFullYear() - 1);
    //             until = oneYearAgo.toISOString().split('T')[0];
    //             break;
    //         default:
    //             // اگر گزینه‌ی معتبری انتخاب نشده باشد، اجرای یک منطق پیش‌فرض (اگر لازم است)
    //             break;
    //     }
    //
    //     // اعمال تغییرات در state بر اساس تاریخ‌های جدید
    //     setStartDate(from);
    //
    //     // ارسال درخواست با تاریخ‌های جدید
    //     dispatch(getAllRFM({
    //         from: from,
    //         until: until
    //     }));
    // };

    useEffect(() => {
    //     listen on hover over .rfm-chart .flex.flex-col.justify-start.items-center:hover:before and show a menu with the following items:
        //view users, use in campagn
        // @ts-ignore
        const menu = document.createElement('div');
        menu.className = 'absolute bg-white w-40 h-20 z-50 scale-0 transition-transform innerMenu';
        menu.innerHTML = `
            <ul>
                <li>view users</li>
                <li>use in campagn</li>
            </ul>
        `;
        // @ts-ignore
        const is = document.querySelectorAll('.rfm-chart .flex.flex-col.justify-start.items-center');
        is.forEach((item) => {
            item.appendChild(menu);
            item.addEventListener('mouseover', () => {
                // const innerMenu = item.querySelector('.innerMenu')!;
                // innerMenu.classList.remove('scale-0');
                // innerMenu.classList.add('scale-100');
            });
            item.addEventListener('mouseleave', () => {
                // const innerMenu = item.querySelector('.innerMenu')!;
                // innerMenu.classList.remove('scale-100');
                // innerMenu.classList.add('scale-0');
            });
        });

    }, []);

    const calculatePercentage = (segment: string) => {
        // Calculate total number of users
        const totalUsers = Object.values(RFM).reduce((acc, count) => acc + count, 0);

// Function to calculate the percentage of users in a given segment
            const count = RFM[segment] || 0;
            return ((totalUsers > 0) ? (count / totalUsers) * 100 : 0).toFixed(2);
    }

    return (
        <div className={'flex justify-center flex-col items-center'}>
            {/*<Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] p-3 bg-white w-full md:hidden flex'}>*/}
            {/*    <span><IconArrowRight/></span>*/}
            {/*    <span className={'mr-2'}>RFM</span>*/}
            {/*</Link>*/}
            <div className={'flex border-b-4 py-4 md:w-[500px] w-[350px] items-start border-b-gray-100'}>
                <div className={'flex items-start mt-6'}>
                    <div className="flex items-center gap-2 my-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             className="stroke-info shrink-0 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span>
                        تحلیل RFM یک روش برای دسته‌بندی مشتریان بر اساس سه عامل زمان آخرین خرید (Recency)، تکرار خرید
                        (Frequency) و ارزش خرید (Monetary) است. این تحلیل به کسب‌وکارها کمک می‌کند تا مشتریان خود را
                        بهتر بشناسند و استراتژی‌های بازاریابی خود را بر اساس نیازهای آنها تنظیم کنند.

                    </span>
                    </div>
                </div>
            </div>
            <div className={'rfm-chart items-center justify-center flex flex-col mt-6'}>
                {/*<select className={'bg-transparent'} onChange={handleSelectChange}>
                    <option value="هفته">تاریخ</option>
                    <option value="هفته">هفته</option>
                    <option value="ماه">ماه</option>
                    <option value="سال">سال</option>
                </select>*/}
                {
                    // @ts-ignore
                    loading || RFM === undefined ? <div></div> :
                        <div className={'flex mt-2 md:w-[500px] w-[350px] md:h-[500px] h-[350px]'}>
                            <div className={'flex flex-col'}>
                                <div className={'flex'}>
                                    <div className={'md:w-[100px] hover:scale-[102] w-[70px] md:h-[200px] h-[140px] bg-blue-200 flex flex-col justify-start items-center'}>
                                        {/*@ts-ignore*/}
                                        <span>{calculatePercentage(RFM.Champion)}%</span>
                                        {/*@ts-ignore*/}
                                        <span>{RFM.Champion}</span>
                                    </div>
                                    <div className={'md:w-[200px] hover:scale-[102] w-[140px] md:h-[200px] h-[140px] bg-blue-400 flex flex-col justify-start items-center'}>
                                        {/*@ts-ignore*/}
                                        <span>{calculatePercentage('Loyal')}%</span>
                                        {/*@ts-ignore*/}
                                        <span>{RFM['Loyal']} </span>
                                    </div>
                                </div>
                                <div className={'flex'}>
                                    <div className="flex flex-col">
                                        <div className={'md:w-[200px] hover:scale-[102] w-[140px] md:h-[200px] h-[140px] bg-green-400 flex flex-col justify-start items-center'}>
                                            {/*@ts-ignore*/}
                                            <span>{calculatePercentage('Potential Loyalist')}%</span>
                                            {/*@ts-ignore*/}
                                            <span>{RFM['Potential Loyalist']}</span>
                                        </div>
                                        <div className={'flex'}>
                                            <div className={'md:w-[100px] hover:scale-[102] w-[70px] md:h-[100px] h-[70px] bg-green-200 flex flex-col justify-start items-center'}>
                                                {/*@ts-ignore*/}
                                                <span>{calculatePercentage('new Customer')}%</span>
                                                {/*@ts-ignore*/}
                                                <span>{RFM['new Customer']}</span>
                                            </div>
                                            <div className={'md:w-[100px] hover:scale-[102] w-[70px] md:h-[100px] h-[70px] bg-amber-400 flex flex-col justify-start items-center'}>
                                                {/*@ts-ignore*/}
                                                <span>{calculatePercentage('Promising')}%</span>
                                                {/*@ts-ignore*/}
                                                <span>{RFM['Promising']}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className={'md:w-[100px] hover:scale-[102] w-[70px] md:h-[100px] h-[70px] bg-[#DE0046] flex flex-col justify-start items-center'}>
                                            {/*@ts-ignore*/}
                                            <span>{calculatePercentage('About Sleep')}%</span>
                                            {/*@ts-ignore*/}
                                            <span>{RFM['About Sleep']}</span>
                                        </div>
                                        <div className={'md:w-[100px] hover:scale-[102] w-[70px] md:h-[200px] h-[140px] bg-orange-400 flex flex-col justify-start items-center'}>
                                            {/*@ts-ignore*/}
                                            <span>{calculatePercentage('Needs Attention')}%</span>
                                            {/*@ts-ignore*/}
                                            <span>{RFM['Needs Attention']}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'flex flex-col'}>
                                <div className={'md:w-[200px] hover:scale-[102] w-[140px] md:h-[100px] h-[70px] bg-amber-300 flex flex-col justify-start items-center'}>
                                    {/*@ts-ignore*/}
                                    <span>{calculatePercentage('Cant Lose Them')}%</span>
                                    {/*@ts-ignore*/}
                                    <span>{RFM['Cant Lose Them']}</span>
                                </div>
                                <div className={'md:w-[200px] hover:scale-[102] w-[140px] md:h-[200px] h-[140px] bg-pink-300 flex flex-col justify-start items-center'}>
                                    {/*@ts-ignore*/}
                                    <span>{calculatePercentage('at Risk')}%</span>
                                    {/*@ts-ignore*/}
                                    <span>{RFM['at Risk']}</span>
                                </div>
                                <div className={'md:w-[200px] hover:scale-[102] w-[140px] md:h-[200px] h-[140px] bg-purple-300 flex flex-col justify-start items-center'}>
                                    {/*@ts-ignore*/}
                                    <span>{calculatePercentage(RFM.Hibernating)}%</span>
                                    {/*@ts-ignore*/}
                                    <span>{RFM.Hibernating}</span>
                                </div>
                            </div>


                        </div>
                }
                {
                    loading || RFM === undefined ? <div></div> :
                        <div className={'md:w-[500px] mt-6 w-[350px] md:h-[500px] h-[350px] flex flex-col'}>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-blue-200 ml-2'}></div>
                                    قهرمان
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM.Champion}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-blue-400 ml-2'}></div>
                                    وفادار
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['Loyal']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-green-400 ml-2'}></div>
                                    وفادار بالقوه
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['Potential Loyalist']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-green-200 ml-2'}></div>
                                    مشتری جدید
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['new Customer']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-amber-400 ml-2'}></div>
                                    امیدوار کننده
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['Promising']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-[#DE0046] ml-2'}></div>
                                    غیرفعال شده
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['About Sleep']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-orange-400 ml-2'}></div>
                                    نیاز به توجه دارد
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['Needs Attention']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-amber-300 ml-2'}></div>
                                    نمی توان آنها را از دست داد
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['Cannot Lose Them']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-pink-300 ml-2'}></div>
                                    در خطر
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM['at Risk']}</div>
                            </div>
                            <div className={'flex justify-between mt-2'}>
                                <div className={'flex'}>
                                    <div className={'w-5 h-5 bg-purple-300 ml-2'}></div>
                                    خواب زمستانی
                                </div>
                                {/*@ts-ignore*/}
                                <div>{RFM.Hibernating}</div>
                            </div>
                        </div>
                }
            </div>

        </div>
    );
};

export default ChartRFM;


