import React from 'react';
import {DefaultBooleanField, DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {QuestionCreateCredentials, submit} from "../../../redux/dashboard/questions/questionsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Question from "../../../models/question";
import FormQuestion from "../../../models/formQuestion";
import {useParams} from "react-router-dom";
import {IconArrowRight} from "@tabler/icons-react";


const AddQuestionModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.questions);
    const params = useParams();

    return (
        <DefaultModal
            label={'addQuestion'}  id='addQuestion' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    title: '',
                    question_type: 'text',
                    form_question_id: params['id'] ?? '',
                }}
                onSubmit={
                    (values: QuestionCreateCredentials, {resetForm, setSubmitting}: FormikHelpers<QuestionCreateCredentials>) => {
                        
                        try {
                            dispatch(submit(values));

                            (document.querySelector("[for='addQuestion']") as HTMLElement).click();

                            resetForm();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: QuestionCreateCredentials) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'عنوان فرم را وارد کنید.';
                    }
                    return errors;
                }}>
                <Form method="dialog">
                    <label htmlFor={'addQuestion'}
                           className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                    <div className='flex-col justify-start md:mt-4  mt-12 items-center w-full flex'>

                        <DefaultInput
                            label='عنوان سوال'
                            name="title"
                            type="text"
                            placeholder='عنوان سوال'
                        ></DefaultInput>

                        <DefaultSelect name="type" options={[
                            {
                                label: 'متن',
                                value: "text"
                            },
                            {
                                label: 'عدد',
                                value: "number"
                            },
                            {
                                label: 'تاریخ',
                                value: "date"
                            },
                            {
                                label: 'متن طولانی',
                                value: "textarea"
                            },
                            {
                                label: 'ایمیل',
                                value: "email"
                            },
                            {
                                label: 'بله یا خیر',
                                value: "boolean"
                            },
                        ]}
                                       label="نوع فیلد"
                        />

                    </div>
                    <div className="modal-action w-full flex justify-start">

                        <button type="submit"
                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                submitLoading ? 'صبر کنید' : 'افزودن '
                            }
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addQuestion"
                            className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddQuestionModal;
