import {Link, useLocation} from "react-router-dom";
import React, {useState} from 'react';
import {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import {IconArrowRight} from "@tabler/icons-react";
import Routes from "../../../utils/routing/routes";
import moment from "jalali-moment";


const UTMsList = () => {
    const {lTV, loading} = useAppSelector((state: RootState) => state.utms);
    return (
        <>
            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>تعرفه ها</span>
            </Link>
            {
                loading ?
                        <div className={'flex justify-center items-center w-full'}>
                        </div>
                :
                    <div className={'px-4 w-full'}>

                        <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                            <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام و نام خانوادگی</th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                    شماره موبایل
                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">مارکتر</th>
                            </tr>
                            </thead>
                            {
                                loading ? <div></div> :
                                    lTV.map((e, i) => (


                                        <tbody>
                                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400">{i + 1}</td>
                                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.full_name}</td>
                                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.mobile_number}</td>
                                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.marketer}</td>
                                            </tbody>
                                    ))}
                        </table>
                </div>
            }
        </>
    );
};

export default UTMsList;