import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LaravelValidationErrorType } from "../../../utils/types";
import {changePaymentStatus, getOrderCart} from "./orderAction";
import Payment from "../../../models/payment";
import { DefaultStates } from "../../mainSlice";
import Checkout from "../../../models/checkout";
import {toastSuccess} from "../../../utils/funcions";

interface State extends DefaultStates {
    order: Payment[];
    ordersList: Payment[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    loading: false,
    submitLoading: false,
    validationErrors: null,
    paginate: 1,
    isEnd: false,
    order: [],
    ordersList: [],
    loadingMore: false,
};

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1; // Update loadingMore state
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.paginate = 1;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getOrderCart.pending, (state) => {
                if (state.paginate === 1) {
                    state.loading = true;
                }else {
                    state.loadingMore = true;
                }
            }).addCase(getOrderCart.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Payment[] = [];
                const title = response.data[1]
                for (let i = 0; i < response.data[0].data.length; i++) {
                    const contact = response.data[0].data[i];
                    contacts.push(new Payment({
                        id: contact.id,
                        status: contact.status,
                        description: contact.description,
                        final_price: contact.final_price,
                        full_name: contact.full_name,
                        mobile_number: contact.mobile_number,
                        result_code: contact.result_code,
                        success_url: contact.success_url,
                        total_price: contact.total_price,
                        user_ip: contact.user_ip,
                        paymentable: contact.paymentable,
                        created_at: contact.created_at,
                        tracking_code: contact.tracking_code,
                        user: contact.user,
                        address: contact.address,
                        user_status: contact.user_status
                    }));
                }
                if (response.data[0].length === 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1 ) {
                    state.ordersList.push(contacts);
                }
                else if (title && (title.title || title.price || title.product || title.category)) {
                    state.ordersList = [contacts];
                } else if (state.paginate === 1) {
                    state.ordersList = [contacts];
                } else {
                    state.ordersList.push(contacts);
                }

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }

        })
            .addCase(getOrderCart.rejected, (state, action) => {
                state.loading = false;
                state.loadingMore = false;
            })
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true;
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });


        builder.addCase(changePaymentStatus.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(changePaymentStatus.fulfilled, (state, action: PayloadAction<any>) => {
            state.submitLoading = false;
            const response = action.payload;

            if (response.status === 200) {
                const hello = state.order

                    state.ordersList.forEach((contactList) => {
                        const index = contactList.findIndex(contact => contact.id === response.data.data.id);
                        contactList[index] = new Payment(response.data.data);
                    })

                toastSuccess('وضعیت با موفقیت ویرایش شد')
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })

        builder.addCase(changePaymentStatus.rejected, (state) => {
            state.submitLoading = false
        })
    },
});

export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = orderSlice.actions;

export default orderSlice.reducer;
