import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ContactRequests from "../../../requests/contactRequests";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import AdvancedCategoryRequests from "../../../requests/advancedCategoryRequests";

export const getAll = createAsyncThunk(
    'advancedCategories/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await AdvancedCategoryRequests.getAllAdvancedCategory()
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove