import Category from "./category";
import Product from "./product";
import User from "./user";

export enum DiscountType {
    PERCENT = "percent",
    FIXED = "fixed",
}

interface AdvancedCategoryInterface {
    lowValue: lowValue[];
    midValue: [];
    highValue: [];
}

interface LowValuesInterface {
    average_revenue: string;
    user_id: number;
    user: User;
}
interface MidValuesInterface {
    average_revenue: string;
    user_id: number;
    user: User;
}
interface HighValuesInterface {
    average_revenue: string;
    user_id: number;
    user: User;
}

class AdvancedCategory {

    lowValue: lowValue[];
    midValue: midValue[];
    highValue: highValue[];
    constructor(data: AdvancedCategoryInterface) {
        this.lowValue = data.lowValue;
        this.midValue = data.midValue;
        this.highValue = data.highValue;
    }

}
class lowValue {

    average_revenue: string;
    user_id: number;
    user: User;
    constructor(data: LowValuesInterface) {
        this.average_revenue = data.average_revenue;
        this.user = data.user;
        this.user_id = data.user_id;
    }
}
class midValue {

    average_revenue: string;
    user: User;
    user_id: number;
    constructor(data: MidValuesInterface) {
        this.average_revenue = data.average_revenue;
        this.user = data.user;
        this.user_id = data.user_id;
    }
}
class highValue {

    average_revenue: string;
    user: User;
    user_id: number;
    constructor(data: HighValuesInterface) {
        this.average_revenue = data.average_revenue;
        this.user = data.user;
        this.user_id = data.user_id;
    }
}

export default AdvancedCategory;