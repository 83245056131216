import React, { ReactNode } from 'react';

interface FilterProps {
    children: ReactNode;
    id: string;
    isOpen?: boolean;
    handleIsOpen?: (isOpen: boolean) => void;
    modalBoxClasses?: string;
}

const Filter: React.FC<FilterProps> = ({ children, id, isOpen, handleIsOpen, modalBoxClasses }) => {
    return (
        <div className={'w-full flex justify-end duration-300'} style={{ overflowY: 'auto' }}>
            <div onClick={() => handleIsOpen && handleIsOpen(!isOpen)} className="btn mt-2 flex cursor-pointer bg-[#4192EF] border-none mr-2 w-max p-2 rounded-xl text-white">
                <span className="text-[15px]">فیلتر ها</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 5l0 14" />
                    <path d="M5 12l14 0" />
                </svg>
            </div>
            <div className={`absolute  md:mt-16 mt-24 left-4 duration-300  w-full ${modalBoxClasses}`}>
                {isOpen ? "" : children}
            </div>
        </div>
    );
};

export default Filter;
