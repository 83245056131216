import React, {ReactNode, useState} from 'react';
import {Formik, FormikHelpers} from "formik";
import {DefaultInput, DefaultTextArea} from "./fieilds";
import {IconBoxMultiple, IconMultiplier1x, IconX} from "@tabler/icons-react";

const DefaultModal: React.FC<{ children: ReactNode, id: string, label?: string, isOpen?: boolean, handleIsOpen?: (isOpen: boolean) => void, modalBoxClasses?: string}> = ({children, id, label, isOpen, handleIsOpen, modalBoxClasses}) => {
    return (
        <>
            <input
                checked={isOpen}
                onChange={(e) => handleIsOpen && handleIsOpen(e.target.checked)}
                type="checkbox" id={id} className="modal-toggle"/>
            <dialog className="md:modal z-[42] modal md:bg-[#0000004d] bg-white shadow-none overflow-x-hidden" style={{ overflowY: 'auto' }}>
                <div className={`modal-box md:mt-8 md:h-max h-full max-h-full md:w-[85%] w-full md:mb-0 md:px-4 px-1 rounded-xl shadow-none ${modalBoxClasses}`}>
                    {children}
                </div>
                <label
                    className="modal-backdrop" htmlFor={id}></label>
            </dialog>
        </>
    );
};


export const DeleteModal: React.FC<{ title: string, message: string, onSubmit: () => void, handleIsOpen: (isOpen: boolean) => void, isOpen: boolean }> = ({ title, message, onSubmit, isOpen, handleIsOpen}) => {

    const id: string = Math.random().toString(36).substr(2, 9);

    return (
        <DefaultModal id={id} isOpen={isOpen} handleIsOpen={handleIsOpen}>
            <div className="modal-header">
                <div className="flex flex-1">
                    <h2 className="text-lg font-bold text-[#DE0046]">{title}</h2>
                </div>
            </div>
            <div className="modal-bod">
                <p>{message}</p>
            </div>
            <div className="modal-footer mt-8">
                <button className="btn btn-error text-white" onClick={onSubmit}>بله</button>
                <label
                    htmlFor={id}
                    className="btn btn-ghost" >خیر</label>
            </div>
        </DefaultModal>
    );
}

export default DefaultModal;
