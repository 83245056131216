import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from 'react';
import store, {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import Product from "../../../models/product";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {
    IconArrowRight,
    IconChevronDown,
    IconFilter,
    IconSearch,
    IconX
} from "@tabler/icons-react";
import Routes from "../../../utils/routing/routes";
import {moneyFormat, pagination} from "../../../utils/funcions";
import {getAllBuyed} from "../../../redux/dashboard/plans/buyedPlansAction";
import {setPaginate, setPaginateToStart, setAllStatesToTrue} from "../../../redux/dashboard/plans/buyedPlansSlice";
import {getAll} from "../../../redux/dashboard/discountCodes/discountCodesAction";


const BuyedPlansList = () => {
    const {buyedPlansList, loading} = useAppSelector((state: RootState) => state.buyedPlans);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };


    // useEffect(() => {
    //         pagination(() => {
    //             if (!store.getState().buyedPlans.loading && !store.getState().buyedPlans.loadingMore && !store.getState().buyedPlans.isEnd) {
    //                 dispatch(setPaginate());
    //                 dispatch(getAllBuyed(null));
    //             }
    //         })
    //     },
    // );
    // useEffect(() => {
    //     dispatch(setPaginateToStart())
    // }, []);

    const logo = require("../../../assets/img/No data-pana-min.png");
    const [fullname, setFullname] = useState<string>('');
    const [mobilenumber, setMobileNumber] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());

    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());

        if (currentTime - lastSearchTimeRef.current >= 2000) { // 2 seconds
            const data = { fullname, mobilenumber, minPrice, maxPrice };

            try {
                dispatch(getAllBuyed(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime;
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

    const handleDeleteSearch = () => {
        setFullname("");
    };

    const handleDeleteMinPrice = () => {
        setMinPrice("");
    };


    const handleDeleteMaxPrice = () => {
        setMaxPrice("");
    };

    const handleDeleteSearchByMobileNumber = () => {
        setMobileNumber("");
    };

    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');

    const adminProductsState = store.getState().buyedPlans;
    useEffect(() => {
        const data = { fullname, mobilenumber, minPrice, maxPrice };

        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());
        const handlePagination = () => {
            if (!store.getState().buyedPlans.loading &&
                !adminProductsState.loadingMore &&
                !store.getState().buyedPlans.isEnd) {
                dispatch(setPaginate());
                dispatch(getAllBuyed(data));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [fullname, mobilenumber, minPrice, maxPrice]);

    useEffect(() => {
        setPaginateToStart();
    }, []);

    return (
        <div className={' lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-4'}>
            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>خرید ها</span>
            </Link>
            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-2'}>تعرفه های خریداری شده</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full'}>
                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-32 h-64'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس نام"
                                value={fullname}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setFullname(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {fullname ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center w-full px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="text"
                                placeholder="کمترین قیمت"
                                value={minPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMinPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {minPrice ? (
                                    <button
                                        onClick={handleDeleteMinPrice}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX/>
                                    </button>) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>

                            )}
                        </div>
                    </label>

                    <label className="input input-bordered flex items-center w-full px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="بیشترین قیمت"
                                value={maxPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMaxPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />

                            {maxPrice ? (
                                <button
                                    onClick={handleDeleteMaxPrice}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>

                            )}

                        </div>
                    </label>
                    <label className="input input-bordered flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس شماره موبایل"
                                value={mobilenumber}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {mobilenumber ? (
                                <button
                                    onClick={handleDeleteSearchByMobileNumber}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6  duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>


            <div className={'overflow-x-auto'}>
                <table
                    className="items-center w-full mt-[36px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        {/*<th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>*/}
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                            و نام خانوادگی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            شماره موبایل
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            قیمت خرید
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            تعرفه خریداری شده
                        </th>
                    </tr>
                    </thead>

                    {
                         <tbody className={''}>
                        {
                            buyedPlansList.map(buyedPlans => buyedPlans.map((e, i) =>
                                <tr key={e.id}
                                    className={`transition-all  mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"}`}>
                                    {/*<td className="px-6 py-4 pl-2 font-bold text-start align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{i + 1}</td>*/}
                                    <td className="px-6 py-[28px] pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.full_name}</td>
                                    <td className="px-6 py-5 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.mobile_number}</td>
                                    <td className="px-6 py-5 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{moneyFormat(e.final_price)} ریال</td>
                                    <td className="px-6 py-5 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80 bg-green-500">
                                        <Link to={Routes.plans.path}
                                              className={'py-2.5 px-2 duration-300 rounded-lg hover:bg-[#4192EF] bg-[#4192EF33] hover:text-white text-[#4192EF]'}>{e.description}</Link>
                                    </td>

                                </tr>
                            ))
                        }
                        </tbody>
                    }

                </table>
            </div>
            {
                buyedPlansList.every(mainUsers => mainUsers.length === 0) && !loading ?
                    <div className={'w-full flex flex-col justify-center items-center'}>
                        <img src={logo} alt="" className={'w-[30%]'}/>
                        <span className={'text-lg'}>تعرفه خریداری شده ای وجود ندارد</span>
                    </div> : <div></div>

            }

        </div>
    );
};

export default BuyedPlansList;