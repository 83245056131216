import {useDispatch} from "react-redux";
import {moneyFormat, NetworkImage} from "../../../utils/funcions";
import React from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../../redux/mainSlice";
import {paymentUserStatusMap} from "../../../models/payment";
import {
    IconArrowRight,
    IconBoxMultiple,
    IconCheck,
    IconCheckbox,
    IconCubeSend,
    IconLoader3,
    IconXboxX
} from "@tabler/icons-react";
import {changeAPaymentStatus} from "../../../redux/dashboard/orderPage/orderPageAction";
export const Order: React.FC  = () => {

    const {order, loading, submitLoading, } = useAppSelector((state: RootState) => state.orderPage);
    const {shop, user, settings} = useAppSelector((state: RootState) => state.user);

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    interface values {
        statuses: string;
        id: any;
    }
    const handleEditConfirm = async (values: values) => {
        const result: DefaultResponse = (await dispatch(changeAPaymentStatus(values))).payload as DefaultResponse;
        // return result;
    }

    // console.log('order!.user_status', order!.user_status)
    return (
        <div className={'lg:pl-28 md:pl-20 pl-2'}>

            <Link to={Routes.orders.path}
                  className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>سفارش</span>
            </Link>

            <div
                className="py-4 pb-0 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                    <span className={'flex mt-2.5 items-center md:px-0 px-5'}><span className={'mx-1 text-[14px] text-[#808080]'}>  سفارش   </span> {order?.full_name}  </span>

            </div>

            {
                loading || order == null ? (
                    <div className="w-full flex justify-center mt-1">
                        <div></div>
                    </div>
                ) : (

                    <div className={'w-full justify-between flex flex-col-reverse md:flex-row'}>
                        <div className={'md:w-2/3 w-full md:mt-0 mt-10 md:px-0 px-2'}>
                            <div className={'grid md:w-[97%] w-full md:grid-cols-2 grid-cols-1 justify-between gap-x-2 '}>
                                {
                                    order.paymentable?.cart_items?.map((e) => <div

                                        className='flex mx-2 mt-2 p-2 border w-full duration-300 cursor-pointer border-[#e7e7e7] rounded-xl bg-white product-hover'
                                    >
                                        <NetworkImage
                                            url={e.product.media[0].thumbnail}
                                            className='lg:w-24 lg:h-24 rounded-xl w-16 h-16'
                                            alt=''
                                        />
                                        <div className='flex flex-col mr-2 items-start justify-between'>
                                            <div className='flex flex-col items-start'>
                                                <span className='text-md font-semibold'>{e.product.title}</span>
                                                <span>قیمت : {moneyFormat(e.product.price)}</span>
                                            </div>
                                            <span>تعداد : {e.quantity}</span>
                                        </div>
                                    </div>)
                                }

                            </div>
                        </div>
                        <div className={'md:w-1/3 w-full md:px-0 px-2 md:mr-0 mr-2'}>
                            <div
                                className={'w-full border mt-2 flex flex-col h-max border-[#E7E7E7] rounded-lg px-3 py-6'}>

                                <div className={'flex flex-col mt-3'}>
                                    <span className={'text-[#808080] text-[13px]'}>نام و نام خانوادگی</span>
                                    <span className={'mr-1.5'}>{order.full_name}</span>
                                </div>
                                <div className={'flex flex-col mt-4'}>
                                    <span className={'text-[#808080] text-[13px]'}>شماره موبایل</span>
                                    <span className={'mr-1.5'}>{order!.mobile_number}</span>
                                </div>
                                <div className={'flex flex-col mt-4 pl-2'}>
                                    <div className={'w-full justify-between flex '}>

                                        <span className={'text-[#808080] text-[13px]'}>وضعیت ارسال</span>
                                        <span
                                            className={'text-[#808080] text-[13px]'}>{paymentUserStatusMap[order!.user_status]}</span>
                                    </div>
                                    <div className={'flex w-full mt-3'}>
                                        <div className={'flex flex-col w-full'}>

                                            {
                                                user!.id === shop!.id ?
                                                    <div className={'flex justify-between w-full pl-6 pr-4'}>
                                                        <div className="tooltip">
                                                            <div
                                                                onClick={() => handleEditConfirm({
                                                                    statuses: 'failed',
                                                                    id: order.id
                                                                })}
                                                                className="w-2 h-2 rounded-full bg-[#DE0046] cursor-pointer"
                                                            ></div>
                                                            <div className="tooltip-text text-[10px]">تغییر به : لغو
                                                                شده
                                                            </div>
                                                        </div>

                                                        <div className="tooltip">
                                                            <div
                                                                onClick={() => handleEditConfirm({
                                                                    statuses: 'pending',
                                                                    id: order.id
                                                                })}
                                                                className="w-2 h-2 rounded-full bg-purple-500 cursor-pointer"
                                                            ></div>
                                                            <div className="tooltip-text text-[10px]">تغییر به : درحال
                                                                بررسی
                                                            </div>
                                                        </div>

                                                        <div className="tooltip">
                                                            <div
                                                                onClick={() => handleEditConfirm({
                                                                    statuses: 'posted',
                                                                    id: order.id
                                                                })}
                                                                className="w-2 h-2 rounded-full bg-[#4192EF] cursor-pointer"
                                                            ></div>
                                                            <div className="tooltip-text text-[10px]">تغییر به : ارسال
                                                                شده
                                                            </div>
                                                        </div>

                                                        <div className="tooltip">
                                                            <div
                                                                onClick={() => handleEditConfirm({
                                                                    statuses: 'delivered',
                                                                    id: order.id
                                                                })}
                                                                className="w-2 h-2 rounded-full bg-[#00DE00] cursor-pointer"
                                                            ></div>
                                                            <div className="tooltip-text text-[10px]">تغییر به : تحویل
                                                                گرفته
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div></div>
                                            }
                                            <div className={'flex'}>
                                                <span
                                                    className={'mr-1.5 ml-1 mt-2 w-full bg-[#E7E7E7] h-1.5 rounded-full'}>
                                        <div
                                            className={`duration-300 ${
                                                order!.user_status === 'pending'
                                                    ? 'w-[33%] bg-purple-500 h-1.5 rounded-full'
                                                    : order!.user_status === 'posted'
                                                        ? 'w-[66%] bg-[#4192EF] h-1.5 rounded-full'
                                                        : order!.user_status === 'delivered'
                                                            ? 'w-[100%] bg-[#00FF00] h-1.5 rounded-full'
                                                            : 'w-[100%] bg-[#DE0046] h-1.5 rounded-full'
                                            }`}
                                        ></div>
                                </span>
                                                <div>
                                                    {order!.user_status === 'delivered' ?
                                                        <IconCheckbox className={'mt-[1px] text-[#00FF00]'}
                                                                      size={20}/> : order!.user_status === 'pending' ?
                                                            <IconLoader3 className={'mt-[1px] text-purple-500'}
                                                                         size={20}/> : order!.user_status === 'posted' ?
                                                                <IconCubeSend className={'text-[#4192EF]'} size={24}/> :
                                                                <IconXboxX className={'mt-[1px] text-[#ED0046]'}
                                                                           size={20}/>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={'flex flex-col mt-2'}>
                                    <span className={'text-[#808080] text-[13px]'}>آدرس</span>
                                    <span className={'mr-1.5 text-[15px]'}>{order.description}</span>
                                </div>
                                <div className={'flex flex-col mt-2 mb-3'}>
                                    <span className={'text-[#808080] text-[13px]'}>توضیحات</span>
                                    <span
                                        className={'mr-1.5 text-[15px]'}>{order.address ? order.address : 'توضیحاتی ندارد'}</span>
                                </div>

                                <hr/>

                                <div>

                                <div className={'flex flex-col mt-3'}>
                                        <span className={'text-[#808080] text-[13px]'}>جمع سبد خرید(ریال)</span>
                                        <span className={'mr-1.5'}>{moneyFormat(order.final_price)}</span>
                                    </div>
                                    <div className={'flex flex-col mt-3 '}>
                                        <span className={'text-[#808080] text-[13px]'}>کد پیگیری سفارش</span>
                                        <span className={'mr-1.5'}>{order.tracking_code}</span>
                                    </div>
                                    <div className={'flex flex-col mt-3'}>
                                        <span className={'text-[#808080] text-[13px]'}>هزینه ارسال(ریال)</span>
                                        <span className={'mr-1.5'}>{settings.find(e => e.name === 'shipping_cost')?.value}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                )
            }

        </div>
    )
        ;
}


