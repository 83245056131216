import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import Category from "../../../models/category";
import CategoryRequests from "../../../requests/categoryRequests";
import {parseNumber} from "react-advanced-cropper";

export const submit = createAsyncThunk(
    'categories/post',
    async (credentials: {title: string, parentId?: number}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await CategoryRequests.create(credentials)
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
// ریکوئست میزنیم
export const getAll = createAsyncThunk(
    'categories/getAll',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await CategoryRequests.getAllCategories()

            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'categories/remove',
    async (id: number): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await CategoryRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'categories/update',
    async (credentials: {id: number, title: string, parentId?: number}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await CategoryRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: credentials}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
