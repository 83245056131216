import API from "../services/api";
import {AxiosResponse} from "axios";
import {MediaType} from "../models/media";
import {checkUser, convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {
    ProductCreateCredentials,
    ProductUpdateCredentials,
    SearchProductsCredentials
} from "../redux/dashboard/products/productsAction";

class ProductRequests {

    public static async getAllProducts(i: number, isReturnCache = false, credentials: SearchProductsCredentials): Promise<AxiosResponse> {
        const queryParams: string[] = [];
        console.log('productswww', credentials)
        if (credentials.title) {
            queryParams.push(`title=${encodeURIComponent(credentials.title)}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPrice=${encodeURIComponent(credentials.minPrice)}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPrice=${encodeURIComponent(credentials.maxPrice)}`);
        }
        if (credentials.status) {
            queryParams.push(`status=${encodeURIComponent(credentials.status)}`);
        }

        // همیشه شامل پارامتر صفحه
        queryParams.push(`page=${i}`);

        // تبدیل آرایه به رشته
        const queryString = queryParams.join('&');
        const url = `products?${queryString}`;

        // ارسال درخواست به API
        return await API.authGet(url, {
            isUseLoading: true,
            isUseCache: !i || i === 1,
            isReturnCache
        });
    }


    public static async getProductById(id: number): Promise<AxiosResponse> {
        return await API.get("products/" + id, {isUseLoading: true});
    }

    public static async getInventoryProduct(): Promise<AxiosResponse> {
        return await API.authGet("inventoryProducts");
    }

    static async getProduct(id: number): Promise<AxiosResponse> {
        return await API.authGet(`products/${id}`, {isUseLoading: true});
    }

    static async create(credentials: ProductCreateCredentials, onUploadProgress?: (progressEvent: number) => void): Promise<AxiosResponse> {
        console.log('credentials', credentials)
            return await API.authPost('products', {
                data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
                isUseLoading: true,
                onProgress: onUploadProgress,
            });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`products/${id}`, {isUseLoading: true});
    }

    static async update(credentials: ProductUpdateCredentials, onUploadProgress?: (progressEvent: number) => void): Promise<AxiosResponse> {
        return await API.authPut(`products/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default ProductRequests;