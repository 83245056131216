import React, {useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import prof from "../../../assets/img/خرید-هودی-نارنجی-6.jpg";
import ChatForm from "./index";
import {IconArrowRight, IconChevronDown, IconMoodEmpty} from "@tabler/icons-react";
import {FaRegFaceSadCry} from "react-icons/fa6";
import {AiOutlineMessage} from "react-icons/ai";
import {NetworkImage} from "../../../utils/funcions";



function Users() {

    const { user, shop } = useAppSelector((state: RootState) => state.user);
    const { users, loading} = useAppSelector((state: RootState) => state.users);

    // @ts-ignore
    return (
        <div className={'flex justify-center'}>
            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 z-[9999] py-4 px-4 bg-white w-full overflow-y-auto md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>لیست پیام ها</span>
            </Link>
            {
                user!.id == shop!.id ? <div className={`h-full w-full md:px-8 px-4 mt-12 md:mr-64 fixed overflow-y-auto`}>

                    <div
                        className="py-4 md:flex hidden pb-0 mb-2 mt-3 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                        <div className={'flex w-full justify-between'}>
                            <span className={'flex items-center md:px-0 px-4'}>لیست پیام ها</span>

                        </div>

                    </div>
                    {
                        users.map((e) => e.message_id == null) ? '' : <div className={'md:hidden flex items-start'}>
                            <span>کاربران</span>
                        </div>
                    }
                    <div className={'h-16'}>

                    </div>
                    {/*isnt lovley*/}
                    {
                        // @ts-ignore
                        loading ? <LoadingTableShimmer/> : users.length == 0 ?
                            <div className="flex flex-col items-center justify-center md:ml-64 h-screen">
                                <AiOutlineMessage size={50} className="text-6xl text-gray-500 mb-4"/>
                                <p className="text-2xl text-gray-700">پیامی وجود ندارد</p>
                            </div> : users.map((e) => e.message_id == null ? "" :
                                <Link
                                    to={Routes.chat(parseInt(e!.message_id.toString())).path}
                                    className={'py-2 px-4 flex hover:bg-[#4192EF33] rounded-xl w-full md:max-w-lg md:mt-2 mt-8 items-start'}>
                                    {
                                        <NetworkImage url={e.sender && e.sender.avatar ? e.sender.avatar : shop!.avatar}
                                                      className="w-[40px] h-[40px] rounded-full"
                                            // src={e.sender && e.sender.avatar ? e.sender.avatar : shop!.avatar}
                                                      alt={""}/>
                                    }

                                    <div className={'flex flex-col justify-start mr-2'}>
                                                <span
                                                    className={'text-[14px] '}>{e!.sender == null ? "کاربر ناشناس" : e!.sender!.f_name}</span>
                                        <span
                                            className={'text-[14px] '}>{e!.sender == null ? "شماره تماس: " : e!.sender!.mobile_number}</span>
                                        {/*<span*/}
                                        {/*    className={'text-[12px] mt-1 w-28'}></span>*/}
                                    </div>
                                </Link>
                            )
                    }
                    <div className={'h-20 w-2'}></div>

                </div> : ''
            }

            <ChatForm></ChatForm>
        </div>
    );
}

export default Users;