import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import ContactAndCustomers from "../../../models/contactCustomer";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/contactsAndCustomers/contactsAndCustomersAction";

interface State extends DefaultStates {
    contactsAndCustomers: ContactAndCustomers[];
    submitLoading: boolean;
}

const initialState: State = {
    contactsAndCustomers: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const ContactsAndCustomersSlice = createSlice({
    name: 'contactsAndCustomers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.contactsAndCustomers.unshift(new ContactAndCustomers({...data, id: 0}));
                toastSuccess('کمپین با موفقیت افزودن شد.');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: ContactAndCustomers[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    contacts.push(new ContactAndCustomers({
                        id: contact.id,
                        f_name: contact.f_name,
                        l_name: contact.l_name,
                        mobile: contact.mobile,
                        mobile_number: contact.mobile_number,
                    }));
                }
                state.contactsAndCustomers = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.contactsAndCustomers.findIndex(contact => contact.id === response.data);
                state.contactsAndCustomers.splice(index, 1);
                toastSuccess('مخاطب با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                    const index = state.contactsAndCustomers.findIndex(contact => contact.id === response.data.id);
                    state.contactsAndCustomers[index] = new ContactAndCustomers({
                        id: response.data.id,
                        f_name: response.data.f_name,
                        l_name: response.data.l_name,
                        mobile: response.data.mobile,
                        mobile_number: response.data.mobile_number,
                    });
                    toastSuccess('مخاطب با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export default ContactsAndCustomersSlice.reducer;