import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import LTVRequests from "../../../requests/lTVRequests";
import QuestionAnswer from "../../../models/questionAnswer";
import QuestionAnswersRequests from "../../../requests/questionAnswersRequests";



export const getFormQuestionUserAnswer = createAsyncThunk(
    'questionsUserAnswers/getAll',
    async (data: {[key: string]: any}): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await QuestionAnswersRequests.getFormQuestionUsers(data.id, data.urls);
            if (response.status === 200) {

                return {status: response.status, data: response.data}
            } else if (response.status ===
                422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove