import React, {useState} from 'react';
import Chart from 'react-apexcharts';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { LoadingTableShimmer } from '../../../components/basincComponents';
import Routes from '../../../utils/routing/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const ChartTBP = () => {
    const { TBP, loading } = useSelector((state: RootState) => state.TBP);

    // Map the TBP data into the format required by the heatmap
    const heatmapData = Object.entries(TBP).map(([duration, data]) => ({
        name: ``, // Use the duration as the series name
        data: Object.entries(data).map(([key, values]) => ({
            x: key, // Use the key as the x-axis value
            //@ts-ignore
            y: values.count, // Use the count as the y-axis value
        })),
    }));

//@ts-ignore
    const Bar = Object.values(TBP).map((e) => Object.values(e).map((l) => l.count))

    const [dateChart, setDateChart] = useState<number | null>(null);

    const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(event.target.value); // Parse the selected value to a number
        setDateChart(selectedValue); // Update state with the selected value
    };

    // @ts-ignore
    return (
        <div className="w-full">
            <Link to={Routes.products.path} className="cursor-pointer fixed top-0 z-[40] p-3 bg-white w-full md:hidden flex">
                <span>
                    <IconArrowRight />
                </span>
                <span className="mr-2">TBP</span>
            </Link>
            <div className="heatmap flex justify-center items-center w-full h-full">
                {loading ? (
                    <LoadingTableShimmer />
                ) : (
                    <div className={'w-full'}>
                        <div className={'flex w-full'}>
                            <div className="w-full">
                                <Chart
                                    height={'300'}
                                    options={{
                                        plotOptions: {
                                            heatmap: {
                                                colorScale: {
                                                    ranges: [
                                                        {
                                                            from: 0,
                                                            to: 0, // Adjust these ranges based on your data
                                                            color: '#20d954', // Green color for low values
                                                        },
                                                        {
                                                            from: 1,
                                                            to: 100, // Adjust these ranges based on your data
                                                            color: '#ff0000', // Red color for high values
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    }}
                                    series={heatmapData}
                                    type="heatmap"
                                    width="100%"
                                />
                            </div>
                            {/* Render labels */}
                            <div className="flex flex-col w-24 mt-12">
                                <span>بیشتر</span>
                                <span className="mt-6">365 روز</span>
                                <span className="mt-6">120 روز</span>
                                <span className="mt-6">30 روز</span>
                                <span className="mt-6">7 روز</span>
                            </div>
                        </div>
{/*@ts-ignore*/}
                        <select className="bg-transparent mt-10" value={dateChart} onChange={handleDateChange}>
                            <option value={7}>هفته (7 روز)</option>
                            <option value={30}>ماه (30 روز)</option>
                            <option value={120}>سه ماه (120 روز)</option>
                            <option value={365}>سال (365 روز)</option>
                            <option value={0}>بیشتر</option>
                        </select>
                        <Chart
                            options={{
                                chart: {
                                    type: 'bar',
                                    height: 350,
                                    stacked: true,
                                    toolbar: {
                                        show: true
                                    },
                                    zoom: {
                                        enabled: true
                                    }
                                },
                                responsive: [{
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: 'bottom',
                                            offsetX: -10,
                                            offsetY: 0
                                        }
                                    }
                                }],
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        borderRadius: 4,
                                        dataLabels: {
                                            total: {
                                                enabled: true,
                                                style: {
                                                    fontSize: '13px',
                                                    fontWeight: 900
                                                }
                                            }
                                        }
                                    }
                                },
                                xaxis: {
                                    type: 'category',
                                    categories: ['2', '3', '4', '5', '6', '7', '8', '9', '10'],
                                },
                                legend: {
                                    position: 'right',
                                    offsetY: 40
                                },
                                fill: {
                                    opacity: 1
                                }
                            }}
                            series={[
                                {
                                    // @ts-ignore
                                    data: dateChart == 7 ? Bar[0] : dateChart == 30 ? Bar[1] : dateChart == 120 ? Bar[2] : dateChart == 365 ? Bar[3] : dateChart == 0 ? Bar[4] : Bar[0]

                                    // data: Bar[dateChart] || [], // Show data based on selected date range                                },
                                }


                            ]}
                            type="bar"
                            width="100%"
                        />
                    </div>

                )}


            </div>
        </div>
    );
};

export default ChartTBP;


