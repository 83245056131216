import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import RFMRequests from "../../../requests/RFMRequests";


// export interface ContactAndCustomerCredentials {
//     id: number;
//     username: string;
//     f_name: string;
//     l_name: string;
//     avatar: string;
//     clv: string;
//
// }
export interface RFMCredentials {
    from: string;
    until: string;
}

export const getAllRFM = createAsyncThunk(
    'RFM/getAll',
    async (credentials: RFMCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await RFMRequests.getAllRFM(credentials);

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            } else if (response.status ===
                422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove