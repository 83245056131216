import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { LaravelValidationErrorType } from "utils/types";
import { toast422, toastError } from "utils/funcions";
import { DefaultResponse } from "../../mainSlice";
import LikeRequests from "../../../requests/likeRequests";
import store from "../../store";
import { setProgress } from "../../tools/progressSlice";

export const submitLike = createAsyncThunk(
    'like/submit',
    async (credentials: { product_id: number, is_like: boolean }): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await LikeRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });

            if (response.status === 200 || response.status === 203) {
                return { status: response.status, data: response.data };
            } else if (response.status === 422) {
                const errors = response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                };
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                };
            }
        } catch (e) {
            toastError();
            return {
                status: 500,
                data: null
            };
        }
    }
);
