import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import CartRequests from "../../../requests/cartRequests";
import SettlementOfAccountRequests from "../../../requests/settlementOfAccountRequests";
import PaymentStatusRequests from "../../../requests/paymentStatusRequests";
import store from "../../store";


export interface SearchSettelmentsCredentials {
    title: string | null,
    mobilenumber: string | null,
    minPrice: string | null,
    maxPrice: string | null,
    status: string | null,
}
export const getSettlementOfAccountCart = createAsyncThunk(
    'settlementOfAccountCart/getAll',
    async (credentials?: SearchSettelmentsCredentials): Promise<DefaultResponse> => {
        try {

            const response: AxiosResponse = await SettlementOfAccountRequests.getAllCheckouts(credentials ?? {} as SearchSettelmentsCredentials, store.getState().checkouts.paginate)

            if (response.status === 200) {

                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const changeCheckoutStatus = createAsyncThunk(
    'settlementOfAccountCart/update',
    async (credentials: { status: string, id: number }): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await SettlementOfAccountRequests.changeCheckoutResellerStatus(credentials)
            // const response: AxiosResponse = await PaymentStatusRequests.changeStatus(credentials)
            //
            if (response.status === 200 || response.status === 201) {

                return {status: response.status, data: response.data}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                }
            }
            return {
                status: 200,
                data: null,
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null,
            }
        }
    }
)


