import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import Category from "../../../models/category";
import CategoryRequests from "../../../requests/categoryRequests";
import {parseNumber} from "react-advanced-cropper";
import store from "../../store";
import {clearCategoryData} from "./productsCategorySlice";

export const getProductsCategory = createAsyncThunk(
    'productsCategory/getAll',
    async (data: {[key: string]: any}): Promise<DefaultResponse>=> {
        try {
            if(data.id && data.id != store.getState().adminProductsCategory.catId) {
                store.dispatch(clearCategoryData());
            }
            const response: AxiosResponse = await CategoryRequests.getProductsCategory(data.id ?? store.getState().adminProductsCategory.catId, store.getState().adminProductsCategory.paginate)
            if (response.status === 200) {
                // console.log('afheryrtfyewvdc', response.data);
                return {status: response.status, data: [response.data.data, data.id, store.getState().adminProductsCategory.paginate, data.id ?? store.getState().adminProductsCategory.catId]}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {

            return {
                status: 500,
                data: null
            }
        }
    }
)