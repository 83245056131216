import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import QuestionRequests from "../../../requests/questionRequests";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";

/*
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product*/

export interface QuestionCredentials {
    title: string,
    question_type: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";
    // type?: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";
}

export interface QuestionCreateCredentials extends QuestionCredentials {
    form_question_id: string,
}

export interface QuestionUpdateCredentials extends QuestionCredentials {
    type?: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";
    id: number,
}

export const submit = createAsyncThunk(
    'questions/post',
    async (credentials: QuestionCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await QuestionRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

export interface QuestionsCredentials {
    title: string | null,
    type: string | null,
}

export const getAll = createAsyncThunk(
    'questions/getAll',
    async (data: {[key: string]: any, credentials?: QuestionsCredentials}): Promise<DefaultResponse> => {
        try {
            // console.log('idididid', data.id ?? data.url)
            const response: AxiosResponse = await QuestionRequests.getAllQuestions(data.id ?? data.url, data.credentials ?? {} as QuestionsCredentials, store.getState().questions.paginate)
            if (response.status === 200) {
                return {status: response.status, data: [response.data.data, data.credentials]}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'questions/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await QuestionRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'questions/update',
    async (credentials: QuestionUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await QuestionRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
