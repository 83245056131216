import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import EditProductModal from "./edit";
import {DeleteModal} from "../../../components/modal";
import {serverAsset} from "../../../services/connectionConfig"; // Import the setLoadingMore action creator
import {Link, useLocation} from "react-router-dom";
// import React, {useState} from 'react';
import store, {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import Product from "../../../models/product";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../../redux/mainSlice";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {MdOutlineMoreHoriz, MdProductionQuantityLimits} from "react-icons/md";
import {AiFillFileAdd, AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Routes from "../../../utils/routing/routes";
import {getAll, remove} from "../../../redux/dashboard/products/productsAction";
import empty from "../../../assets/img/No data-pana-min.png";
import {emptyComponent, loadingComponent, NetworkImage, pagination} from "../../../utils/funcions";
import {IconShoppingCartPlus} from "@tabler/icons-react";
import {setPaginate, setPaginateToStart} from "../../../redux/dashboard/products/productsSlice";
import {TbBrandProducthunt} from "react-icons/tb";
import {GrAdd} from "react-icons/gr";
import {FaProductHunt} from "react-icons/fa";
import {AsyncImage} from "loadable-image";


const BUFFER_ZONE = 100;

const ProductsBigList = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {productsList, isEnd, loading, products} = useSelector(
        // @ts-ignore
        (state) => state.adminProducts
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Product | null>(null);

    const handleDeleteClick = (product: Product) => {
        setSelectedItem(product);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            // setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                // setDeletedId(0);
            }
        }
    };

    const [editingItem, setEditingItem] = useState<Product | null>(null);
    const adminProductsState = store.getState().adminProducts;


    // useEffect(() => {
    //     pagination(() => {
    //         if (!store.getState().adminProducts.loading && !adminProductsState.loadingMore && !store.getState().adminProducts.isEnd) {
    //             dispatch(setPaginate());
    //             dispatch(getAll({isReturnCache: false}));
    //         }
    //     })
    // })


    const {shop, user} = useAppSelector((state: RootState) => state.user);

    useEffect(() => {
        // console.log('console.log', store.getState().adminProducts)
        const handlePagination = () => {
            if (!store.getState().adminProducts.loading && !adminProductsState.loadingMore && !store.getState().adminProducts.isEnd) {
                dispatch(setPaginate());
                dispatch(getAll({isReturnCache: false}));
            }
        };

        const cleanup = pagination(handlePagination);
        // if (store.getState().adminProducts.isEnd) {
        //     dispatch(setPaginate());
        // }
        return () => cleanup();
    })
    useEffect(() => {
        setPaginateToStart();
    }, []);

    return (
        <div className={"flex flex-col w-full"}>
            <div className="w-full flex justify-center">
                <DeleteModal
                    title="حذف محصول"
                    message="آیا از حذف این محصول مطمئن هستید؟"
                    onSubmit={handleDeleteConfirm}
                    isOpen={isModalOpen}
                    handleIsOpen={setIsModalOpen}
                />
                <EditProductModal
                    product={editingItem}
                    onClose={(isOpen: boolean) => {
                        setEditingItem(null);
                    }}
                />
                <div className="mb-12 flex flex-wrap justify-start pb-1.5 w-full">
                    {
                        loading ? <div className={'w-full flex justify-center items-center mt-5'}>
                            {loadingComponent()}
                        </div> : productsList.flat().length === 0 ?
                            <div className={'w-full flex-col flex justify-center items-center'}>
                                {/*icon of no product found like instagram*/}
                                <div className={'flex justify-center p-2 mt-5 items-center border rounded-full'}>
                                    <MdProductionQuantityLimits size={30} color={'#9E9E9E'}/>
                                </div>

                                <span className={'text-black font-bold text-[16px] mt-2'}>محصولی یافت نشد.</span>
                                {/*    adding new product like instagram addnew  post*/}
                                {
                                    user!.id === shop!.id && (
                                        emptyComponent({
                                            htmlFor: 'addProduct',
                                            text: 'افزودن اولین محصول',
                                        })
                                    )
                                }
                            </div> :
                            <div className={'w-full '}>
                                {
                                    <div
                                        className={`flex flex-wrap  pb-1.5 w-full`}>
                                        {
                                            //@ts-ignore
                                            productsList.map(products => products!.map((product) =>
                                                <div className="group aspect-square relative w-1/3 md:p-0.5 p-[0.5px]">


                                                    <Link to={Routes.product(product.id).path}
                                                          className={'w-full h-full'}>
                                                        <div
                                                            className={'w-full h-full cursor-pointer p-0 border-[1px] border-gray-50'}>
                                                            {
                                                                product.media.length == 0 ? <img
                                                                        className="w-full h-full p-0 object-cover bg-transparent"
                                                                        src={dLoad}
                                                                        alt={""}/> :
                                                                    <div onDrop={() => {
                                                                        setEditingItem(product);
                                                                    }}
                                                                         className={'w-full h-full justify-center items-center flex'}
                                                                    >
                                                                        {/*<img
                                                                            src={serverAsset(product.media[0].thumbnail)}
                                                                            alt=""
                                                                            className="w-full object-cover justify-center items-center flex h-full bg-transparent" // No blur here
                                                                        />*/}
                                                                        <AsyncImage
                                                                            src={serverAsset(product.media[0].thumbnail)}
                                                                            alt=""
                                                                            className="w-full object-cover justify-center items-center flex h-full bg-transparent" // No blur here
                                                                            error={<img
                                                                                src={dLoad}
                                                                                alt=""
                                                                                className="w-full object-cover justify-center items-center flex h-full bg-transparent" // No blur here
                                                                            />}
                                                                        />
                                                                        {/*<img*/}
                                                                        {/*    className="w-full h-full m-0 p-0 object-cover bg-transparent"*/}
                                                                        {/*    // style={box}*/}
                                                                        {/*    src={serverAsset(product.media[0].thumbnail)}*/}
                                                                        {/*    alt={product.title}/>*/}
                                                                    </div>

                                                            }
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductsBigList;
