interface LikeInterface {
    product_id: number;
    is_like: boolean;
    user_id: number;
}

class Like {
    product_id: number;
    user_id: number;
    is_like: boolean;

    constructor(data: LikeInterface) {
        this.product_id = data.product_id;
        this.is_like = data.is_like;
        this.user_id = data.user_id;
    }
}

export default Like;
