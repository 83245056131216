import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import React from "react";
import ApexChart from "./activeOrNotChart";
import {moneyFormat} from "../../../utils/funcions";
import {LoadingTableShimmer} from "../../../components/basincComponents";
function WarehouseInventoryProducts() {
    const { products } = useAppSelector((state: RootState) => state.adminProducts);
    const { productsLength, productsPrice, loading } = useAppSelector((state: RootState) => state.inventoryProducts);


    return (
        <div className="flex w-full flex-col pr-4">

            <div className={'flex justify-between'}>
                <span>موجودی کل محصولات</span>
                <span className={'px-2 py-1 rounded-xl text-white bg-[#D4AF37]'}>{loading ? <span className="loading loading-spinner text-white"></span> : productsLength}</span>
            </div>
            <div className={'flex mt-5 justify-between'}>
                <span>ارزش کل محصولات</span>
                <span className={'px-2 py-1 rounded-xl text-white bg-[#D4AF37]'}>{loading ? <span className="loading loading-spinner text-white"></span> : moneyFormat(productsPrice)}</span>
            </div>
            <div className={'flex my-5 justify-between'}>
                <span>محصولات فعال و غیر فعال</span>
                {/*<span className={'px-2 py-1 rounded-xl text-white bg-[#D4AF37]'}>{moneyFormat(productsPrice)}</span>*/}
            </div>
<ApexChart/>
        {/*</div>*/}

        </div>
    );
}

export default WarehouseInventoryProducts;

