import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import UserRequests from "../../../requests/userRequests";
import store from "../../store";
import {setProgress} from "../../tools/progressSlice";
import Auth from "../../../services/savedData/auth";
import {replacementUserAvatar} from "../../../utils/constants";
import {serverAsset} from "../../../services/connectionConfig";
import CategoryRequests from "../../../requests/categoryRequests";


export interface UserCredentials {
    fName: string,
    lName: string,
    avatar?: Blob,
    banner?: string
    description?: string
    invitaionCode?: string
    plan_id?: number
}

export interface UserUpdateCredentials extends UserCredentials {
}

/*export const submit = createAsyncThunk(
    'users/post',
    async (credentials: UserCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await UserRequests.create(credentials, (percentage: number) => {
                console.log('progressEvent', percentage);
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)*/

export const getUserInfo = createAsyncThunk(
    'users/getAll',
    async (initial: {isReturnCache?: boolean}): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await UserRequests.getUserInfo({isReturnCache: initial.isReturnCache ?? false})
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else {
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


export const getUsers = createAsyncThunk(
    'categories/getAll',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await UserRequests.getUsersProductPage()
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//update
export const updateProfile = createAsyncThunk(
    'user/update',
    async (credentials: UserUpdateCredentials): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await UserRequests.updateProfile(credentials,(percentage: number) => {
                store.dispatch(setProgress(percentage));
            })
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const deleteProfile = createAsyncThunk(
    'user/update',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await UserRequests.deleteProfile();
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
