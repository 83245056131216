import API from "../services/api";
import {AxiosResponse} from "axios";


class OrderRequests {
    public static async getOrderById(id: number): Promise<AxiosResponse> {

        return await API.authGet(`orders/${id}`, {isUseLoading: true});
    }
}

export default OrderRequests;