import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import TBPRequests from "../../../requests/TBPRequests";


// export interface ContactAndCustomerCredentials {
//     id: number;
//     username: string;
//     f_name: string;
//     l_name: string;
//     avatar: string;
//     clv: string;
//
// }
export interface TBPCredentials {
    from: string;
    until: string;
}

export const getAllTBP = createAsyncThunk(
    'TBP/getAll',
    async (credentials: TBPCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await TBPRequests.getAllTBP(credentials);

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            } else if (response.status ===
                422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove