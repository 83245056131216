import {createAsyncThunk} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../mainSlice";
import {AxiosResponse} from "axios";
import SettingRequests from "../../../requests/settingsRequests";
import store from "../../store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toast422, toastError, toastSuccess} from "../../../utils/funcions";
import {SettingCredentials} from "../../../pages/front/settings/mainSettings";
import {updateSettings} from "../../user/user/userSlice";
import SavedData from "../../../services/savedData/savedData";
import api from "../../../services/api";



export const submit = createAsyncThunk(
    'settings/post',
    async (credentials: SettingCredentials): Promise<void> => {
        try {
            const response: AxiosResponse = await SettingRequests.updateSettings(credentials);
            
            if (response.status === 200 || response.status === 201) {
                (new SavedData( api.getUrlFromEndpoint('users/get'))).del();
                store.dispatch(updateSettings(response.data));
                toastSuccess('با موفقیت بروزرسانی شد')
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
            } else {
                toastError();
            }
        } catch (e) {
            toastError();
            store.dispatch(remProgress());
        }
    }
)