import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllTBP} from "redux/dashboard/TBP/TBPAction";

interface State extends DefaultStates {
    TBP: [];
    submitLoading: boolean;
}


const initialState: State = {
    TBP: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const TBPSlice = createSlice({
    name: 'TBP',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllTBP.pending, (state) => {

            state.loading = true
        })
        builder.addCase(getAllTBP.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.TBP = response.data;

                // state.TBP = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllTBP.rejected, (state, action) => {
                state.loading = false;
            }
        )
    },
});

export default TBPSlice.reducer;