import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import ShopPlan from "../../../models/shopPlan";
import {getAllShopPlans, removePlan, submit, update} from "./shopPlansAction";

interface State extends DefaultStates {
    plans: ShopPlan[];
    submitLoading: boolean;
}

const initialState: State = {
    plans: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const ShopPlansSlice = createSlice({
    name: 'shopPlan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                state.plans.push(new ShopPlan({...data, title: data.title, description: data.description, end_time: data.end_time, price: data.price}));
                toastSuccess('پلن با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })

        builder.addCase(getAllShopPlans.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllShopPlans.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const plans: ShopPlan[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const plan = response.data[i];
                    plans.push(plan);
                }
                state.plans = plans;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllShopPlans.rejected, (state, action) => {
                state.loading = false;
            }
        )

        //remove
        builder.addCase(removePlan.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(removePlan.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
            //    remove from list
                const index = state.plans.findIndex(plan => plan.id === response.data.id);
                state.plans =  state.plans.filter((plan, i) => i !== index);
                toastSuccess('پلن با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(removePlan.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.plans.findIndex(plan => plan.id === response.data.id);
                state.plans[index] = new ShopPlan({...response.data, title: response.data.title, description: response.data.description, price: response.data.price});
                toastSuccess('پلن با موفقیت ویرایش شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export default ShopPlansSlice.reducer;