import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";

const AnswersList = () => {

    const {questionAnswers, loading} = useAppSelector((state: RootState) => state.questionAnswersSlice);

    return (
        <div className={'flex flex-col'}>
            <span className={'mt-10'}>سوال: {questionAnswers?.title}</span>
            <div className="flex flex-wrap w-full pb-2 mt-6 justify-evenly">
                {
                    loading ? <div></div> :
                        questionAnswers!.answers?.map((e, i) => <div
                            className={'flex flex-col lg:w-[32%] md:w-[47%] mt-4 w-full justify-center items-center'}>
                            <div
                                className={`flex items-center indicator ${i % 2 === 0 ? 'bg-[#4192EF]' : 'bg-white'}  shadow-md rounded-2xl justify-center py-4 mx-1 w-full flex-col`}>
                <span
                    className={`indicator-item indicator-center ml-2 badge badge-secondary border-none ${i % 2 === 0 ? 'bg-white' : 'bg-[#4192EF]'} ${i % 2 === 0 ? 'text-[#4192EF]' : 'text-white'}`}>{e.ip}</span>
                                <div className={`mt-4 ${i % 2 === 0 ? 'text-white' : 'text-black'}`}>
                                    {e.answer}
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default AnswersList;