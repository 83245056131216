import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {IconChevronDown, IconEdit, IconChevronUp, IconX, IconSearch, IconFilter} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";
import logo from "../../../assets/img/No data-pana-min.png";
import {moneyFormat, NetworkImage} from "../../../utils/funcions";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import Product from "../../../models/product";
import EditProductModal from "../products/edit";
import {setPaginateToStart} from "../../../redux/dashboard/products/productsSlice";
import {getAll} from "../../../redux/dashboard/products/productsAction";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import moment from "jalali-moment";
import Payment from "../../../models/payment";

export type paymentUserStatus = 'pending' | 'settled' | 'failed';
// Define the mapping object with translations for each cart status
export const paymentUserStatusMap: Record<paymentUserStatus, string> = {
    //@ts-ignore
    pending: {
        color: '#4192EF',
        title: 'پرداخت شده',

    },
    //@ts-ignore
    settled: {
        color: '#00FF00',
        title: 'پرداخت شده',

    },
    //@ts-ignore
    failed:{
        color: '#00FF00',
        title: 'پرداخت شده',
    },
};
function WarehouseOrdersDetail() {
    const { orders, ordersPrice, loading } = useAppSelector((state: RootState) => state.inventoryProducts);
    const [copy, setCopy] = useState<boolean>(true);
    const [editingItem, setEditingItem] = useState<Product | null>(null);
    // const [tabBar, setTabBar] = useState<number>(1); // شروع با تب بار 1 (محصولات فعال)
    const [sortOrder, setSortOrder] = useState<string>('desc'); // مرتب‌سازی نزولی
    const [sortColumn, setSortColumn] = useState<string>('quantity'); // ستون مرتب‌سازی
    const [searchTerm, setSearchTerm] = useState<string>(''); // مقدار جستجو

    const [tabBar, setTabBar] = useState<number>(1);
    const [delayedColor, setDelayedColor] = useState<string>('text-white'); // رنگ اولیه برای تاخیر
    const [delayedColorGold, setDelayedColorGold] = useState<string>('text-white'); // رنگ اولیه برای تاخیر
    // const [delayedColor3, setDelayedColor3] = useState<string>('text-white'); // رنگ اولیه برای تاخیر

    const handleChangeTabBar = (tab: number) => {
        // رنگ متن را بلافاصله تغییر می‌دهیم
        setTabBar(tab);

        // پس از 1 ثانیه رنگ متن را تغییر می‌دهیم
        setTimeout(() => {
            setDelayedColor(tab === 1 ? 'text-white' : 'text-[#D4AF37]');
            setDelayedColorGold(tab === 1 ? 'text-[#D4AF37]' : 'text-white');
        }, 1000); // تغییر رنگ بعد از 1 ثانیه
    };

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('desc');
        }
    };

    const getSortedProducts = (products: Payment[]) => {
        return orders.sort((a, b) => {
            if (sortOrder === 'asc') {
                //@ts-ignore
                return a[sortColumn] - b[sortColumn];
            } else {
                //@ts-ignore
                return b[sortColumn] - a[sortColumn];
            }
        });
    };


    // محاسبه تعداد محصولات فعال و غیرفعال
    // const activeProductsCount = orders.filter(product => product.status).length;
    // const inactiveProductsCount = productsList.reduce((acc, products) => acc + products.filter(product => !product.is_product_active).length, 0);

    const [title, setTitle] = useState<string>('');
    const lastSearchTimeRef = useRef(Date.now());

    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleSearch = async () => {
        dispatch(setPaginateToStart())
        const data = { title, minPrice, maxPrice, status };

        try {
            dispatch(getAll({isReturnCache: true, credentials: data}));
        } catch (error) {
            alert('مشکلی پیش آمد.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle("");
        handleSearch()
    };

    const handleDeleteSearchByMinPayment = () => {
        setMinPrice("");
    };
    const handleDeleteSearchByMaxPayment = () => {
        setMaxPrice("");
    };
    const handleDeleteSearchByStatus = () => {
        setStatus("");
    };

    const {settings} = useSelector((state: RootState) => state.user);

    return (
        <div className="flex flex-col pr-4">
            <EditProductModal
                product={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            />
            <div className="flex w-full justify-between">
                <span className="flex mb-5 gap-x-2 md:px-0 relative px-4">
                    <div
                        className={`absolute bg-[#D4AF37] z-2 mt-3 rounded-lg ${tabBar === 1 ? 'w-[140px] h-8 tab-bar-active' : tabBar === 2 ? 'w-[115px] tab-bar2-active h-1 mr-36' : 'w-[160px] tab-bar3-active h-1 mr-[262px]'} duration-300`}
                    ></div>
                    <div
                        onClick={() => handleChangeTabBar(1)}
                        className={`px-3 py-3 duration-300 cursor-pointer z-[41] gap-x-2 justify-center items-center flex w-max text-[#D4AF37] p-3 rounded-xl ${
                            tabBar === 1 ? delayedColor  : 'text-[#D4AF37]'
                        }`}
                    >
                        <span className="text-[15px] mr-2">درحال بررسی ({orders.length})</span>
                    </div>
                </span>
                {/*<div className="flex flex-col">*/}
                {/*    <div className="input input-bordered flex items-center px-0 p-1">*/}
                {/*        <div className="relative w-full">*/}
                {/*            <input*/}
                {/*                type="search"*/}
                {/*                placeholder="نام محصول"*/}
                {/*                value={title}*/}
                {/*                onKeyDown={(e) => e.key === 'Enter' && handleSearch() }*/}
                {/*                onChange={(e) => setTitle(e.target.value)}*/}
                {/*                className="input placeholder:text-[14px] border-none input-primary max-h-[20px] min-w-[250px] min-h-[20px] w-full outline-none focus:outline-none"*/}
                {/*            />*/}
                {/*            <button*/}
                {/*                onClick={handleSearch}*/}
                {/*                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                {/*            >*/}
                {/*                <IconSearch size={'24'}/>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="flex mt-2">*/}
                {/*        {filteredProducts.length > 0 && (*/}
                {/*            <span className="text-sm text-gray-500">تعداد نتایج: {filteredProducts.flat().length}</span>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>


            <div className="overflow-x-auto w-full">
                <table className="items-center w-full mt-[60px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            شناسه پیگیری
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            مشتری
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            تاریخ
                        </th>
                        <th
                            className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 cursor-pointer flex items-center gap-x-2"
                            onClick={() => handleSort('final_price')}
                        >
                            جمع
                            {sortColumn === 'final_price' && (
                                sortOrder === 'asc' ? <IconChevronUp /> : <IconChevronDown />
                            )}
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            هزینه ارسال
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            وضعیت پرداخت
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        orders.map((product, i) => (
                            <tr
                                key={product.id}
                                className={`group py-2 transition-all border-b border-b-gray-200 mt-1 hover:bg-neutral-100 ${
                                    i % 2 === 1 ? 'bg-[#D4AF3712]' : 'bg-white'
                                } items-start justify-center`}
                            >
                                <td className="px-6 py-4 flex items-center font-semibold text-start align-middle shadow-none tracking-none whitespace-nowrap">
                                    {product.tracking_code ?? 'ندارد'}
                                </td>
                                <td className="px-6 py-2 pl-2 font-semibold text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap">
                                    {product.full_name}
                                </td>
                                <td className="px-6 py-2 font-semibold text-start uppercase align-middle text-md border-b-solid tracking-none whitespace-nowrap">
                                    {moment(product.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}

                                </td>
                                <td className="px-6 py-2 font-semibold text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap">
                                    {moneyFormat(product.final_price)}
                                </td>
                                <td
                                    className="px-6 py-2 font-semibold border-t text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap"
                                    style={{ width: '20px' }}
                                >
                                    {settings.find(e => e.name === 'shipping_cost')?.value ?? ''}

                                </td>
                                <td className="px-6 py-2 font-semibold text-white w-full border-t text-start uppercase align-middle text-xs border-b-solid tracking-none whitespace-nowrap">
                                    <div
                                        className={`p-2 rounded-lg ${
                                            product.status === 'pending'
                                                ? 'bg-[#4192EF] w-[78px]'
                                                : product.status === 'settled'
                                                    ? 'bg-green-500 w-[77px]'
                                                    : 'bg-[#DE0046] w-[58px]'
                                        }`}
                                    >
                                        {product.status === 'pending' ? (
                                            <span>درحال بررسی</span>
                                        ) : product.status === 'settled' ? (
                                            <span>پرداخت شده</span>
                                        ) : (
                                            <span>لغو شده</span>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
                {orders.length === 0 && !loading ? (
                    <div className="w-full flex flex-col justify-center items-center">
                        <img src={logo} alt="" className="w-[30%]" />
                        <span className="text-lg">محصولی وجود ندارد</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default WarehouseOrdersDetail;

