import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser, toastError, toastSuccess} from "../utils/funcions";
import {ChatCredentials} from "../redux/dashboard/messenger/chatAction";

class ChatRequests {

    public static async getAllChat(id: number): Promise<AxiosResponse> {
        return await API.authGet(`msgs/${id}`, {isUseLoading: true});
    }public static async getAllUsers(): Promise<AxiosResponse> {
        return await API.authGet('msgs', {isUseLoading: true});
    }
    static async getProductsCategory(id: number): Promise<AxiosResponse> {
        return await API.authGet(`productsCategory/${id}`, {isUseLoading: true});
    }

    static async create(credentials: ChatCredentials) {
        // console.log('crehshhshdentials: ', credentials);
        return await API.authPost('msgs', {
            data: credentials,
            isUseLoading: true,
        });
    }
}


export default ChatRequests;