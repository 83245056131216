import { createSlice } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
import Notification from "../../../models/notification";
import { getAllNotifications, getAllUnreadNotifications } from "./unreadNotificationsAction";
import { toastSuccess } from "../../../utils/funcions";

interface State extends DefaultStates {
    unreadNotifications: Notification[];
    notifications: Notification[];
    unreadNotificationCount: number;
    submitLoading: boolean;
}

const initialState: State = {
    unreadNotifications: [],
    notifications: [],
    unreadNotificationCount: 0,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const UnreadNotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllUnreadNotifications.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAllUnreadNotifications.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Notification[] = [];
                const newNotifications: Notification[] = [];

                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    const notification = new Notification({
                        message: contact.message,
                        id: contact.id,
                        shop_id: contact.shop_id,
                        title: contact.title,
                        user_id: contact.user_id,
                        shop_seened: contact.shop_seened,
                        user_seened: contact.user_seened,
                        user_ip: contact.user_ip,
                        created_at: contact.created_at,
                    });

                    contacts.push(notification);

                    // چک می‌کنیم که آیا این نوتیفیکیشن جدید است
                    const isNew = !state.notifications.some(n => n.id === notification.id);
                    if (isNew) {
                        newNotifications.push(notification);
                    }
                }

                state.unreadNotifications = contacts;

                state.notifications = [...newNotifications, ...state.notifications];

                if (state.unreadNotificationCount !== contacts.length && contacts.length !== 0) {
                    toastSuccess('اعلان جدید دریافت شد');
                }
                state.unreadNotificationCount = contacts.length;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });

        builder.addCase(getAllUnreadNotifications.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getAllNotifications.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAllNotifications.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;

            if (response.status === 200) {
                const newNotifications: Notification[] = [];

                for (let i = 0; i < response.data.length; i++) {
                    const contact = response.data[i];
                    const notification = new Notification({
                        message: contact.message,
                        id: contact.id,
                        shop_id: contact.shop_id,
                        title: contact.title,
                        user_id: contact.user_id,
                        shop_seened: contact.shop_seened,
                        user_seened: contact.user_seened,
                        user_ip: contact.user_ip,
                        created_at: contact.created_at,
                    });

                    const isNew = !state.notifications.some(n => n.id === notification.id);
                    if (isNew) {
                        newNotifications.push(notification);
                    }
                }

                state.notifications = [...newNotifications, ...state.notifications];
                state.unreadNotificationCount = 0;

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });

        builder.addCase(getAllNotifications.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default UnreadNotificationsSlice.reducer;
