import marker from "../../../../assets/img/marker_red.png";
import {CSSProperties, useEffect} from "react";
import NeshanMapLoader from "../neshan-map-loader";

export const NeshanMapLeaflet = (props: {styles?: CSSProperties, options: Object, location: (e: any) => void, fullAddress: (e: any) => void}) =>
{

    const componentUid = 'map-' + Math.floor(Math.random() * 100);

    const mapKey = "web.d2bb162357c4414bb25c7d9586e6fd7f"
    const serviceKey = "service.27ca7dac57084659b2edf162d809ae24"

    const defaultOptions = {
        key: mapKey,
        maptype: 'dreamy',
        poi: true,
        traffic: false,
        center: [36.30284183765441, 59.5958496945331],
        zoom: 14,
    };

    const defaultStyles: CSSProperties = {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        background: '#eee',
        border: '2px solid #aaa',
        position: 'relative'
    };

    const markerStyles: CSSProperties = {
        width: '30px',
        height: '44px',
        background: 'transparent center center no-repeat',
        position: 'absolute',
        left: '50%',
        top: '50%',
        margin: '-44px auto auto -15px',
        zIndex: '401',
        backgroundImage: 'url(' + marker + ')'
    }

    const bannerStyles = {
        ...defaultStyles,
        ...props.styles
    };

    useEffect(() => {
        NeshanMapLoader({
            onLoad: () => {
                const L = (window as any).L;
                const _map = new L.Map(componentUid, { ...defaultOptions, ...props.options });
                _map.on('moveend', function () {
                    const point = _map.getCenter();
                    props.location(point);
                    fetch(`https://api.neshan.org/v4/reverse?lat=${point.lat}&lng=${point.lng}`, {
                        headers: {
                            'Access-Control-Request-Headers': 'api-key',
                            "Api-Key": serviceKey
                        }
                    }).then((result) => {
                        result.json().then(e=> {
                            props.fullAddress(e)
                        })
                    })
                });
            },
            onError: () => {
                console.error('Neshan Maps Error: This page didn\'t load Neshan Maps correctly')
            },
        })
    }, [])

    return (
        <div className={`w-full h-full lg:rounded-l-none rounded-l-2xl rounded-r-2xl shadow-xl`}>
            <div
                className={'lg:rounded-l-none rounded-l-2xl rounded-r-2xl'}
                id={componentUid} style={bannerStyles}>
                <div className={'lg:rounded-l-none rounded-l-2xl rounded-r-2xl'} style={markerStyles}></div>
            </div>
        </div>
    )
}