import FormQuestion from "./formQuestion";
import Question from "./question";

interface IUserWithAnswer {
    id?: number | null;
    answer?: string;
    ip?: string;
    form_question_id?: string;
    question?: Question;
    // id: number;
}

class Answer {

    id?: number | null;
    answer?: string;
    ip?: string;
    form_question_id?: string;
    question?: Question;

    constructor(data: IUserWithAnswer) {
        this.id = data.id;
        this.ip = data.ip;
        this.answer = data.answer;
        this.question = data.question;
        this.form_question_id = data.form_question_id;
        // this.reciever = data.reciever;
        // this.sender = data.sender;
        // this.message_id = data.message_id;
    }

}

export default Answer;