import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";
import AnswersList from "./list";
function Answers() {
    return (
        <>
            <AnswersList></AnswersList>
        </>
    );
}

export default Answers;