import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser} from "../utils/funcions";

class HighLightRequests {

    public static async getAllHighLights(id: number): Promise<AxiosResponse> {
        return await API.authGet(`highLightedProducts/${id}`, {isUseLoading: true});
    }
    public static async highLights(): Promise<AxiosResponse> {
        return await API.get("highLights",{isUseLoading: true});
    }
}


export default HighLightRequests;