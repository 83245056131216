import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser} from "../utils/funcions";

class CategoryRequests {

    public static async getAllCategories(): Promise<AxiosResponse> {
        const userType = checkUser();
            return await API.authGet("categories", {isUseLoading: true});
    }
    static async getProductsCategory(id: number, page: number): Promise<AxiosResponse> {
        console.log('console.log', id)
        return await API.authGet(`productsCategory/${isNaN(id) ? '' : id}?page=${page}`, {isUseLoading: true});
    }

    static async getCategoriesForAddProduct(): Promise<AxiosResponse> {
        return await API.authGet(`getCategoriesForAddProduct`, {isUseLoading: true});
    }

    static async create(credentials: { title: string; parentId?: number }) {
        return await API.authPost('categories', {
            data: {
                title: credentials.title,
                parent_id: credentials.parentId,
            },
            isUseLoading: true,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`categories/${id}`, {isUseLoading: true});
    }

    static async update(credentials: { id: number; title: string; parentId?: number }): Promise<AxiosResponse> {
        return await API.authPut(`categories/${credentials.id}`, {
            data: {
                title: credentials.title,
                parent_id: credentials.parentId,
            },
            isUseLoading: true,
        });
    }
}


export default CategoryRequests;