import {Link} from "react-router-dom";
import Routes from "../../utils/routing/routes";

export const SuccessAnswerSubmitted = () => (<section
    className="h-screen bg-center bg-no-repeat bg-cover bg-[#4192EF] dark:from-gray-800 dark:to-gray-800 to-green-200 dark:form-gray-500bdark: font-poppins">
    <div className="flex items-center py-20">
        <div className="container relative justify-center px-4 mx-auto text-center">
            <h1 className="inline-block mb-8 font-semibold text-gray-100 text-7xl lg:text-9xl dark:text-gray-300"> ثبت شد!
            </h1>
            <h2 className="mb-8 text-2xl font-semibold text-gray-100 lg:text-4xl dark:text-gray-400">پاسخ شما با موفقیت ثبت شد
            </h2>
            <div className="flex flex-wrap items-center justify-center">
                <Link to={Routes.products.path}
                   className="w-full px-8 py-3 mb-4 mr-0 text-base font-medium text-gray-100 uppercase bg-blue-600 rounded-full lg:w-auto hover:bg-blue-800 lg:mb-0 lg:mr-4 md:w-full ">
                    خانه
                </Link>
            </div>
        </div>
    </div>
</section>)