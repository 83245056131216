export interface SettingInterface {
    name: string;
    value: string;
    family: string;
}

class Setting {

    name: string;
    value: string;
    family: string;

    constructor(data: SettingInterface) {
        this.name = data.name;
        this.value = data.value;
        this.family = data.family;
    }

}

export default Setting;