import Payment from "./payment";
import Plan from "./plan";

export interface IUser {
    id: number;
    username: string;
    email: string;
    f_name: string;
    l_name: string;
    avatar: string;
    mobile_number: string;
    description: string;
    banner: string;
    video: string;
    wallet_amount: string;
    isReseller: boolean ;
    is_blocked: boolean ;
    user_type: string;
    is_super_admin?: boolean;
    payments_count?: number;
    shop_plan?: Plan;
    domain?: string;
    unique_name?: string;
    payments?: Payment[];
    buying_date?: string;
    plan_id?: number;
}

class User {

    public id: number;
    public username: string;
    public email: string;
    public fName: string;
    public f_name: string;
    public payments_count?: number;
    public lName: string;
    public l_name: string;
    public avatar: string;
    public mobileNumber: string;
    public mobile_number?: string;
    public description: string;
    public banner: string;
    public video: string;
    public unique_name?: string;
    public walletAmount: string;
    public user_type: string;
    public isReseller: boolean;
    public is_blocked: boolean;
    public is_super_admin?: boolean;
    domain?: string;
    public payments?: Payment[];
    public shop_plan?: Plan;
    // public shop_plan?: ShopPla[];
    public buying_date?: string;
    public plan_id?: number;

    constructor(data: IUser) {
        this.id = data.id;
        this.username = data.username;
        this.email = data.email;
        this.fName = data.f_name;
        this.f_name = data.f_name;
        this.lName = data.l_name;
        this.l_name = data.l_name;
        // this.is_blocked = data.l_name;
        this.avatar = data.avatar;
        this.mobileNumber = data.mobile_number;
        this.mobile_number = data.mobile_number;
        this.payments_count = data.payments_count;
        this.unique_name = data.unique_name;
        this.description = data.description;
        this.user_type = data.user_type;
        this.is_super_admin = data.is_super_admin;
        this.banner = data.banner;
        this.video = data.video;
        this.domain = data.domain;
        this.walletAmount = data.wallet_amount;
        this.isReseller = data.isReseller;
        this.is_blocked = data.is_blocked;
        this.shop_plan = data.shop_plan;
        this.payments = data.payments;
        this.buying_date = data.buying_date;
        this.plan_id = data.plan_id;

    }

}

export default User;