import Product from "./product";

interface CategoryInterface {
    id: number;
    title: string;
    parentId?: number;
    products?: Product[];
    parent?: Category;
    children?: Category[];
    products_count?: number;
}

class Category {

    id: number;
    title: string;
    parentId?: number;
    products?: Product[];
    parent?: Category;
    children?: Category[];
    products_count?: number;

    constructor(data: CategoryInterface) {
        this.id = data.id;
        this.title = data.title;
        this.parentId = data.parentId;
        this.products = data.products;
        this.parent = data.parent;
        this.children = data.children;
        this.products_count = data.products_count;
    }

}

export default Category;