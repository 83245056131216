import React, {useEffect, useState} from "react";
import DefaultModal from "../../../components/modal";
import {Form, Formik, FormikHelpers} from "formik";
import {DefaultInput} from "../../../components/fieilds";
import {RegisterCredentials, submit} from "redux/user/register/registerAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import store, {RootState} from "../../../redux/store";
import {IconArrowRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {getQueryStringValue} from "../../../utils/funcions";
import {setMobileNumber} from "../../../redux/user/login/loginSlice";

function RegisterModal() {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const [isUsePassword, setIsUsePassword] = useState(true);

    //useSelector is a hook that allows you to extract data from the Redux store state, using a selector function.
    const {loading, mobileNumber, isReseller} = useAppSelector((state: RootState) => state.login);

    const handleRegister = async (data: RegisterCredentials) => {
        dispatch(setMobileNumber(data.mobileNumber));

        try {
            // console.log('mobileNumber', mobileNumber, data)
            // data.mobile_number = mobileNumber
            // data.mobileNumber = mobileNumber
            // console.log('mobileNumber', data)
            dispatch(submit({...data, mobile_number: mobileNumber, is_reseller: isReseller}));
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (getQueryStringValue('reseller_invitation_id')) {
            (document.querySelector('#registerModal') as HTMLInputElement).checked = true;
        }
    }, []);

    return (
        <DefaultModal
            label={'registerModal'} id='registerModal' modalBoxClasses={' overflow-x-hidden px-3'}>
            <label htmlFor={"registerModal"}>

                <div className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={'mr-2'}>ثبت نام</span>
                </div>
            </label>
            <Formik
                initialValues={{
                    mobileNumber: mobileNumber,
                    password: '',
                    confirm_password: '',
                    f_name: '',
                    l_name: '',
                    username: 'shop',
                    invitaion_user_id: getQueryStringValue('reseller_invitation_id') ?? '',
                    is_reseller: isReseller
                }}
                onSubmit={
                    (values: RegisterCredentials, {setSubmitting}: FormikHelpers<RegisterCredentials>) => {
                        values.mobileNumber = mobileNumber
                        handleRegister(values);
                    }
                }

                //validation
                validate={(values: RegisterCredentials) => {
                    const errors: any = {};

                    if (isUsePassword) {
                        if (!values.password) {
                            errors.password = 'رمز عبور را وارد کنید';
                        } else if (values.password.length < 8) {
                            errors.password = 'رمز عبور باید حداقل 8 کاراکتر باشد';
                        }
                        if (!values.confirm_password) {
                            errors.confirmPassword = 'تکرار رمز عبور را وارد کنید';
                        }
                        if (values.password !== values.confirm_password) {
                            errors.confirmPassword = 'رمز عبور و تکرار آن یکسان نیست';
                        }
                    }
                    if (values.f_name) {
                        if (values.f_name.length < 3) {
                            errors.f_name = 'حداقل 3 کاراکتر';
                        }
                        if (values.f_name.length > 60) {
                            errors.f_name = 'حداکثر 60 کاراکتر';
                        }
                    } 
                    if (!mobileNumber) {

                        // if (values.mobileNumber) {
                            errors.mobileNumber = 'شماره موبایل الزامی است';
                        // }
                    }
                    if (values.l_name) {
                        if (values.l_name.length < 3) {
                            errors.l_name = 'حداقل 3 کاراکتر';
                        }
                        if (values.l_name.length > 60) {
                            errors.l_name = 'حداکثر 60 کاراکتر';
                        }
                    } else {
                        errors.l_name = 'نام خانوادگی الزامی است';
                    }
                    if (values.confirm_password ) {
                        if (!values.confirm_password) {
                            errors.password = 'تکرار رمز عبور را وارد کنید';
                        } else if (values.confirm_password.length < 8) {
                            errors.password = 'تکرار رمز عبور باید حداقل 8 کاراکتر باشد';
                        }
                        if (!values.confirm_password) {
                            errors.confirmPassword = 'تکرار رمز عبور را وارد کنید';
                        }
                        if (values.confirm_password !== values.confirm_password) {
                            errors.confirmPassword = 'رمز عبور و تکرار آن یکسان نیست';
                        }

                    }
                    return errors;
                }}
            >
                <Form method="dialog">
                    <div className='flex-col justify-start items-center w-full flex md:mt-6 mt-10'>


                        <DefaultInput name='mobileNumber' type='text' placeholder='شماره موبایل' value={mobileNumber}
                                      label='شماره موبایل'/>

                        <DefaultInput name={'f_name'} type={'text'} placeholder={'نام'}
                                      label={'نام'}/>
                        <DefaultInput name={'l_name'} type={'text'} placeholder={'نام خانوادگی'}
                                      label={'نام خانوادگی'}/>


                        {/*<div className="flex items-center mb-4 w-full">*/}
                        {/*    <div className="flex items-center">*/}
                        {/*        <input*/}
                        {/*            type="checkbox"*/}
                        {/*            checked={isUsePassword}*/}
                        {/*            onChange={(e) => setIsUsePassword(e.target.checked)}*/}
                        {/*            className="checkbox"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <label className="label mr-3">*/}
                        {/*        <span className="label-text">ثبت رمز عبور</span>*/}
                        {/*    </label>*/}
                        {/*</div>*/}

                        <div
                            className={`transition-max-height overflow-hidden w-full ${isUsePassword ? 'max-h-56' : 'max-h-0'}`}>
                            <DefaultInput name={'password'} type={'password'} placeholder={'رمز عبور'}
                                          label={'رمز عبور'}/>
                            <DefaultInput name={'confirm_password'} type={'password'} placeholder={'تکرار رمز عبور'}
                                          label={'تکرار رمز عبور'}/>
                        </div>
                        {!getQueryStringValue('reseller_invitation_id') && <DefaultInput name={'invitaion_user_id'} type={'text'} placeholder={'کد دعوت (اختیاری)'}
                                                                                         label={'کد دعوت'}/>}


                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button type="submit"
                                className={`btn hover:text-[#4192EF] text-white ${loading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                             {
                            loading ? 'لحظه ای صبر کنید' : 'ثبت نام '
                        }
                        </button>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
}

export default RegisterModal;