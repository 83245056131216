import API from "../services/api";
import {AxiosResponse} from "axios";

class UTMRequests {

    public static async getAllUTMs(): Promise<AxiosResponse> {
        return await API.authGet("UTMs", {isUseLoading: true});
    }

}



export default UTMRequests;