import {IUser} from "./user";

interface CheckoutInterface {
    reseller_price: number;
    id: number;
    status: string;
    user: IUser;
}

class Order {
    id: number;
    status: string;
    reseller_price: number;
    user: IUser;

    constructor(data: CheckoutInterface) {
        this.id = data.id;
        this.reseller_price = data.reseller_price;
        this.status = data.status;
        this.user = data.user;
    }
}

export default Order;