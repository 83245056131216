import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Plan from "../../../models/plan";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAllPlans, removePlan, submit, update} from "./plansAction";
import plans from "../../../pages/front/plans";

interface State extends DefaultStates {
    plans: Plan[];
    submitLoading: boolean;
}

const initialState: State = {
    plans: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const PlansSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                toastSuccess('پلن با موفقیت اضافه شد');
                state.plans.push(new Plan({...data}));
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })

        builder.addCase(getAllPlans.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllPlans.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const plans: Plan[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const category = response.data[i];
                    plans.push(new Plan({
                        id: category.id,
                        title: category.title,
                        description: category.description,
                        end_time: category.end_time,
                        price: category.price
                    }));
                }
                state.plans = plans;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllPlans.rejected, (state, action) => {
                state.loading = false;
            }
        )

        //remove
        builder.addCase(removePlan.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(removePlan.fulfilled, (state, action) => {
            const response = action.payload;

            if (response.status === 200) {
                const plans = [...state.plans];

                const index = plans.findIndex(category => category.id === response.data);

                if (index !== -1) {
                    plans.splice(index, 1);
                    state.plans = plans;
                    toastSuccess('پلن با موفقیت حذف شد');
                } else {
                }
            } else if (response.status === 422) {
                state.validationErrors = response.data;
            }
        });

        builder.addCase(removePlan.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.plans.findIndex(category => category.id === response.data.id);
                state.plans[index] = new Plan({...response.data, title: response.data.title, description: response.data.description, price: response.data.price});
                toastSuccess('پلن با موفقیت ویرایش شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});

export default PlansSlice.reducer;