import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Chat from "../../../models/chat";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAllChats, submit} from "./chatAction";

interface State extends DefaultStates {
    chats: Chat[];
    submitLoading: boolean;
}

const initialState: State = {
    chats: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const ChatSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                state.chats.push(new Chat({...data, message: data.message, reciever_id: data.reciever_id, message_id: data.message_id, sender: data.sender, reciever: data.reciever, created_at: data.created_at}));
                toastSuccess('تیکت با موفقیت ارسال شد.');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })

        // messages
        builder.addCase(getAllChats.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllChats.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const chats: Chat[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const category = response.data[i];
                    chats.push(new Chat({message: category.message, sender_id: category.sender_id, reciever_id: category.reciever_id, message_id: category.message_id, id: category.id, reciever: category.reciever, sender: category.sender, is_me: category.is_me, created_at: category.created_at}));
                }

                state.chats = chats;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllChats.rejected, (state, action) => {
                state.loading = false;
            }
        )

    },
});

export default ChatSlice.reducer;