import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllRFM} from "redux/dashboard/RFM/RFMAction";
/*{"Champion":0,"Loyal":0,"Potential Loyalist":0,"new Customer":35,"Promising":0,"Needs Attention":0,"About Sleep":4,"Cannot Lose Them":1,"at Risk":9,"Hibernating":0,"Losing But Engaged":0,"Lost Customer":3}*/
interface State extends DefaultStates {
    RFM: { [key: string]: number };
}


const initialState: State = {
    RFM: {},
    loading: false,
    validationErrors: null,
};
const RFMSlice = createSlice({
    name: 'RFM',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllRFM.pending, (state) => {

            state.loading = true
        })
        builder.addCase(getAllRFM.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.RFM = response.data;

                // state.RFM = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllRFM.rejected, (state, action) => {

                state.loading = false;
            }
        )
    },
});

export default RFMSlice.reducer;