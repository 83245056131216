import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
// import {getOrderCart} from "./orderAction";
import Payment from "../../../models/payment";
import {changeCheckoutStatus, getSettlementOfAccountCart} from "./settlementOfAccountAction";
import Checkout from "../../../models/checkout";
import User from "../../../models/user";

interface State extends DefaultStates {
    checkouts: Checkout[];
    checkoutsList: Checkout[][];
    paginate: number;
    isEnd: boolean;
    submitLoading: boolean;
    loadingMore: boolean;
}

const initialState: State = {
    loading: false,
    submitLoading: false,
    validationErrors: null,
    paginate: 1,
    loadingMore: false,
    isEnd: false,
    checkouts: [],
    checkoutsList: []
};

const SettlementOfAccountSlice = createSlice({
    name: 'checkouts',
    initialState,
    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1; // Update loadingMore state
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.paginate = 1;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getSettlementOfAccountCart.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            } else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getSettlementOfAccountCart.fulfilled, (state, action) => {

            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Checkout[] = [];
                const title = response.data[1]
                for (let i = 0; i < response.data.data.length; i++) {
                    const contact = response.data.data[i];
                    contacts.push(new Checkout({
                        id: contact.id,
                        status: contact.status,
                        reseller_price: contact.reseller_price,
                        user: contact.user,
                    }));
                }


                if (response.data.length === 0 || response.data.length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1) {
                    state.checkoutsList.push(contacts);
                } else if (title && (title.title || title.price || title.product || title.category)) {
                    state.checkoutsList = [contacts];
                } else if (state.paginate === 1) {
                    state.checkoutsList = [contacts];
                } else {
                    state.checkoutsList.push(contacts);
                }
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })

        builder.addCase(getSettlementOfAccountCart.rejected, (state, action) => {

                state.loading = false;
            }
        ).addCase("setLoadingMore/pending", (state) => {
            state.loadingMore = true;
        })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });


        builder.addCase(changeCheckoutStatus.pending, (state) => {
            state.submitLoading = true;
        })
        builder.addCase(changeCheckoutStatus.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.checkoutsList.forEach((contactList) => {
                    const index = contactList.findIndex(contact => contact.id === response.data.data.id);
                    contactList[index] = new Checkout(response.data.data);
                })

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(changeCheckoutStatus.rejected, (state, action) => {

                state.loading = false;
            }
        )

    },
});
export const {setPaginate, setPaginateToStart, setAllStatesToTrue} = SettlementOfAccountSlice.actions;

export default SettlementOfAccountSlice.reducer;