import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import QuestionAnswersRequests from "../../../requests/questionAnswersRequests";


export interface SearchAnswerCredentials {
    title: string | null,
    minPrice: string | null,
    maxPrice: string | null,
    status: string | null,
}
export const getFormQuestionUsers = createAsyncThunk(
    'questionAnswers/getAll',
    async (data: {[key: string]: any, credentials: SearchAnswerCredentials}): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await QuestionAnswersRequests.formQuestionAnswers(data.id);
            if (response.status === 200) {
                // console.log('reloadest', response)

                return {status: response.status, data: [response.data, data.credentials]}
            } else if (response.status ===
                422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
//remove