import User, {IUser} from "./user";

interface IUserWithLtv extends IUser {
    ltv: string;
}

class LTV {

    public user: User;
    public ltv: string;

    constructor(data: IUserWithLtv) {
        this.user = new User(data);
        this.ltv = data.ltv;
    }

}

export default LTV;