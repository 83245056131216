import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import CartRequests from "../../../requests/cartRequests";
import store from "../../store";
import PaymentStatusRequests from "../../../requests/paymentStatusRequests";
// import {SearchContactCredentials} from "../contacts/contactsAction";

interface CheckDiscountCodeResponse extends DefaultResponse {
    code: string
}

export type CartResponse = DefaultResponse & {
    id: number
}

export interface SearchOrdersCredentials {
    title: string | null,
    mobilenumber: string | null,
    minPrice: string | null,
    maxPrice: string | null,
    trackCode: string | null,
    status: string | null,
    purchaseDate: string | null,
    purchaseEndDate: string | null,
}
export const getOrderCart = createAsyncThunk(
    'ordersCart/getAll',
    async (credentials?: SearchOrdersCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CartRequests.getOrderCart(credentials ?? {} as SearchOrdersCredentials, store.getState().order.paginate)
            if (response.status === 200) {
                return {status: response.status, data: [response.data, credentials]}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
export const changePaymentStatus = createAsyncThunk(
    'ordersCart/update',
    async (credentials: { statuses: string, id: number }): Promise<DefaultResponse> => {
        try {

            const response: AxiosResponse = await PaymentStatusRequests.changeStatus(credentials)
            // const response: AxiosResponse = await PaymentStatusRequests.changeStatus(credentials)
            //
            if (response.status === 200 || response.status === 201) {
                // console.log('errorValidation', response.data)
                // const data = response.data;
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                }
            }
            return {
                status: 200,
                data: null,
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null,
            }
        }
    }
)




