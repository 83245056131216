import {LoadingTableShimmer} from "../../../components/basincComponents";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import userAvata from "../../../assets/img/user_avatar.png";
import {NetworkImage} from "../../../utils/funcions";

export const EightUser = () => {
    const {mainUsers} = useAppSelector((state: RootState) => state.eightUserAction);
    return <div>
        <div className={'lg:flex  hidden max-w-sm '}>
            {
                <div className={`h-full mt-8 fixed overflow-y-auto overflow-x-hidden`}>

                    <div className={'flex items-start'}>
                        <span>کاربران فعال</span>
                    </div>
                    <div className={'flex flex-col left-22 top-6 mb-8'}>
                        {

                                mainUsers.slice(0, 8).map((e, value) => {
                                    return (
                                        <div className={'flex items-start mt-4'}>
                                            {e.avatar == null || e.avatar == undefined ? (
                                                <img
                                                    className="w-[50px] h-[50px] rounded-full"
                                                    src={userAvata}
                                                    alt=""
                                                />
                                            ) : (
                                                <NetworkImage
                                                    className={'w-[50px] h-[50px] object-contain rounded-full'}
                                                    url={e.avatar}
                                                    alt=""
                                                />
                                            )}
                                            <div className={'flex flex-col justify-start mr-2'}>
                        <span className={'text-[14px] '}>
                            {e.f_name ?? "کاربر"}
                        </span>
                                                <span className={'text-[12px] mt-1 w-32'}>
                            {e.description != null ?
                                e.description.length > 18 ?
                                    e.description.substring(0, 18) + '...'
                                    : e.description
                                : "بیوگرافی ندارد."}
                        </span>
                                            </div>
                                        </div>
                                    );
                                })

                        }

                    </div>
                    {
                        mainUsers.length - 8 <= 0 ? '' : <span className={'text-[#4192EF] pr-1'}>
                          و {mainUsers.length - 8} کاربر دیگر...

                    </span>
                    }
                </div>
            }
        </div>
    </div>


}
