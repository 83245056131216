import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { useAppSelector } from 'redux/hooks';
import { remove, update, submit } from 'redux/dashboard/contactsAndCustomers/contactsAndCustomersAction';
import { DeleteModal } from '../../../components/modal';
import { DefaultResponse } from '../../../redux/mainSlice';
import ContactAndCustomer from '../../../models/contactCustomer';
import { DefaultTextArea } from '../../../components/fieilds';
import {LoadingTableShimmer} from "../../../components/basincComponents";

const ContactsAndCustomersList = () => {
    const { contactsAndCustomers, loading, submitLoading } = useAppSelector((state: RootState) => state.contactsAndCustomers);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userSelectedIds, setUserSelectedIds] = useState<number[]>([]);
    const [text, setText] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<ContactAndCustomer | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [smsText, setSmsText] = useState('');
    const [numPages, setNumPages] = useState(1);
    const {shop} = useSelector((state: RootState) => state.user);

    const handleDeleteClick = (contactAndCustomer: ContactAndCustomer) => {
        setSelectedItem(contactAndCustomer);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201 && result.status !== 204) {
                setDeletedId(0);
            }
        }
    };

    const sendData = () => {
        dispatch(submit({
            user_ids: userSelectedIds,
            message: text,
            price: (numPages * 160) * userSelectedIds.length
        }));
    }

    const handleCheckboxChange = (id: number, isChecked: boolean) => {
        setUserSelectedIds((prevSelectedIds) => {
            if (isChecked) {
                return [...prevSelectedIds, id];
            } else {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            }
        });
    };

    const handleTextChange = (e: string) => {
        setSmsText(e);
        countCharacters(e);
    }

    const countCharacters = (text: string) => {
        let numChars = text.length;
        let numPages = 1;

        if (numChars <= 70) {
            numPages = 1;
        } else if (numChars <= 134) {
            numPages = 2;
        } else if (numChars <= 201) {
            numPages = 3;
        } else {
            numPages = Math.ceil(numChars / 67);
        }

        setNumPages(numPages);
    };

    const [animate, setAnimate] = useState(false);

    const handleClick = () => {
        setAnimate(!animate);
    };


    return (
        <div className="flex-auto px-0 pt-0 pb-2">
            <div className="p-0 overflow-x-auto ps px-4">
                <div className={'lg:flex-row mt-14 overflow-x-auto justify-between mb-4 items-center flex flex-col w-full px-4'}>
                    <ul className="timeline">
                        <li>
                            <div className="timeline-start">160 تومان</div>
                            <div className="timeline-middle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="timeline-end timeline-box">به ازای هر نفر</div>
                            <hr/>
                        </li>
                        <li>
                            <hr/>
                            <div className="timeline-start">67 کاراکتر</div>
                            <div className="timeline-middle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="timeline-end timeline-box">هر صفحه پیام</div>
                            <hr/>
                        </li>
                        <li>
                            <hr/>
                            <div className="timeline-start">160 تومان</div>
                            <div className="timeline-middle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="timeline-end timeline-box">قیمت صفحات پیام</div>
                        </li>
                    </ul>


                    <ul className="timeline">
                        <li>
                            <div className="timeline-start">{(numPages * 160) * userSelectedIds.length}</div>
                            <div className="timeline-middle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="timeline-end timeline-box">هزینه ارسال پیامک + (مالیات)</div>
                            <hr/>
                        </li>
                        {/*<li>*/}
                        {/*    <hr/>*/}
                        {/*    <div className="timeline-start">{numPages}</div>*/}
                        {/*    <div className="timeline-middle">*/}
                        {/*        <svg*/}
                        {/*            xmlns="http://www.w3.org/2000/svg"*/}
                        {/*            viewBox="0 0 20 20"*/}
                        {/*            fill="currentColor"*/}
                        {/*            className="h-5 w-5">*/}
                        {/*            <path*/}
                        {/*                fillRule="evenodd"*/}
                        {/*                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"*/}
                        {/*                clipRule="evenodd"/>*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*    <div className="timeline-end timeline-box">تعداد صفحات</div>*/}
                        {/*    <hr/>*/}
                        {/*</li>*/}

                        <li>
                            <hr/>
                            <div className="timeline-start">{numPages}</div>
                            <div className="timeline-middle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="timeline-end timeline-box">تعداد صفحات</div>
                        </li>
                    </ul>
                    {/*<ol>*/}
                    {/*    <li>هزینه ارسال پیامک : {(numPages * 160) * userSelectedIds.length} تومان</li>*/}
                    {/*    <li>تعداد صفحات: {numPages}</li>*/}
                    {/*</ol>*/}
                </div>
                <div className="flex w-full px-5 justify-between items-center mt-2 resize-none">
                    <textarea
                        value={text}
                        onChange={(e) => {
                            handleTextChange(e.target.value);
                            setText(e.target.value);
                        }}
                        className="textarea textarea-bordered h-3 max-h-36 w-full"
                        placeholder="پیام خود را وارد کنید"
                    ></textarea>
                    {
                        shop!.id === 111 ? <button
                            // onClick={() => sendData()}
                            type='submit'
                            className={`btn mr-2 cursor-not-allowed text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:text-[#4192EF] hover:shadow-soft-xs active:opacity-85"}`}
                        >
                            ارسال
                        </button> : <button
                            onClick={() => sendData()}
                            type='submit'
                            className={`btn mr-2 text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:text-[#4192EF] hover:shadow-soft-xs active:opacity-85"}`}
                        >
                            ارسال
                        </button>
                    }
                </div>

                <div className={`p-0 md:px-6 overflow-x-auto ps`}>
                    {loading ? <div className={'flex justify-center items-center w-full'}>
                            <LoadingTableShimmer/>
                        </div>
                        : (<table
                            className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                            <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">

                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            checked={userSelectedIds.length === contactsAndCustomers.length} // اگر تمامی موارد انتخاب شده باشند، چک باکس اصلی هم انتخاب شود
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setUserSelectedIds(checked ? contactsAndCustomers.map(contact => contact.id) : []);
                                            }}
                                        />
                                        <span className="custom-checkbox"></span>
                                        </label>

                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                                    و نام خانوادگی
                                </th>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                                    موبایل
                                </th>
                            </tr>
                            </thead>
                            <tbody className={''}>
                            {contactsAndCustomers.map((contact, i) => (
                                <tr key={contact.id.toString()}
                                    className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} ${deletedId === contact.id ? '-translate-x-96 hidetr' : ''}`}>
                                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                        <label className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                checked={userSelectedIds.includes(contact.id)}
                                                onChange={(e) => handleCheckboxChange(contact.id, e.target.checked)}
                                            />
                                            <span className="custom-checkbox"></span>
                                        </label>
                                    </td>
                                    <td className="px-6 py-3 font-semibold text-start  align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                        { contact.f_name + ' ' + contact.l_name }
                                    </td>
                                    <td className="px-6 py-3 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{contact.mobile_number}</td>

                                </tr>
                            ))}
                            {/*{contacts.map((contact, i) => (*/}
                            {/*//     <tr key={contact.id.toString()}*/}
                            {/*//         className={`transition-all ${deletedId === contact.id ? '-translate-x-96 hidetr' : ''}`}>*/}
                            {/*//         <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{i + 1}</td>*/}
                            {/*//         <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{contact.f_name + ' ' + contact.l_name}</td>*/}
                            {/*//         <td className="px-6 py-3 font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70">*/}
                            {/*//             <label*/}
                            {/*//                 onClick={() => setEditingItem(contact)}*/}
                            {/*//                 className="inline-block px-4 py-3 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-150 hover:scale-102 active:opacity-85 bg-x-25 text-slate-700"> ویرایش </label>*/}
                            {/*//             <label*/}
                            {/*//                 onClick={() => handleDeleteClick(contact)}*/}
                            {/*//                 className="text-[#DE0046] text-sm mt-2 cursor-pointer"> حذف </label>*/}
                            {/*//*/}
                            {/*//         </td>*/}
                            {/*//     </tr>*/}
                            {/*// ))}*/}

                            </tbody>
                        </table>)}
                    <div className="ps__rail-x">
                        <div className="ps__thumb-x"></div>
                    </div>
                    <div className="ps__rail-y">
                        <div className="ps__thumb-y"></div>
                    </div>
                </div>

                {/*<table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">*/}
                {/*    <thead className="align-bottom">*/}
                {/*    <tr>*/}
                {/*        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>*/}
                {/*        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>*/}
                {/*        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام</th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    {loading ? "" : (*/}
                {/*        <tbody>*/}
                {/*        {contactsAndCustomers.map((contactAndCustomer, i) => (*/}
                {/*            contactAndCustomer.id && (*/}
                {/*                <tr*/}
                {/*                    key={contactAndCustomer.id.toString()}*/}
                {/*                    className={`transition-all ${deletedId === contactAndCustomer.id ? '-translate-x-96 hidetr' : ''}`}*/}
                {/*                >*/}
                {/*                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">*/}
                {/*                        <input*/}
                {/*                            type="checkbox"*/}
                {/*                            checked={userSelectedIds.includes(contactAndCustomer.id)}*/}
                {/*                            onChange={(e) => handleCheckboxChange(contactAndCustomer.id, e.target.checked)}*/}
                {/*                        />*/}
                {/*                    </td>*/}
                {/*                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></td>*/}
                {/*                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">*/}
                {/*                        {contactAndCustomer.f_name + ' ' + contactAndCustomer.l_name}*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*            )*/}
                {/*        ))}*/}
                {/*        </tbody>*/}
                {/*    )}*/}
                {/*</table>*/}
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>
        </div>
    );
};

export default ContactsAndCustomersList;
