import QuestionAnswer from "./questionAnswer";

export interface QuestionInterface {
    id: number;
    form_question_id: number;
    title: string,
    answers?: QuestionAnswer[],
    type: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";
}

class Question {

    id: number;
    formQuestionId: number;
    title: string;
    answers?: QuestionAnswer[];
    type: 'text' | 'number' | 'date' | 'file' | 'textarea' | 'email' | "boolean";

    constructor(data: QuestionInterface) {
        this.id = data.id;
        this.answers = data.answers;
        this.formQuestionId = data.form_question_id;
        this.type = data.type;
        this.title = data.title;
    }

}

export default Question;