import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ResellerRequests from "../../../requests/resellerRequests";
import CartRequests from "../../../requests/cartRequests";
import {CartCheckoutCredentials, CartCheckoutResponse} from "../cart/cartAction";


export const getChart = createAsyncThunk(
    'chart/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.getAllCountResellers()
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)