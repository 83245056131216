import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { submit } from '../../../redux/dashboard/messenger/chatAction';
import { useAppSelector } from '../../../redux/hooks';
import { useParams } from 'react-router-dom';
import prof from '../../../assets/img/prof.webp';
import Routes from '../../../utils/routing/routes';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from "@reduxjs/toolkit";
import {getSettingByKey, NetworkImage, toastSuccess} from "../../../utils/funcions";
import { LoadingTableShimmer } from "../../../components/basincComponents"; // Adjust path
import userAvata from "../../../assets/img/user_avatar.png";
import { IconArrowRight } from "@tabler/icons-react";
import localToken from "../../../services/savedData/localToken";
import moment from "jalali-moment";

const ChatForm = () => {
    const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
    const handleChatClick = (chatId: number) => {
        setSelectedChatId(chatId);
    };

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { user, shop, settings } = useAppSelector((state: RootState) => state.user);
    const { id } = useParams(); // Retrieve 'id' from URL params
    const [receiverId, setReceiverId] = useState<number | null>(null);
    const [message, setMessage] = useState('');
    const { chats, submitLoading } = useAppSelector((state: RootState) => state.chat);
    const { users, loading } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        if (loading === false && users.length > 0) {
            handleChatClick(selectedChatId ?? users[0].message_id);
        }
    }, [loading]);

    useEffect(() => {
        if (id) {
            const parsedId = parseInt(id, 10);
            setReceiverId(parsedId);
        }
    }, [id]);

    const lastSendTimeRef = useRef(Date.now());
    const chatContainerRef = useRef<HTMLDivElement>(null); // Ref for the chat container

    // console.log('localToken', localToken.get())

    const handleSendMessage = async () => {
        const currentTime = Date.now();
        if (!receiverId || !message) {
            return;
        }
        if (currentTime - lastSendTimeRef.current >= 1000) {
            const data = {
                message: message,
                receiver_id: receiverId,
            };

            try {
                // @ts-ignore
                await dispatch(submit(data));
                setMessage(''); // Clear message after sending
                setTimeout(() => {
                    if (chatContainerRef.current) {
                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    }
                }, 100); // Add a slight delay to ensure the message is rendered before scrolling
                lastSendTimeRef.current = currentTime; // Update last send time
            } catch (error) {
                alert('مشکلی پیش آمد.' + error);
            }
        } else {
            alert('لطفاً هر 1 ثانیه یکبار پیام ارسال کنید.');
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chats]);

    const text = settings.find(e => e.name === 'msg_text')?.value || ''; // Provide a default value if not found
    const textRef = useRef<HTMLSpanElement>(null); // Initialize ref with null

    const animateText = React.useCallback(() => {
        if (!text) {
            console.error("Text is undefined");
            return;
        }

        let textIndex = 0;
        const intervalId = setInterval(() => {
            if (textRef.current) {
                if (textIndex < text.length) {
                    textRef.current.textContent = text.substring(0, textIndex + 1);
                    textIndex++;
                } else {
                    clearInterval(intervalId); // Stop animation when complete
                }
            } else {
                console.error("Element with ref 'textRef' not found!");
                clearInterval(intervalId);
            }
        }, 2000 / 30);

        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [text]);

    useEffect(() => {
        const cleanup = animateText();
        return cleanup;
    }, [animateText]);


    //
    return (
        <div className="flex flex-col h-screen overflow-x-hidden w-full md:max-w-4xl">
            {
                users[0] && users.map(e => e.reciever) ? <Link to={user!.id === shop!.id ? Routes.users.path : Routes.products.path}
                                 className="cursor-pointer mr-4 fixed top-0 z-[40] py-3 bg-white w-full items-center md:hidden flex">
                    <span><IconArrowRight/></span>

                    <span>
  {/*<NetworkImage*/}
  {/*    url={*/}
  {/*        users[2].reciever?.avatar ?? prof*/}
  {/*    }*/}
  {/*    alt={''}*/}
  {/*    className={'flex w-[38px] h-[38px] mr-1 object-cover rounded-full'}*/}
  {/*/>*/}
</span>

                    <span className="mr-2">{users[0].reciever?.f_name ? users[0].reciever?.f_name + ' ' + users[0].reciever?.l_name  :  'ناشناس'}</span>
                </Link> : <Link to={user!.id === shop!.id ? Routes.users.path : Routes.products.path}
                                className="cursor-pointer mr-4 fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex">
                    <span><IconArrowRight/></span>
                    <span className="mr-2">پیام</span>
                </Link>
            }
            {id === undefined ? (
                ''
            ) : (
                <div className="flex w-full overflow-x-hidden">
                    {user!.id === shop!.id ? (
                        <div className="h-full md:block overflow-x-hidden hidden border-l border-l-1 border-gray-100 overflow-y-auto mt-12 fixed">
                            {loading ? (
                                <LoadingTableShimmer />
                            ) : (
                                users.map((e) =>
                                    e.message_id === null ? (
                                        ''
                                    ) : (
                                        <Link
                                            key={e.message_id}
                                            to={Routes.chat(parseInt(e.message_id.toString())).path}
                                            className={`py-2 px-4 rounded-lg flex hover:bg-[#4192EF33] items-start mt-2 ${selectedChatId === e!.message_id ? 'bg-[#4192EF33] text-[#4192EF]' : ''}`}
                                            onClick={() => handleChatClick(e!.message_id)}
                                        >
                                            <img className="w-[40px] h-[40px] rounded-full" src={prof} alt="" />
                                            <div className="flex flex-col justify-start mr-2">
                                                <span
                                                    className="text-[14px]">{e!.sender === null ? "کاربر ناشناس" : e!.sender!.f_name}</span>
                                                <span className="text-[12px] mt-1 w-28"></span>

                                                <span
                                                    className={'text-[14px] '}>{e!.sender == null ? "شماره تماس: " : e!.sender!.mobile_number}</span>
                                            </div>
                                        </Link>
                                    )
                                )
                            )}
                            <div className="h-20 w-2"></div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="flex flex-col w-full">
                        <div
                            className={user!.id === shop!.id ? `lg:w-5/6 md:w-4/6 w-full md:mr-48 mr-0` : `w-full mr-0`}>
                            <div className="mb-10 py-10 w-full overflow-y-auto" id="chat-container"
                                 ref={chatContainerRef}>
                                {user?.id === shop?.id ? (
                                    <div className="chat chat-start w-full flex justify-start px-4 mt-6 ">
                                        <div className="chat-image avatar">
                                            <div className="w-10 rounded-full">
                                                <NetworkImage alt="" url={shop?.avatar ?? userAvata}/>
                                            </div>
                                        </div>
                                        <div className="chat-bubble bg-[#4192EF33] text-[#4192EF]">
                                            <span
                                                ref={textRef}>{text == '' ? 'سلام چطور میتونم کمکتون کنم' : text}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="chat chat-end w-full flex justify-end px-4 mt-6 ">
                                        <div className="chat-bubble bg-[#00FF0033] text-green-500">
                                            <span
                                                ref={textRef}>{text == '' ? 'سلام چطور میتونم کمکتون کنم' : text}</span>
                                        </div>
                                        <div className="chat-image avatar">
                                            <div className="w-10 mr-2 rounded-full">
                                                <NetworkImage alt="" url={shop?.avatar ?? userAvata}/>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {chats.map((e) => {
                                    return e.sender_id == user!.id || e.is_me ? (
                                        <div className="chat chat-start w-full flex justify-start px-4 mt-2" key={e.id}>
                                            <div
                                                className="chat-bubble before:none content-none before:content-none bg-[#4192EF33] text-[#4192EF] flex flex-col items-start">
                                                <div>
                                                    {e.message}
                                                </div>
                                                <div className={'text-sm'}>
                                                    {moment(e.created_at!, 'YYYY/MM/DD/HH/mm').locale('fa').format('HH:mm')}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full chat-end pl-4 flex justify-end mt-2" key={e.id}>
                                            <div className="chat-bubble ml-0 md:left-10 before:none content-none before:content-none bg-[#00FF0033] text-green-500 flex flex-col">
                                                <div>
                                                    {e.message}
                                                </div>
                                                <div className={'text-sm'}>
                                                    {moment(e.created_at!, 'YYYY/MM/DD/HH/mm').locale('fa').format('HH:mm')}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <footer className="fixed bottom-0 w-full md:max-w-4xl">
                                <form
                                    className="bg-white py-2 px-4 flex items-center border-t border-gray-200 w-full rtl">
                                    <label className="sr-only">پیام شما</label>
                                    <div
                                        className="flex items-center w-full py-2 pr-3 pl-1 bg-gray-50 rounded-xl shadow-md">
                                        <div
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    handleSendMessage();
                                                }
                                            }}
                                            onClick={() => handleSendMessage()}
                                            className="md:inline-flex hidden justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 transition-colors duration-200 ml-2"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="w-6 h-6" width="44" height="44"
                                                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M10 14l11 -11"/>
                                                <path
                                                    d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"/>
                                            </svg>
                                            {/*<svg className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20"*/}
                                            {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*    <path*/}
                                            {/*        d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>*/}
                                            {/*</svg>*/}
                                        </div>
                                        <textarea
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    handleSendMessage();
                                                }
                                            }}
                                            id="message"
                                            name="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="block max-h-32 p-2 w-full text-sm text-gray-900 bg-white rounded-full border-none focus:ring-0 focus:outline-none resize-none overflow-hidden rtl"
                                            placeholder="متن پیام ..."
                                            rows={1}
                                            style={{lineHeight: '1.5', height: 'auto', minHeight: '2.5rem'}}
                                        />
                                        <div
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    handleSendMessage();
                                                }
                                            }}
                                            onClick={() => handleSendMessage()}
                                            className="inline-flex md:hidden justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 transition-colors duration-200 ml-2"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="w-6 h-6 rotate-[-135deg]" width="44" height="44"
                                                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M10 14l11 -11"/>
                                                <path
                                                    d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"/>
                                            </svg>
                                            {/*<svg className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20"*/}
                                            {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*    <path*/}
                                            {/*        d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>*/}
                                            {/*</svg>*/}
                                        </div>
                                    </div>
                                </form>
                            </footer>


                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatForm;
