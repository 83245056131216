import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {getSettingByKey, NetworkImage} from "../../utils/funcions";
import {Link} from "react-router-dom";
import Routes from "../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";

export const AboutUs: React.FC = () => {

    const {shop, loading, settings} = useSelector((state: RootState) => state.user);
    // const {user, users} = useSelector((state: RootState) => state.user);

    return (
        loading ? '' :
            <section className="items-center flex-col bg-white mt-4 mb-10 font-poppins dark:bg-gray-800 w-full">

                <Link to={Routes.products.path}
                      className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={'mr-2'}>درباره ما</span>
                </Link>
                <div className="justify-center flex-1 max-w-6xl py-3 mx-auto mt-16 lg:py-6 md:px-6 flex w-full">
                    <div className="flex flex-wrap w-full   ">
                        <div className="w-full mb-10 lg:w-1/2 lg:mb-0 flex justify-center">
                            <div className="relative lg:max-w-md">
                                <NetworkImage
                                    url={shop!.avatar}
                                    alt="aboutimage"
                                    className="relative object-cover bg-transparent md:w-full w-[90px] h-[90px] md:rounded-none rounded-full md:h-96"
                                />
                            </div>
                        </div>
                        <div className="w-full px-6 mb-24 lg:w-1/2 lg:mb-0 ">
                            <div className="md:pl-4 pl-0 mb-6 flex flex-col justify-center items-center">
          <span className="text-md font-bold text-gray-600 uppercase dark:text-gray-400 ">
            {settings.find(e => e.name === 'shop_name')?.value}
          </span>
                            </div>
                            <p className="mb-4 text-base leading-7 text-gray-500 dark:text-gray-400">
                                {settings.find(e => e.name === 'about_us')?.value}
                            </p>
                            <hr className="mb-3"></hr>
                            <div>
                                <div>
                                    <p className="text-base leading-7 text-gray-500 dark:text-gray-400 text-xs">نشانی</p>
                                    <p className="text-sm">{settings.find(e => e.name === 'address')?.value ?? ''}</p>
                                </div>

                                <hr className="mb-3 mt-3"></hr>


                                <div>
                                    <p className="text-base leading-7 text-gray-500 dark:text-gray-400 text-xs">شماره
                                        تلفن</p>
                                    <p className="text-sm">{settings.find(e => e.name === 'phone_number')?.value ?? ''}</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
    );
}