import {createSlice, current} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import Cart from "../../../models/cart";

export var cartItems: any = null;

interface State extends DefaultStates {
    cart: Cart|null;
    submitLoading: boolean;
}

const initialState: State = {
    loading: false,
    submitLoading: false,
    validationErrors: null,
    cart: null,
};

const CartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
    },
});

export default CartSlice.reducer;