import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Contact from "../../../models/contact";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getAll, remove, submit, update} from "redux/dashboard/contacts/contactsAction";

interface State extends DefaultStates {
    contacts: Contact[];
    contactsList: Contact[][];
    paginate: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    contacts: [],
    contactsList: [],
    submitLoading: false,
    paginate: 1,
    loading: false,
    isEnd: false,
    loadingMore: false,
    validationErrors: null,
};

const ContactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1;
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;

            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                const newContact = new Contact({ ...data, parentId: data.parent_id });
                state.contactsList.map((e) => e.unshift(newContact))
                toastSuccess('مخاطب با موفقیت اضافه شد');
            }else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });

        // builder.addCase(submit.fulfilled, (state, action) => {
        //     state.submitLoading = false;
        //     const response = action.payload;
        //     if (response.status === 200 || response.status === 201) {
        //         const data = response.data.data;
        //         state.contacts.unshift(new Contact({...data, parentId: data.parent_id}));
        //         toastSuccess('مخاطب با موفقیت اضافه شد');
        //     } else if (response.status === 422) {
        //         state.validationErrors = response.data as LaravelValidationErrorType;
        //     }
        // })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Contact[] = [];
                const title = response.data[1]
                for (let i = 0; i < response.data[0].length; i++) {
                    const customer = response.data[0][i];
                    contacts.push(new Contact({
                        id: customer.id,
                        f_name: customer.f_name,
                        username: customer.username,
                        mobile_number: customer.mobile_number,
                        l_name: customer.l_name,
                    }));
                }


                if (response.data[0].length === 0 || response.data[0].length < 30) {
                    state.isEnd = true;
                }
                if (title && state.paginate !== 1 ) {
                    state.contactsList.push(contacts);
                }
                else if (title && (title.title || title.price || title.product || title.category)) {
                    state.contactsList = [contacts];
                } else if (state.paginate === 1) {
                    state.contactsList = [contacts];
                } else {
                    state.contactsList.push(contacts);
                }
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true;
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });
        //remove
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })

        builder.addCase(remove.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;

            if (response.status === 200) {
                state.contactsList.forEach((contactList) => {
                    const index = contactList.findIndex(contact => contact.id === response.data.id);
                    if (index !== -1) {
                        contactList.splice(index, 1);
                    }
                });
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });

        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

        //update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                        const index = state.contacts.findIndex(contact => contact.id === response.data.id);
                        state.contacts[index] = new Contact({
                            id: response.data.id,
                            username: response.data.username,
                            f_name: response.data.f_name,
                            mobile_number: response.data.mobile_number,
                            l_name: response.data.l_name,
                        });
                        toastSuccess('مخاطب با موفقیت ویرایش شد');
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )

    },
});
export const { setPaginate, setPaginateToStart, setAllStatesToTrue } = ContactsSlice.actions;

export default ContactsSlice.reducer;