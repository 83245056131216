import {DefaultStates} from "../../mainSlice";
import {createSlice} from "@reduxjs/toolkit";
import {getAll} from "./analyticsAction";
import {LaravelValidationErrorType} from "../../../utils/types";
import User from "../../../models/user";


interface State {
    show: boolean;
    openSideBar: boolean;
}

const initialState: State = {
    show: false,
    openSideBar: false,
};

const AnalyticsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setShow: (state) => {
            state.show = !state.show;
        },
        setSideBar: (state, action?) => {
            state.openSideBar = action?.payload ?? !state.openSideBar;
        }
    },
});

export const {setShow, setSideBar} = AnalyticsSlice.actions;
export default AnalyticsSlice.reducer;