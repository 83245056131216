import React from "react";

export function LoadingTableShimmer() {
    return (
        <div
            className="animate-pulse text-xl font-medium text-neutral-900 dark:text-white">
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-lg bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-lg bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-lg bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-lg bg-gray-300"></span>
            <div className="divider h-0"></div>
        </div>);
}
export function OneLoadingTableShimmer() {
    return (
        <div
            className="animate-pulse text-xl font-medium text-neutral-900 dark:text-white">
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-lg bg-gray-300"></span>
            <div className="divider h-0"></div>
        </div>);
}