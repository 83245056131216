import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import Auth from "services/savedData/auth";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {goToRoute} from "../../../utils/routing/baseRoutes";
import Routes from "../../../utils/routing/routes";

export interface verifyCodeCredentials {
    mobileNumber: string;
    code: string;
}

export const submit = createAsyncThunk(
    'verifyCode/post',
    async (credentials: verifyCodeCredentials)=> {
        try {
            const response: AxiosResponse = await UserRequests.verifyCode(credentials)
            const data = response.data;
            if (response.status === 200) {
                Auth.set(data);
                //refresh page
                window.location.reload();
                return {
                    status: response.status,
                    data: data
                }
            }else if (response.status === 422) {
                toast422(data as LaravelValidationErrorType);
                return {
                    status: response.status,
                    data: data as LaravelValidationErrorType
                }
            }else {
                toastError(data.message ?? null);
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)