import {createSlice} from "@reduxjs/toolkit";
import {checkOnline, onlineState} from "./checkOnlineAction";


interface State extends onlineState {
    loading: boolean;
    isInternetOnline: boolean;
}

const initialState: State = {
    loading: true,
    isInternetOnline: !navigator || (navigator && navigator.onLine),
    isServerOnline: true,
    status: 0,
};

const CheckOnlineSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setIsInternetOnline(state, action) {
            state.isInternetOnline = action.payload;
        },
        setIsServerOnline(state, action) {
            state.isServerOnline = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(checkOnline.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(checkOnline.fulfilled, (state, action) => {
            state.loading = false;
            state.isServerOnline = action.payload.isServerOnline;
            state.status = action.payload.status;
        })
        builder.addCase(checkOnline.rejected, (state, action) => {
            state.loading = false;
            state.isServerOnline = false;
            state.status = 500;
        })

    },
});

export const {setIsInternetOnline, setIsServerOnline} = CheckOnlineSlice.actions;

export default CheckOnlineSlice.reducer;