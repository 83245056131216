import React from 'react';
import {DefaultInput} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {submit} from "../../../redux/dashboard/plans/plansAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {IconArrowRight} from "@tabler/icons-react";


interface values {
    title: string;
    description: string;
    price: number;
}


const AddPlan = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, plans} = useAppSelector((state: RootState) => state.plans);

    return (
        <DefaultModal
            label={'addPlan'}  id='addPlan' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    price: 0,
                }}
                onSubmit={
                    (values: values, {resetForm, setSubmitting}: FormikHelpers<values>) => {
                        
                        try {
                            dispatch(submit(values));
                            (document.querySelector("[for='addPlan']") as HTMLElement).click();

                            resetForm();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'نام پلن را وارد کنید';
                    }
                    if (!values.description
                    ) {
                        errors.description = 'توضیحات را وارد کنید';
                    }
                    if (!values.price) {
                        errors.price = 'قیمت پلن را وارد کنید';
                    }
                    return errors;
                }}>
                <Form method="dialog">
                    <label htmlFor={'addPlan'}
                           className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                    <div className='flex-col justify-start md:mt-4  mt-12 items-center w-full flex'>

                        <DefaultInput
                            label='نام پلن'
                            name="title"
                            type="text"
                            placeholder='نام پلن'
                        ></DefaultInput>

                        <DefaultInput
                            label='توضیحات'
                            name="description"
                            type="text"
                            placeholder='توضیحات'
                        ></DefaultInput>
                        <DefaultInput
                            label='قیمت'
                            name="price"
                            type="number"
                            placeholder='قیمت'
                        ></DefaultInput>

                    </div>
                    <div className="modal-action w-full flex justify-start">

                        <button type="submit"
                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                submitLoading ? 'صبر کنید' : 'افزودن '
                            }
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addPlan"
                            className="btn text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85 bg-[#DE0046]">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddPlan;
