import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
// import {getAllCountResellers, getAllResellers} from "./resellerAction";
import UserWithPayments from "../../../models/userWithPayments";
import {getChart} from "./resellerChartAction";

interface State extends DefaultStates {
    resellerChart: []|null;
    submitLoading: boolean;
    loading: boolean;
}

const initialState: State = {
    resellerChart: null,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const ResellerChartSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder.addCase(getChart.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(getChart.fulfilled, (state, action) => {

            state.submitLoading = false
            const response = action.payload;

            if (response.status === 200) {
                state.resellerChart = response.data.data
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getChart.rejected, (state, action) => {
            state.submitLoading = true
            }
        )


    },
});

export default ResellerChartSlice.reducer;