import Media from "./media";
import Category from "./category";
import {Shop} from "./shop";
import HighLight from "./highLightedProducts";
import Cart from "./cart";
import User from "./user";

export type paymentStatus = 'pending' | 'paid' | 'cancelled' | 'delivered' | 'returned' | 'refunded' | 'settled';
// Define the mapping object with translations for each cart status
export const paymentStatusMap: Record<paymentStatus, string> = {
    pending: "پرداخت نشده",
    paid: "پرداخت شده",
    cancelled: "لغو شده",
    delivered: "تحویل داده شده",
    returned: "بازگشت داده شده",
    refunded: "بازپرداخت شده",
    settled: "پرداخت شده"
};

export type paymentUserStatus = 'pending' | 'posted' | 'doing' | 'delivered' | 'failed';
// Define the mapping object with translations for each cart status
export const paymentUserStatusMap: Record<paymentUserStatus, string> = {
    pending: "درحال بررسی",
    posted: "ارسال شده",
    doing: "ارسال شده",
    delivered: "تحویل داده شده",
    failed: "لغو شده",
};
interface PaymentInterface {
    id?: number;
    full_name: string;
    mobile_number: string;
    payments_sum_total_price?: number;
    status: paymentStatus;
    user_status: paymentUserStatus;
    description: string;
    result_code: number;
    total_price: number;
    tracking_code?: string;
    final_price: number;
    success_url: string;
    user_ip: string;
    paymentable: Cart,
    created_at?: string,
    user?: User;
    address?: string;
}

class Payment {
    id?: number;
    full_name: string;
    mobile_number: string;
    status: paymentStatus;
    user_status: paymentUserStatus;
    description: string;
    result_code: number;
    total_price: number;
    final_price: number;
    tracking_code?: string;
    success_url: string;
    user_ip: string;
    user?: User;

    address?: string;
    paymentable: Cart;
    created_at?: string
    username?: string;
    payments_sum_total_price?: number;

    constructor(data: PaymentInterface) {
        this.id = data.id;
        this.full_name = data.full_name;
        this.address = data.address;
        this.mobile_number = data.mobile_number;
        this.payments_sum_total_price = data.payments_sum_total_price;
        this.status = data.status;
        this.user_status = data.user_status;
        this.description = data.description;
        this.tracking_code = data.tracking_code;
        this.result_code = data.result_code;
        this.total_price = data.total_price;
        this.final_price = data.final_price;
        this.success_url = data.success_url;
        this.user_ip = data.user_ip;
        this.user = data.user;
        this.paymentable = data.paymentable;
        this.created_at = data.created_at;
    }

}

export default Payment;