import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllReferralCheckouts, getAllResellersChart} from "./referrallAction";
import Referrall from "../../../models/referrall";
import ReferralCheckouts from "../../../models/referralCheckouts";
import Product from "../../../models/product";

interface State extends DefaultStates {
    referrall: Referrall|null;
    referrallcount: number;
    referrallCheckouts: ReferralCheckouts[];
    // referral: number;
    submitLoading: boolean;
}

const initialState: State = {
    referrallcount: 0,
    referrallCheckouts: [],
    referrall: null,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const ReferrallSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllResellersChart.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllResellersChart.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {

                const resellerPayments = response.data;
                // @ts-ignore
                state.referrallcount = resellerPayments
            } else if (response.status === 422) {
                
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllResellersChart.rejected, (state, action) => {

                state.loading = false;
            }
        )


        builder.addCase(getAllReferralCheckouts.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllReferralCheckouts.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                console.log('console.log', response)

                const products: ReferralCheckouts[] = [];
                for (let i = 0; i < response.data.length; i++) {

                    const product = response.data[i];
                    products.push(
                        new ReferralCheckouts({
                            id: product.id,
                            referral_inv_count: product.referral_inv_count,
                            shop_count: product.shop_count,
                            user: product.user,
                            // property_id: product.property_id,
                        }
                        )
                    )
                }

                state.referrallCheckouts = products
            } else if (response.status === 422) {

                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllReferralCheckouts.rejected, (state, action) => {

                state.loading = false;
            }
        )


    },
});

export default ReferrallSlice.reducer;