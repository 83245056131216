import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ResellerRequests from "../../../requests/resellerRequests";
import {CartCheckoutResponse} from "../cart/cartAction";
import store from "../../store";

// const {user, shop} = store.getState().user;

export interface SearchResellerCredentials {
    title?: string | null;
    minPrice?: string | null;
    maxPrice?: string | null;
}

export const getAllResellers = createAsyncThunk(
    'resellers/getAll',
    async (credentials?: SearchResellerCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.getAllResellers(credentials ?? {} as SearchResellerCredentials, store.getState().reseller.paginate)
            if (response.status === 200) {
                return  {status: response.status, data: [response.data, credentials]}
            }else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
export const checkoutResellerCart =  async (): Promise<CartCheckoutResponse> => {
    try {
        const response: AxiosResponse = await ResellerRequests.checkout()
        if (response.status === 200 || response.status === 201 || response.status === 202) {
            toastSuccess('درخواست تسویه حساب ارسال شد')
            return {status: response.status, data: response.data}
        } else if (response.status === 422) {
            
            const errors = await response.data as LaravelValidationErrorType;
            toast422(errors);
            return {
                status: response.status,
                data: response.data
            }
        }
        toastError();
        return {
            status: response.status,
            data: response.data
        }
    } catch (e) {
        toastError();
        return {
            status: 500,
            data: {}
        }
    }
}

export const getAllCountResellers = createAsyncThunk(
    'resellers/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.getAllCountResellers()
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
