import PlansList from "./list";
import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import AddCategoryModal from "../categories/add";
import AddPlan from "./add";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";


function PlansIndex() {

    const {shop, user} = useSelector((state: RootState) => state.user);

    const [showDropDown, setDropDown] = useState(false);

    const location = useLocation();
    return (
        <>
            {
                user!.id === shop!.id ? <div className={'flex w-full items-center md:pr-0 pr-4 justify-between lg:pl-28 md:mt-16  mt-16  md:pl-16 pl-3'}>
                    <span>تعرفه ها</span>
                    <label htmlFor="addPlan"
                           className="btn flex bg-[#4192EF] hover:text-[#4192EF] w-max border-none p-3 rounded-xl text-[15px] text-white">
                        افزودن
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="icon icon-tabler icon-tabler-plus" width="24"
                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                             fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 5l0 14"/>
                            <path d="M5 12l14 0"/>
                        </svg>
                    </label>

                    {/*add post modal*/}

                    <AddPlan/>
                </div> : <div></div>
            }

            <PlansList></PlansList>
        </>
    );
}

export default PlansIndex;