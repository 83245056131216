import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAll} from "redux/dashboard/payment/paymentAction";
import Payment from "../../../models/payment";

interface State extends DefaultStates {
    payment: Payment[];
    submitLoading: boolean;
}

const initialState: State = {
    payment: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const Paymentslice = createSlice({
    name: 'payment',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const contacts: Payment[] = [];
                for (let i = 0; i < response.data.users.length; i++) {
                    const contact = response.data.users[i];
                    contacts.push(new Payment({
                        full_name: contact.full_name,
                        mobile_number: contact.mobile_number,
                        status: contact.status,
                        description: contact.description,
                        result_code: contact.result_code,
                        total_price: contact.total_price,
                        final_price: contact.final_price,
                        success_url: contact.success_url,
                        user_ip: contact.user_ip,
                        paymentable: contact.paymentable,
                        created_at: contact.created_at,
                        id: contact.id,
                        user_status: contact.user_status
                    }));
                }
                state.payment = contacts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }

        )


    },
});

export default Paymentslice.reducer;