import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {copyToClipboard, getSettingByKey, NetworkImage} from "../../utils/funcions";
import {AiFillInstagram, AiFillLinkedin} from "react-icons/ai";
import {BsTelegram} from "react-icons/bs";
import {BiLogoLinkedin} from "react-icons/bi";
import {Link} from "react-router-dom";
import Routes from "../../utils/routing/routes";
import {
    IconArrowRight,
    IconBrandInstagram,
    IconBrandLinkedin,
    IconBrandTelegram,
    IconBrandTwitter, IconHome, IconPhoneCall
} from "@tabler/icons-react";
import TehranMap from "../../assets/img/Map_of_Tehran.png";
import dLoad from "../../assets/img/cantLoadImage.webp";
import {NeshanMap} from "../../redux/components/NeshanMap/NeshanMap";
import {parseNumber} from "react-advanced-cropper";
import {LoadingTableShimmer} from "../../components/basincComponents";

export const ContactUs: React.FC = () => {

    const [copied, setCopied] = useState(false); // وضعیت برای نمایش پیام کپی شدن


    const {shop, loading, settings} = useSelector((state: RootState) => state.user);
    // const {user, users} = useSelector((state: RootState) => state.user);
    const address = settings.find(e => e.name === 'address')?.value;
    const phone = settings.find(e => e.name === 'phone_number')?.value;
    const linkedin = settings.find(e => e.name === 'linkedin_url')?.value;
    const insta = settings.find(e => e.name === 'instagram_url')?.value;
    const rubika = settings.find(e => e.name === 'rubika_url')?.value;
    const bale = settings.find(e => e.name === 'bale_url')?.value;
    const telegram = settings.find(e => e.name === 'telegram_url')?.value;
    return (
        <div>
            <section className="items-center flex-col bg-white mt-4 mb-10 font-poppins dark:bg-gray-800 w-full">


                <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[21] px-2 py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={'mr-2'}>تماس با ما</span>
                </Link>
                {/*<Link to={Routes.products.path} className={'cursor-pointer md:hidden flex mt-6 px-4'}>*/}
                {/*    <span><IconArrowRight/></span>*/}
                {/*    <span className={'mr-2'}>تماس با ما</span>*/}
                {/*</Link>*/}
                <div className="justify-center flex-1 py-3 mt-16 duration-300 mx-auto lg:py-6 flex w-full">
                    <div className="pl-4 lg:flex-row flex flex-col w-full md:justify-between justify-between duration-300">
                        <div className="w-full mb-10 lg:mb-0 flex justify-center md:pr-0 pr-4">
                            <div className="relative w-full min-h-[370px] rounded-2xl">
                                {settings.find(e => e.name === 'lat')?.value ? <NeshanMap
                                    lat={parseNumber(
                                        //@ts-ignore
                                        settings.find(e => e.name === 'lat')?.value)}
                                    lng={parseNumber(
                                        //@ts-ignore
                                        settings.find(e => e.name === 'lng')?.value)}
                                ></NeshanMap> : <NeshanMap
                                ></NeshanMap>}
                            </div>
                        </div>
                        {/*<div className={'md:w-6 md:h-2 w-2 h-0'}>*/}

                        {/*</div>*/}

                        {
                            loading ? <LoadingTableShimmer/> :
                                <div className="flex justify-end w-full duration-300 bg-white">


                                    <div className={'w-full flex flex-col rounded-3xl'}>
                                        <div
                                            className={'w-full rounded-l-3xl items-start md:flex-row flex flex-col md:mpr-0 pr-2 py-10 bg-white shadow-xl'}>
                                            <div className={'flex flex-col justify-evenly w-full md:pr-6'}>
                                                <div onClick={() => copyToClipboard(insta!)} className={'cursor-pointer flex rounded-full items-start'}>
                                                    <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-gradient-to-bl hover:border-pink-300 hover:from-[#6228D7] hover:via-[#EE2A7B] hover:to-[#F9CE34] border border-1 duration-300 text-white'}>
                                                        <IconBrandInstagram size={40}/>
                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3 md:w-44 w-full'}>

                                                        <span className={''}>اینستاگرام</span>
                                                        <span
                                                            className={'inline-block break-all'}>
                                                            {insta ?
                                                                (insta.length > 21 ? insta.substring(0, 21) + '' : insta) : ''}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div onClick={() => copyToClipboard(telegram!)} className={'cursor-pointer flex mt-12 rounded-full items-start'}>
                                                    <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-white text-white hover:text-[#4192EF] border border-1'}>
                                                        <IconBrandTelegram size={40}/>

                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3 md:w-44 w-full'}>

                                                        <span>تلگرام</span>
                                                        <span
                                                            className={'inline-block break-all'}
                                                        >{telegram ?
                                                            (telegram.length > 21 ? telegram.substring(0, 21) + '' : telegram) : ''}</span>
                                                    </div>
                                                </div>


                                                {/*<div className={'w-16 h-2'}></div>*/}
                                                <div onClick={() => copyToClipboard(linkedin!)} className={'cursor-pointer flex mt-12 rounded-full items-start'}>
                                                    <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-white text-white hover:text-[#4192EF] border border-1'}>
                                                        <IconBrandLinkedin size={40}/>

                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3'}>

                                                        <span>لینکدین</span>
                                                        <span
                                                            className={'inline-block break-all'}
                                                        >{linkedin ?
                                                            (linkedin.length > 21 ? linkedin.substring(0, 21) + '' : linkedin) : ''}</span>
                                                    </div>
                                                </div>
                                                {/*<a href={''} className={'flex rounded-full items-center'}>*/}
                                                {/*    <div*/}
                                                {/*        className={'p-6 rounded-full hover:bg-[#4192EF] hover:border-[#4192EF] text-white border border-1'}>*/}
                                                {/*    <IconBrandLinkedin size={60}/>*/}

                                                {/*    </div>*/}

                                                {/*    <span className={'mt-4 text-white'}>لینکدین</span>*/}
                                                {/*</a>*/}
                                            </div>
                                            <div className={'flex flex-col justify-evenly w-full md:mt-0 mt-12'}>

                                                <div onClick={() => copyToClipboard(phone!)} className={'cursor-pointer flex rounded-full items-start'}>
                                                    <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-sky-400 border border-1 duration-300 text-white'}>
                                                        <IconPhoneCall size={40}/>

                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3 md:w-44 w-full'}>

                                                        <span>تلفن</span>
                                                        <span
                                                            className={'inline-block'}
                                                        >{phone ?
                                                            (phone.length > 21 ? phone.substring(0, 21) + '' : phone) : ''}</span>
                                                    </div>
                                                </div>

                                                <div onClick={() => copyToClipboard(rubika!)} className={'cursor-pointer flex rounded-full items-start mt-12'}>
                                                    <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-gradient-to-bl hover:from-[#6228D7] hover:via-[#EE2A7B] hover:to-[#F9CE34] border border-1 duration-300 text-white'}>
                                                        <svg version="1.1" fill={'white'} id="Capa_1"
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             className={'text-white hover:text-[#4192EF]'}
                                                             xmlnsXlink="http://www.w3.org/1999/xlink"
                                                             width="40px" height="40px" viewBox="0 0 412.287 412.287">
                                                            <g>
                                                                <path d="M380.546,110.125L211.552,1.586c-3.293-2.115-7.515-2.115-10.807,0L31.744,110.081c-2.866,1.84-4.6,5.014-4.598,8.42
		l0.041,180.039c0.002,3.476,1.806,6.699,4.767,8.519l168.961,103.75c1.605,0.986,3.419,1.479,5.233,1.479
		c1.814,0,3.629-0.491,5.232-1.479L380.352,307.1c2.96-1.818,4.766-5.043,4.767-8.519l0.023-180.039
		C385.143,115.137,383.219,111.965,380.546,110.125z M206.147,25.627l61.936,39.629l-61.936,39.627l-61.934-39.627L206.147,25.627z
		 M50.197,291.254v-63.938l60.692,36.848l0.01,64.377L50.197,291.254z M110.899,237.623l-60.703-37.285v-64.74l60.703,38.842
		V237.623z M63.22,116.834l59.186-37.869l59.186,37.869l-59.186,37.869L63.22,116.834z M133.908,174.44l60.705-38.844v64.742
		l-60.705,37.285V174.44z M133.938,277.927l21.348,25.815l-21.348,24.783V277.927L133.938,277.927z M194.642,379.952l-50.232-30.854
		l50.232-30.847V379.952z M206.138,297.04l-62.076-38.765l62.076-38.592l62.076,38.733L206.138,297.04z M217.653,379.952v-61.645
		l50.194,30.812L217.653,379.952z M278.358,328.571l-21.388-24.805l21.388-25.842V328.571z M278.388,237.67l-60.703-37.287v-64.736
		l60.703,38.842V237.67z M230.708,116.879l59.186-37.867l59.186,37.867l-59.186,37.869L230.708,116.879z M362.104,291.3
		l-60.705,37.286v-63.17l60.705-37.983V291.3z M362.104,200.383l-60.705,37.287v-63.182l60.705-38.844V200.383z"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3 md:w-44 w-full'}>

                                                        <span>روبیکا</span>
                                                        <span
                                                            className={'inline-block'}
                                                        >{rubika ?
                                                            (rubika.length > 21 ? rubika.substring(0, 21) + '' : rubika) : ''}</span>
                                                    </div>
                                                </div>
                                                <div onClick={() => copyToClipboard(address!)} className={'cursor-pointer flex rounded-full items-start mt-12'}>
                                                <div
                                                        className={'p-3 rounded-xl bg-[#4192EF] hover:bg-sky-300 border border-1 duration-300 text-white'}>
                                                        <IconHome size={40}/>

                                                    </div>
                                                    <div className={'flex flex-col items-start mr-3 md:w-44 w-full'}>

                                                        <span>آدرس</span>
                                                        <span
                                                            className={'inline-block break-all'}
                                                        >{address ?
                                                            (address.length > 40 ? address.substring(0, 40) + '...' : address) : ''}</span>
                                                    </div>
                                                </div>


                                                {/*<div className={'w-16 h-2'}></div>*/}
                                                {/*<a href={`https://`} className={'flex items-center'}>*/}
                                                {/*    <div*/}
                                                {/*        className={'p-6 rounded-full hover:bg-gradient-to-bl hover:border-pink-300 hover:from-[#6228D7] hover:via-[#EE2A7B] hover:to-[#F9CE34] border border-1 duration-300 text-white'}>*/}


                                                {/*    </div>*/}

                                                {/*    <span className={'mt-4 text-white'}>روبیکا</span>*/}
                                                {/*</a>*/}

                                                {/*<a href={''} className={'flex flex-col rounded-full'}>*/}
                                                {/*    <div*/}
                                                {/*        className={'p-6 rounded-full hover:bg-[#4192EF] hover:border-[#4192EF] text-white border border-1'}>*/}
                                                {/*        <IconBrandLinkedin size={60}/>*/}

                                                {/*    </div>*/}
                                                {/*</a>*/}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                        }

                        <div className={'md:w-6 md:h-2 w-2 h-6'}>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}