import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import {parseNumber} from "react-advanced-cropper";
import OrderRequests from "../../../requests/orderRequests";
import PaymentStatusRequests from "../../../requests/paymentStatusRequests";

export const getOrderById = createAsyncThunk(
    'orderPage/get',
    async (data: {[key: string]: any}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await OrderRequests.getOrderById(parseNumber(data.id))
            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {

            return {
                status: 500,
                data: null
            }
        }
    }
)
export const changeAPaymentStatus = createAsyncThunk(
    'orderPage/update',
    async (credentials: { statuses: string, id: number }): Promise<DefaultResponse> => {
        try {

            const response: AxiosResponse = await PaymentStatusRequests.changeStatus(credentials)
            // const response: AxiosResponse = await PaymentStatusRequests.changeStatus(credentials)
            //
            if (response.status === 200 || response.status === 201) {
                // console.log('errorValidation', response.data)
                // const data = response.data;
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                }
            }
            return {
                status: 200,
                data: null,
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null,
            }
        }
    }
)


// export const getOrderById = createAsyncThunk(
//     'orderPage/getOrderById',
//     async (data: ['id']): Promise<DefaultResponse> => {
//         console.log('sdsdfefgdferfdsfd')
//         try {
//             console.log('sdsdfefgdferfdsfd')
//             const response: AxiosResponse = await CartRequests.getOrderById(parseNumber(data.id))
//             if (response.status === 200) {
//                 console.log('sdsdfefgdferfdsfd', response.data)
//                 const {shop} = store.getState().user;
//                 response.data.data = {...response.data, ...{shop: shop}}
//                 return {status: response.status, data: response.data}
//             } else if (response.status === 422) {
//                 console.log('sdsdfefgdferfdsfd')
//                 
//                 const errors = await response.data as LaravelValidationErrorType;
//                 toast422(errors);
//                 return {
//                     status: response.status,
//                     data: errors
//                 }
//             } else {
//                 console.log('sdsdfefgdferfdsfd')
//                 toastError();
//                 return {
//                     status: response.status,
//                     data: null
//                 }
//             }
//         } catch (e) {
//             console.log('sdsdfefgdferfdsfd')
//             console.log('sdsdfefgdferfdsfd')
//             // toastError();
//             return {
//                 status: 500,
//                 data: null
//             }
//         }
//     }
// )
