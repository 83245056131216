import {createAsyncThunk} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../mainSlice";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toast422, toastError} from "../../../utils/funcions";
import AnalyticsRequests from "../../../requests/analyticsRequests";
import Store from "../../store";



export const getAll = createAsyncThunk(
    'analytics/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            if (!Store.getState().analytics.loading) return {status: 200, data: {data: Store.getState().analytics.data}}
            const response: AxiosResponse = await AnalyticsRequests.getAllAnalytics()
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
