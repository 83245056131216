import Category from "./category";
import Product from "./product";

export enum DiscountType {
    PERCENT = "percent",
    FIXED = "fixed",
}

interface ContactAndCustomerInterface {
    id: number,
    f_name: string,
    l_name: string,
    mobile: string,
    mobile_number: string,
}

class ContactAndCustomer {

    id: number;
    f_name: string;
    l_name: string;
    mobile: string;
    mobile_number: string;

    constructor(data: ContactAndCustomerInterface) {
        this.id = data.id;
        this.f_name = data.f_name;
        this.l_name = data.l_name;
        this.mobile = data.mobile;
        this.mobile_number = data.mobile_number;
    }

}

export default ContactAndCustomer;