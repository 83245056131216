import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import DiscountCodeRequests from "../../../requests/discountCodeRequests";
import store from "../../store";

export const getAllDiscountProducts = createAsyncThunk(
    'discountProducts/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await DiscountCodeRequests.getAllDiscountProducts();

            if (response.status === 200) {
                // console.log('errorValidation', response.data)
                return { status: response.status, data: response.data};
            } else if (response.status === 422) {
                const errors = response.data as LaravelValidationErrorType;
                toast422(errors);
                return { status: response.status, data: errors };
            } else {
                toastError();
                return { status: response.status, data: null };
            }
        } catch (e) {
            toastError();
            return { status: 500, data: null };
        }
    }
);
