import {createSlice} from '@reduxjs/toolkit';
import {ReactNode} from "react";

interface ModalState {
    isOpen: boolean;
    content: ReactNode;
    className?: string;
    outerClassName?: string;
    onConfirm?: () => void;
    modalReturnData?: any;
}

//payload is must be contains a css class name and content
export interface ModalPayload {
    content: ReactNode | string;
    onConfirm?: () => void;
    className?: string;
    outerClassName?: string;
    modalReturnData?: any;
}


const initialState: ModalState = {
    isOpen: false,
    content: null,
    className: '',
    outerClassName: '',
    onConfirm: () => {},
    modalReturnData: null,
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: { payload: ModalPayload }) => {
            state.content = action.payload.content;
            state.onConfirm = action.payload.onConfirm;
            state.isOpen = true;
            state.className = action.payload.className;
            state.outerClassName = action.payload.outerClassName;
        },
        closeModal: state => {
            state.isOpen = false;
            state.content = null;
            state.className = '';
            state.outerClassName = '';
            state.onConfirm = () => {};
            /*setTimeout(() => {
                state.modalReturnData = null;
            }, 300)*/
        },
        setModalReturnData: (state, action: { payload: any }) => {
            state.modalReturnData = action.payload;
        }
    },
});

export const {openModal, closeModal, setModalReturnData} = modalSlice.actions;
export default modalSlice.reducer;