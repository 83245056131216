import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import CustomerRequests from "../../../requests/customerRequests";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";

/*
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product*/

export interface CustomerCredentials {

    username: string;
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
    email: string;
    avatar: string;
    is_from_shop: true;
    is_reseller: false;
}

export interface CustomerCreateCredentials extends CustomerCredentials {
}

export interface CustomerUpdateCredentials extends CustomerCredentials {
    id: number,
}

export const submit = createAsyncThunk(
    'customers/post',
    async (credentials: CustomerCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CustomerRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                toastSuccess('مشتری با موفقیت ساخته شد')
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)


export interface SearchCustomerCredentials {
    title: string | null,
}

export const getAll = createAsyncThunk(
    'customers/getAll',
    async (credentials?: SearchCustomerCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CustomerRequests.getAllCustomers(credentials, store.getState().adminCustomers.paginate)
            if (response.status === 200) {
                return {status: response.status, data: [response.data.data, credentials], }
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'customers/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CustomerRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'customers/update',
    async (credentials: CustomerUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await CustomerRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
