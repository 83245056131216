import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import {ShopPlanInterface} from "../../../models/shopPlan";
import ShopPlanRequests from "../../../requests/shopPlanRequests";

export const submit = createAsyncThunk(
    'shopPlans/post',
    async (credentials: ShopPlanInterface): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ShopPlanRequests.create(credentials)
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


export const getAllShopPlans = createAsyncThunk(
    'shopPlans/getAll',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ShopPlanRequests.getAllPlans()
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const removePlan = createAsyncThunk(
    'shopPlans/remove',
    async (id: number): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ShopPlanRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


export const update = createAsyncThunk(
    'shopPlans/update',
    async (credentials: ShopPlanInterface): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ShopPlanRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: credentials}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
//update
