import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {SettingCredentials} from "../pages/front/settings/mainSettings";


class SettingRequests {

    public static async updateSettings(credentials: SettingCredentials): Promise<AxiosResponse> {
        return await API.authPut("settings", {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true
        });
    }
}

export default SettingRequests;