import Category from "./category";
import Product from "./product";

export enum DiscountType {
    PERCENT = "percent",
    FIXED = "fixed",
}

interface CustomerInterface {
    id: number,
    username: string;
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
    email: string;
    avatar: string;
    is_reseller: boolean;
}

class Customer {
    id: number;
    username: string;
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
    email: string;
    avatar: string;
    is_from_shop: true;
    is_reseller: false;

    constructor(data: CustomerInterface) {
        this.id = data.id;
        this.username = data.username;
        this.f_name = data.f_name;
        this.l_name = data.l_name;
        this.description = data.description;
        this.mobile_number = data.mobile_number;
        this.email = data.email;
        this.avatar = data.avatar;
        this.is_from_shop = true;
        this.is_reseller = false;
    }

}

export default Customer;