import Question from "./question";

export interface FormQuestionInterface {
    id: string;
    title: string,
    status: boolean,
    questions: Question[],
    form_question_id?: string,
    created_at: string,
}

class FormQuestion {

    id: string;
    title: string;
    status: boolean;
    questions: Question[];
    form_question_id?: string;
    createdAt: string;

    constructor(data: FormQuestionInterface) {
        this.id = data.id;
        this.title = data.title;
        this.status = data.status;
        this.questions = data.questions;
        this.createdAt = data.created_at;
    }

}

export default FormQuestion;