import User, {IUser} from "./user";

interface IUserWithChat {
    message: string;
    id: number;
    is_me?: boolean;
    reciever_id: number;
    sender_id: number;
    message_id: number;
    sender: User | null
    reciever: User | null
    created_at?: string | null;
}

class Chat {

    public reciever_id: number;
    public id: number;
    public is_me?: boolean;
    public sender_id: number;
    public message: string;
    public message_id: number;
    public reciever: User | null;
    public sender: User | null;
    public created_at?: string | null;

    constructor(data: IUserWithChat) {
        this.reciever_id = data.reciever_id;
        this.is_me = data.is_me;
        this.id = data.id;
        this.sender_id = data.sender_id;
        this.message = data.message;
        this.reciever = data.reciever;
        this.created_at = data.created_at;
        this.sender = data.sender;
        this.message_id = data.message_id;
    }

}

export default Chat;