import API from "../services/api";
import {AxiosResponse} from "axios";

export interface IPaymentStatusArgument {
    id: number,
    statuses: string
}

class PaymentStatusRequest {


    static async changeStatus(credentials: {statuses: string, id: number}): Promise<AxiosResponse> {
        return await API.authPut(`changePaymentStatus/${credentials.id}`, {
            data: {
                statuses: credentials.statuses
            },
            isUseLoading: true,
        });
    }

}

export default PaymentStatusRequest;