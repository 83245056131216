import API from "../services/api";
import {AxiosResponse} from "axios";
import {checkUser, convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {CartCheckoutCredentials} from "../redux/dashboard/cart/cartAction";
import {PlanCheckoutCredentials} from "../redux/dashboard/plans/buyPlanAction";
import {ShopPlanInterface} from "../models/shopPlan";

class ShopPlanRequests {


    public static async getAllPlans(): Promise<AxiosResponse> {
            return await API.authGet("shopPlan", {isUseLoading: true});
    }

    static async create(credentials: ShopPlanInterface): Promise<AxiosResponse> {

        return await API.authPost("shopPlan", {
            data: credentials,
            isUseLoading: true,
        });
    }

    static async buyPlan(credentials: PlanCheckoutCredentials): Promise<AxiosResponse> {
        return await API.authPost(`buyShopPlan/${credentials.id}` , {
            isUseLoading: true,
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id'])
            // data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
        });
    }


    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`${"shopPlan"}/${id}`, {isUseLoading: true});
    }

    static async update(credentials: ShopPlanInterface
    ): Promise<AxiosResponse> {
        return await API.authPut(`shopPlan/${credentials.id}`, {
            data: credentials,
            isUseLoading: true,
        });
    }
}


export default ShopPlanRequests;