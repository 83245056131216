import API from "../services/api";
import {AxiosResponse} from "axios";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {CartCheckDiscountCredentials, CartCheckoutCredentials} from "../redux/dashboard/cart/cartAction";
import {SearchContactCredentials} from "../redux/dashboard/contacts/contactsAction";
import {SearchUsersCredentials} from "../redux/dashboard/users/UsersAction";
import {SearchOrdersCredentials} from "../redux/dashboard/orders/orderAction";
import {useState} from "react";

export interface ICartArgument {
    productId: number,
    quantity: number
}

class CartRequests {

    public static async getCart(): Promise<AxiosResponse> {
        return await API.authGet("carts", {isUseLoading: true});
    }

    public static async getOrderCart(credentials: SearchOrdersCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`username=${encodeURIComponent(credentials.title)}`);
            // queryParams['username'] = encodeURIComponent(credentials.title?.toString() ?? '');
        }
        if (credentials.trackCode) {
            queryParams.push(`tracking_code=${encodeURIComponent(credentials.trackCode)}`);
            // queryParams['username'] = encodeURIComponent(credentials.title?.toString() ?? '');
        }

        if (credentials.mobilenumber) {
            queryParams.push(`mobilenumber=${encodeURIComponent(credentials.mobilenumber.toString())}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPrice=${encodeURIComponent(credentials.minPrice)}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPrice=${encodeURIComponent(credentials.maxPrice)}`);
        }

        if (credentials.status) {
            queryParams.push(`status=${encodeURIComponent(credentials.status)}`);
        }
        if (credentials.purchaseDate) {
            queryParams.push(`startDate=${encodeURIComponent(credentials.purchaseDate)}`);

            // queryParams['startDate'] = encodeURIComponent(credentials.purchaseDate.toString());
        }
        if (credentials.purchaseEndDate) {
            queryParams.push(`endDate=${encodeURIComponent(credentials.purchaseEndDate)}`);
            // queryParams['endDate'] = encodeURIComponent(credentials.purchaseEndDate.toString());
        }

        // Always include the page parameter
        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `orders?${queryString}`;
        // ارسال درخواست به API
        return await API.authGet(url, {isUseLoading: true});
    }

    public static async changeCart(credentials: ICartArgument): Promise<AxiosResponse> {
        return await API.authPost("carts/changeCart", {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true
        });
    }

    public static async clearCart(): Promise<AxiosResponse> {
        return await API.authPost("carts/clear", {isUseLoading: true});
    }

    public static async removeCart(credentials: ICartArgument): Promise<AxiosResponse> {
        return await API.authPost("carts/changeCart", {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true
        });
    }

    static async checkout(credentials: CartCheckoutCredentials): Promise<AxiosResponse> {
        return await API.authPost("carts/checkout", {
            isUseLoading: true,
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
        });
    }

    static async checkDiscount(credentials: CartCheckDiscountCredentials): Promise<AxiosResponse> {
        return await API.authPost(`carts/discountCode`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
        });
    }
}

export default CartRequests;