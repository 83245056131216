import API from "../services/api";
import {AxiosResponse} from "axios";
import {
    DiscountCodeCreateCredentials,
    DiscountCodeUpdateCredentials,
    SearchDiscountCodeCredentials
} from "../redux/dashboard/discountCodes/discountCodesAction";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {SearchOrdersCredentials} from "../redux/dashboard/orders/orderAction";

class DiscountCodeRequests {

    public static async getAllDiscountProducts(): Promise<AxiosResponse> {
        return await API.authGet('discountProducts', {
            // data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            // onProgress: onUploadProgress,
        });
    }
    public static async getAllDiscountCodes(credentials: SearchDiscountCodeCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`title=${encodeURIComponent(credentials.title)}`);
        }
        if (credentials.price) {
            queryParams.push(`price=${encodeURIComponent(credentials.price.toString())}`);
        }
        if (credentials.product) {
            queryParams.push(`product=${encodeURIComponent(credentials.product)}`);
        }
        if (credentials.category) {
            queryParams.push(`category=${encodeURIComponent(credentials.category)}`);
        }

        // Always include the page parameter
        queryParams.push(`page=${i}`);

        const queryString = queryParams.join('&');
        const url = `dashboard/discountCodes?${queryString}`;

        // Send request to API
        return await API.authGet(url, { isUseLoading: true });
    }




    // public static async getAllDiscountCodes(credentials: SearchDiscountCodeCredentials, i: number): Promise<AxiosResponse> {
    //     return await API.authGet(
    //
    //         `dashboard/discountCodes?code=${credentials.title ?? ''}&price=${credentials.price ?? ''}&product=${credentials.product ?? ''}&category=${credentials.category ?? ''}$&category=${credentials.category ?? ''}`,
    //         { isUseLoading: true }
    //     );
    // }


    static async create(credentials: DiscountCodeCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('dashboard/discountCodes', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`dashboard/discountCodes/${id}`, {isUseLoading: true});
    }

    static async update(credentials: DiscountCodeUpdateCredentials): Promise<AxiosResponse> {
        return await API.authPut(`dashboard/discountCodes/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default DiscountCodeRequests;