import React from 'react';
import DefaultModal from '../../../components/modal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
    toastError,
    getReplacementAvatar,
    getReplacementNullAvatar, defaultModal,
} from '../../../utils/funcions';
import { DefaultInput, DefaultTextArea } from '../../../components/fieilds';
import { Form, Formik, FormikHelpers } from 'formik';
import { handleMediaSelection } from '../../../utils/media/media_select';
import { MediaType } from '../../../models/media';
import {logout, serverAsset} from '../../../services/connectionConfig';
import { DefaultResponse } from '../../../redux/mainSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { deleteProfile, updateProfile } from '../../../redux/user/user/userAction';
import { setCrop, setCropResult } from '../../../redux/tools/cropSlice';
import { updateAvatar as updateAvatarInState } from '../../../redux/user/user/userSlice';
import { AiOutlineDelete, AiOutlineLogout } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineLogout } from 'react-icons/hi';
import { IconArrowRight } from '@tabler/icons-react';
import {useDispatch} from "react-redux";

interface Values {
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
}

export const EditProfileModal: React.FC<{ handleIsOpen: (isOpen: boolean) => void, isOpen: boolean }> = ({
                                                                                                             isOpen,
                                                                                                             handleIsOpen,
                                                                                                         }) => {
    const id: string = Math.random().toString(36).substr(2, 9);
    const { user, shop, submitLoading } = useAppSelector((state: RootState) => state.user);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    return (
        <DefaultModal label={'profileModal'} id={id} isOpen={isOpen} handleIsOpen={handleIsOpen}>
            <label htmlFor={id}>
                <div className="cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex">
                    <span><IconArrowRight /></span>
                    <span className="mr-2">پروفایل</span>
                </div>
            </label>
            <Formik
                initialValues={{
                    f_name: user?.fName ?? '',
                    l_name: user?.lName ?? '',
                    description: user?.description ?? '',
                    mobile_number: user?.mobileNumber ?? '',
                }}
                onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
                    try {
                        await dispatch(
                            updateProfile({
                                description: values.description,
                                fName: values.f_name,
                                lName: values.l_name,
                            })
                        );
                        (document.querySelector(`[for=${id}]`) as HTMLElement).click();
                    } catch (error) {
                        toastError('خطایی در به‌روزرسانی اطلاعات رخ داد');
                    } finally {
                        setSubmitting(false);
                    }
                }}
                validate={(values: Values) => {
                    const errors: any = {};
                    if (!values.f_name) {
                        errors.f_name = 'نام الزامی است';
                    } else if (values.f_name.length < 3) {
                        errors.f_name = 'حداقل 3 کاراکتر';
                    } else if (values.f_name.length > 60) {
                        errors.f_name = 'حداکثر 60 کاراکتر';
                    }

                    if (!values.l_name) {
                        errors.l_name = 'نام خانوادگی الزامی است';
                    } else if (values.l_name.length < 3) {
                        errors.l_name = 'حداقل 3 کاراکتر';
                    } else if (values.l_name.length > 60) {
                        errors.l_name = 'حداکثر 60 کاراکتر';
                    }

                    if (values.description && values.description.length > 500) {
                        errors.description = 'حداکثر 500 کاراکتر';
                    }

                    return errors;
                }}
            >
                <Form className="w-full flex justify-center md:mt-6 mt-10">
                    <div className="flex flex-col w-full">
                        <div className="modal-header md:block hidden">
                            <div className="flex flex-1">
                                <div
                                    className="flex cursor-pointer"
                                    onClick={() => {
                                        handleLogout();
                                    }}
                                >
                                    <HiOutlineLogout color={'#DE0046'} size={25} />
                                    <h2 className="text-lg mr-2 -mt-1 font-bold text-[#DE0046]">خروج</h2>
                                </div>
                                <h2 className="text-lg mr-4 font-bold -mt-1 text-gray-500">پروفایل {user?.fName}</h2>
                            </div>
                        </div>
                        <div className="modal-body mt-4 w-full">
                            <div className="flex md:flex-row flex-col justify-center md:items-start items-center">
                                <AvatarSelect />
                                <div className="px-5 w-full">
                                    <DefaultInput
                                        label={'نام'}
                                        type="string"
                                        className=""
                                        name="f_name"
                                    />
                                    <DefaultInput
                                        label={'نام خانوادگی'}
                                        type="string"
                                        className=""
                                        name="l_name"
                                    />
                                    <DefaultTextArea
                                        label={'توضیحات'}
                                        type="string"
                                        placeholder={'توضیحات'}
                                        className="mb-4"
                                        name="description"
                                    />
                                    <DefaultInput
                                        type="string"
                                        disabled={true}
                                        value={user?.mobileNumber ?? ''}
                                        className=""
                                        name="mobile_number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'w-full h-2 bg-[#F7F7F7]'}></div>
                        <div className="modal-header md:hidden block mt-4">
                            <div className="flex flex-1 mr-3">
                                <div
                                    className="flex cursor-pointer"
                                    onClick={() => {
                                        handleLogout();
                                    }}
                                >
                                    <HiOutlineLogout color={'#DE0046'} size={24} />
                                    <h2 className="text-[16px] mr-2 text-[#DE0046]">خروج از حساب کاربری</h2>
                                </div>
                            </div>
                        </div>
                        <div className="modal-action w-full flex justify-start md:px-0 px-3">
                            <button
                                type="submit"
                                className={`btn text-white ${
                                    submitLoading
                                        ? 'bg-gray-400'
                                        : 'bg-[#4192EF] hover:scale-102 hover:text-[#4192EF] hover:shadow-soft-xs active:opacity-85 md:w-max w-full'
                                }`}
                            >
                                بروزرسانی
                            </button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );

    function handleLogout() {
        defaultModal(
            <div className="flex flex-col items-center">
                <h2 className="text-lg mr-4 font-bold -mt-1 text-gray-500">آیا مطمئن هستید؟</h2>
                <h2 className="text-lg mr-4 font-bold -mt-1 text-gray-500">آیا می‌خواهید از حساب کاربری خود خارج شوید؟</h2>
            </div>,
            () => logout()
        );
    }
};

const AvatarSelect: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { user } = useAppSelector((state: RootState) => state.user);

    // @ts-ignore
    const [medium, setMedium] = React.useState<string | Blob | null>(serverAsset(user?.avatar ?? null));
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
    const replacementAvatar = getReplacementAvatar();
    const cropResult = useAppSelector(state => state.crop.result);

    const updateAvatar = async (file: File | Blob) => {
        // if (file && file.size > 5000000) {
        //     return toastError('حجم عکس نباید بالای 5 مگابایت باشد');
        // }

        const result: DefaultResponse = (await dispatch(
            updateProfile({
                avatar: file,
                lName: user!.lName,
                fName: user!.fName,
                banner: user!.banner,
            })
        )).payload as DefaultResponse;

        if (result.status === 200) {
            dispatch(updateAvatarInState(result.data['avatar']));
        } else {
            console.log('console.log', user!.avatar , replacementAvatar)
            setMedium(user!.avatar ?? replacementAvatar);
        }
    };

    const deleteAvatar = async () => {
        setDeleteLoading(true);
        try {
            const result: DefaultResponse = (await dispatch(deleteProfile())).payload as DefaultResponse;
            if (result.status === 200) {
                const nullAvatar = getReplacementNullAvatar();
                dispatch(updateAvatarInState(nullAvatar));
                setMedium(nullAvatar);
            } else {
                toastError('مشکلی در حذف پروفایل پیش آمد');
            }
        } catch (error) {
            toastError('خطایی رخ داد');
        } finally {
            setDeleteLoading(false);
        }

    };

    React.useEffect(() => {
        if (cropResult) {
            setMedium(cropResult);
            dispatch(setCropResult(undefined));
            updateAvatar(cropResult);
        }
    }, [cropResult, dispatch]);

    return (
        <div className="flex flex-col w-40 h-40">
            <div className="relative w-full h-full">
                <div className="relative w-full flex justify-center items-center">
                    {medium && medium !== serverAsset(replacementAvatar) && (
                        <div className="absolute top-1 right-1">
                            <button
                                className="btn btn-sm bg-gray-300 p-0 bg-opacity-80 rounded-full w-8 h-8 flex justify-center items-center border-none"
                                onClick={deleteAvatar}
                                disabled={deleteLoading}
                            >
                                <AiOutlineDelete size={18} className="text-[#DE0046]" />
                            </button>
                        </div>
                    )}
                    <img
                        src={medium instanceof Blob ? URL.createObjectURL(medium) : medium ?? replacementAvatar}
                        alt="User Avatar"
                        className="rounded-full object-cover w-[120px] h-[120px]"
                        width="120"
                        height="120"
                    />
                    <div className="absolute bottom-1 right-1">
                        <button
                            className="btn btn-sm p-0 bg-gray-300 bg-opacity-80 rounded-full w-8 h-8 flex justify-center items-center border-none"
                            onClick={async () => {
                                const files: File[] | null = await handleMediaSelection([MediaType.image]);
                                console.log('console.log', files)
                                if (files) {
                                    console.log('console.log', files)
                                    await dispatch(setCrop(files[0]));
                                }
                            }}
                        >
                            <BiEditAlt size={18} className="text-black-400" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
