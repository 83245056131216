import API from "../services/api";
import {AxiosResponse} from "axios";
import {MediaType} from "../models/media";
import {
    CustomerCreateCredentials,
    CustomerUpdateCredentials,
    SearchCustomerCredentials
} from "../redux/dashboard/customers/customersAction";
import {convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import store from "../redux/store";

class CustomerRequests {

    public static async getAllCustomers(credentials?: SearchCustomerCredentials, i?: number): Promise<AxiosResponse> {
        return await API.authGet(`customers?username${credentials?.title ? `=${credentials.title}` : ''}&page=${i}`, {isUseLoading: true});
    }

    static async create(credentials: CustomerCreateCredentials, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse> {
        return await API.authPost('customers', {
            data: credentials,
            isUseLoading: true,
            onProgress: onUploadProgress,
        });
    }

    static async remove(id: number): Promise<AxiosResponse> {
        return await API.authDelete(`customers/${id}`, {isUseLoading: true});
    }

    static async update(credentials: CustomerUpdateCredentials): Promise<AxiosResponse> {
        return await API.authPut(`customers/${credentials.id}`, {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials, ['id']),
            isUseLoading: true,
        });
    }
}



export default CustomerRequests;