import React, {ReactNode, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Routes from "../utils/routing/routes";
import Auth from "../services/savedData/auth";
import {EditProfileModal} from "../pages/front/profile/edit";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

const NoPlanGuard: React.FC<{children: ReactNode}> = ({ children }) => {
    const {is_expired} = useSelector((state: RootState) => state.user);
    if (is_expired) {
        return <Navigate to={Routes.withoutShopPlans.path} />
    }

    return (
        <>
            {children}
        </>
    );
};

export default NoPlanGuard;
