

interface IShop {

    id: number;
    username: string;
    description: string;
    avatar: string;
    banner: string;
    video: string;
    plan_id?: number;
}

export class Shop {

        public id: number;
        public username: string;
        public description: string;
        public avatar: string;
        public banner: string;
        public video: string;
        public plan_id?: number;

        constructor(data: IShop) {
            this.id = data.id;
            this.username = data.username;
            this.plan_id = data.plan_id;
            this.description = data.description;
            this.avatar = data.avatar;
            this.banner = data.banner;
            this.video = data.video;
        }

}