import { createSlice } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
import QuestionAnswer from "../../../models/questionAnswer";
import { getQuestionAnswers } from "./questionAnswersAction";
import contact from "../../../models/contact";
import Question from "../../../models/question";

// Interface for the state with additional properties
interface State extends DefaultStates {
    questionAnswers: Question|null;
    submitLoading: boolean;
}
const initialState: State = {
    questionAnswers: null,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const QuestionAnswersSlice = createSlice({
    name: 'questionAnswers',
    initialState,
    reducers: {}, // No reducers defined in this slice
    extraReducers: (builder) => {
        builder.addCase(getQuestionAnswers.pending, (state) => {
            state.loading = true; // Set loading to true while fetching data
        });
        builder.addCase(getQuestionAnswers.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.questionAnswers = (new Question({
                    id: response.data[0].id,
                    type: response.data[0].type,
                    answers: response.data[0].answers,
                    title: response.data[0].title,
                    form_question_id: response.data[0].form_question_id
                }))
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });
        builder.addCase(getQuestionAnswers.rejected, (state, action) => {
            state.loading = false; // Set loading to false on error
        });
    },
});

export default QuestionAnswersSlice.reducer;
