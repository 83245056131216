import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import FormQuestionRequests from "../../../requests/formQuestionRequests";
import {MediaType} from "../../../models/media";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import FormQuestion from "../../../models/formQuestion";
import Category from "../../../models/category";
import Product from "../../../models/product";

/*
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    category?: Category,
    product?: Product*/

export interface FormQuestionCredentials {
    title: string,
    status: boolean
}

export interface FormQuestionCreateCredentials extends FormQuestionCredentials {
}

export interface FormQuestionUpdateCredentials extends FormQuestionCredentials {
    id: string,
}

export const submit = createAsyncThunk(
    'formQuestions/post',
    async (credentials: FormQuestionCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await FormQuestionRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

export interface FormCredentials {
    title: string | null,
}

export const getAll = createAsyncThunk(
    'formQuestions/getAll',
    async (credentials?: FormCredentials): Promise<DefaultResponse> => {
        try {
            // @ts-ignore
            const response: AxiosResponse = await FormQuestionRequests.getAllFormQuestions(credentials ?? {} as FormCredentials, store.getState().formQuestions.paginate);
            if (response.status === 200) {
                return {status: response.status, data: [response.data, credentials]}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'formQuestions/remove',
    async (id: string): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await FormQuestionRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'formQuestions/update',
    async (credentials: FormQuestionUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await FormQuestionRequests.update(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
