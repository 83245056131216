import LTVList from "./list";
import AdvancedCategoryList from "./list";

function Index() {


    return (
        <>

            {/*admin can using this*/}
            <div
                className="py-6 px-4 md:mt-0 mt-10 pb-0 mb-2 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">
                <h6>ارزش مشتریان</h6>
                {/*<label htmlFor="addProduct"*/}
                {/*       className="btn bg-gradient-to-tl from-purple-800 to-pink-500 border-none p-3 rounded-xl text-xl text-white">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg"*/}
                {/*         className="icon icon-tabler icon-tabler-plus" width="24"*/}
                {/*         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"*/}
                {/*         fill="none"*/}
                {/*         strokeLinecap="round" strokeLinejoin="round">*/}
                {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>*/}
                {/*        <path d="M12 5l0 14"/>*/}
                {/*        <path d="M5 12l14 0"/>*/}
                {/*    </svg>*/}
                {/*</label>*/}


            </div>
            {/*end admin using*/}
            <AdvancedCategoryList></AdvancedCategoryList>



        </>
    );
}

export default Index;