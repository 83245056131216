import {DefaultStates} from "../../mainSlice";
import {createSlice} from "@reduxjs/toolkit";
import {getAll} from "./analyticsAction";
import {LaravelValidationErrorType} from "../../../utils/types";
import User from "../../../models/user";


interface State extends DefaultStates {
    data: {
        "30DaysAgoSoldCount": number[],
        "30DaysAgoSoldAmount": string[],
        "30DaysAgoUsersCount": number[],
        "30DaysAgoProductsCount": number[],
        totalSoldCount: number,
        totalSoldAmount: string,
        totalUsersCount: number,
        totalProductsCount: number,
        mostCategorySoldAmountOf30DaysAgo: {
            month_index: number,
            category_id: number,
            category_amount: string,
            category_name: string
        }[],
        mostCategorySoldCountOf30DaysAgo: {
            month_index: number,
            category_id: number,
            product_count: number,
            category_name: string
        }[],
        mostProductSoldAmountOf30DaysAgo: {
            month_index: number,
            product_id: number,
            product_amount: string,
            product_name: string,
            product_image: string,
            product_url: string
        }[],
        mostProductSoldCountOf30DaysAgo: {
            month_index: number,
            product_id: number,
            product_count: number,
            product_name: string,
            product_image: string,
            product_url: string
        }[],
        mostUserBuyCountOf30DaysAgo: (null|{
            user_id: number,
            user_count: number,
            user: User
        })[],
        mostUserBuyAmountOf30DaysAgo: (null|{
            user_id: number,
            user_amount: string,
            user: User
        })[],
        categorize: {
            Best: {
                user_id?: number,
                f_name?: string,
                l_name?: string,
                avatar?: string,
                total_count?: number
            }[],
            Average: {
                user_id?: number,
                f_name?: string,
                l_name?: string,
                avatar?: string,
                total_count?: number
            }[],
            Bad: {
                user_id?: number,
                f_name?: string,
                l_name?: string,
                avatar?: string,
                total_count?: number
            }[]
        },
        clv: {
            id: number,
            f_name: string,
            l_name: string,
            avatar: string,
            mobile_number: string,
            user_id: number,
            apv: string,
            frequency: number,
            lifespan: string,
            clv: string
        }[]

    };
}

const initialState: State = {
    loading: true,
    validationErrors: null,
    data: {
        "30DaysAgoSoldCount": [],
        "30DaysAgoSoldAmount": [],
        "30DaysAgoUsersCount": [],
        "30DaysAgoProductsCount": [],
        totalSoldCount: 0,
        totalSoldAmount: '',
        totalUsersCount: 0,
        totalProductsCount: 0,
        mostCategorySoldAmountOf30DaysAgo: [],
        mostCategorySoldCountOf30DaysAgo: [],
        mostProductSoldAmountOf30DaysAgo: [],
        mostProductSoldCountOf30DaysAgo: [],
        mostUserBuyCountOf30DaysAgo: [],
        mostUserBuyAmountOf30DaysAgo: [],
        categorize: {
            Best: [],
            Average: [],
            Bad: []
        },
        clv: []
    }
};

const AnalyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAll.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.data = response.data;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAll.rejected, (state, action) => {
                state.loading = false;
            }
        )

    },
});

export default AnalyticsSlice.reducer;