import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {submit} from "redux/user/login/loginAction";
import {LaravelValidationErrorType} from "../../../utils/types";
import {ModalPayload} from "../../components/modalSlice";

interface State extends DefaultStates {
    token: string | null;
    mobileNumber: string,
    isReseller: boolean
}

const initialState: State = {
    token: null,
    loading: false,
    validationErrors: null,
    mobileNumber: '',
    isReseller: false
};

const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        signUpAsReseller: (state) => {
            state.isReseller = true;
        },
        signUpAsDefault: (state) => {
            state.isReseller = false;
        },
        setMobileNumber: (state, action) => {
            state.mobileNumber = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.loading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.token = response.data as string;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            } else if (response.status === 201 || response.status === 203) {
                state.mobileNumber = response.mobileNumber as string;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.loading = false;
        })
    },
});

export default LoginSlice.reducer;
export const {signUpAsReseller, signUpAsDefault, setMobileNumber} = LoginSlice.actions;
