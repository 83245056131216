import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import { LoadingTableShimmer } from '../../../components/basincComponents';
import { moneyFormat } from '../../../utils/funcions';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import AdvancedCategory from "../../../models/advancedCategory";
import Routes from "../../../utils/routing/routes";

const AdvancedCategoryList: React.FC = () => {
    const { advancedCategories, loading } = useAppSelector((state: RootState) => state.advancedCategory);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const toggleSortDirection = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    // تابع مقایسه ارزش‌ها بر اساس جهت مرتب‌سازی
    const compareValues = (a: AdvancedCategory, b: AdvancedCategory) => {
        const valueA = parseInt(a.lowValue[0].average_revenue);
        const valueB = parseInt(b.lowValue[0].average_revenue);

        if (sortDirection === 'asc') {
            return valueA - valueB; // مرتب‌سازی از کم به زیاد
        } else {
            return valueB - valueA; // مرتب‌سازی از زیاد به کم
        }
    };

    return (
        <div className="flex-auto px-0 pt-0 pb-2">
            <Link to={Routes.products.path} className="cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex">
                <span><IconArrowRight/></span>
                <span className="mr-2">دسته بندی مشتریان</span>
            </Link>
            <div className="p-0 md:px-6 overflow-x-auto ps">
                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            نام و نام خانوادگی
                        </th>
                        <th
                            className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                            onClick={toggleSortDirection}
                            style={{ cursor: 'pointer' }}
                        >
                            ارزش{' '}
                            {sortDirection === 'asc' ? (
                                <span>&uarr;</span> // نمایش نشانه مرتب‌سازی از کم به زیاد
                            ) : (
                                <span>&darr;</span> // نمایش نشانه مرتب‌سازی از زیاد به کم
                            )}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading || !advancedCategories ? (
                        <tr>
                            <td colSpan={3} className="py-4 text-center">
                                <LoadingTableShimmer />
                            </td>
                        </tr>
                    ) : (
                        advancedCategories.sort(compareValues).map((category) => (
                            <tr>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                    {`${category.lowValue[0].user.f_name} ${category.lowValue[0].user.l_name}`}
                                </td>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                    {moneyFormat(parseInt(category.lowValue[0].average_revenue))}
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedCategoryList;
