import AddQuestionModal from './add';
import QuestionsList from "./list";
import {IconCirclePlus} from "@tabler/icons-react";
import {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {getAll} from "../../../redux/dashboard/questions/questionsAction";


function Index() {

    // const [title, setTitle] = useState<string>('');
    // const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const lastSearchTimeRef = useRef(Date.now());
    // const handleSearch = async () => {
    //     const currentTime = Date.now();
    //     if (currentTime - lastSearchTimeRef.current >= 2000) { // 10 seconds
    //         const data = {
    //             title: title,
    //         };
    //
    //         try {
    //             // @ts-ignore
    //             dispatch(getAll(data));
    //         } catch (error) {
    //             alert('مشکلی پیش آمد.')
    //         }
    //
    //         setTitle(''); // Clear title after search
    //         lastSearchTimeRef.current = currentTime; // Update the last search time
    //     } else {
    //         alert('   2 ثانیه یکبار اقدام به جستجو کنید.')
    //     }
    // };
    //
    // const handleDeleteSearch = async () => {
    //
    //     const data = {
    //         title: "",
    //     };
    //
    //     try {
    //         dispatch(getAll(data))
    //     } catch (error) {
    //     }
    //
    //     setTitle("");
    // };

    return (
        <>
            {/*end admin using*/}
            <QuestionsList></QuestionsList>
        </>
    );
}

export default Index;