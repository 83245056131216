import ContactsAndCustomersList from "./list";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";

function Index() {


    return (
        <>


            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 px-4 z-[40] py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>کمپین ها</span>
            </Link>
            {/*admin can using this*/}
            {/*<div*/}
            {/*    className="py-6 pr-6 pb-0 mb-2 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">*/}
            {/*    <h6 className='mr-3'>کمپین ها</h6>*/}
            {/*</div>*/}
            {/*end admin using*/}
            <ContactsAndCustomersList></ContactsAndCustomersList>



        </>
    );
}

export default Index;