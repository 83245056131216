import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import User from "../../../models/user";
import {getAllUsersChat} from "./usersChatAction";
import Chat from "../../../models/chat";

interface State extends DefaultStates {
    users: Chat[];
    submitLoading: boolean;
}

const initialState: State = {
    users: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
// دیتا رو نگه داری میکنیم
const UsersChatSlice = createSlice({
    name: 'usersChat',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUsersChat.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllUsersChat.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const chats: Chat[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const category = response.data[i];

                    chats.push(new Chat({message: category.message, sender_id: category.sender_id, reciever_id: category.reciever_id, message_id: category.message_id, id: category.id, reciever: category.reciever, sender: category.sender}));
                }

                state.users = chats;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllUsersChat.rejected, (state, action) => {
                state.loading = false;
            }
        )

    },
});

export default UsersChatSlice.reducer;