import React, {useState} from 'react';
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const LTVList = () => {
    const {lTV, loading} = useAppSelector((state: RootState) => state.lTV);
    return (
        <div className="flex-auto px-0 pt-0 pb-2">
            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
            <span><IconArrowRight/></span>
            <span className={'mr-2'}>ارزش مشتریان</span>
        </Link>
            <div className="p-0 md:px-6 overflow-x-auto ps">
                <table
                    className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام و نام خانوادگی</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">LTV</th>
                        {/*<th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">*/}
                        {/*    ویرایش / حذف*/}
                        {/*</th>*/}
                    </tr>
                    </thead>
                    {loading ? "" : (
                        <tbody>
                        {lTV.map((ltv, i) => (
                            ltv.ltv && <tr key={ltv.user.id.toString()}
                                               className={`transition-all `}>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ltv.user.fName && ltv.user.lName}</td>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ltv.ltv}</td>
                                {/*<td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ltv.user.lName}</td>*/}
                            </tr>
                        ))}

                        </tbody>
                    )}
                </table>
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>
        </div>
    );
};

export default LTVList;
