import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {getAllHighLights} from "./storiesAction";
import Product from "../../../models/product";

interface State extends DefaultStates {
    highLightedProducts: Product[];
    submitLoading: boolean;
}

const initialState: State = {
    highLightedProducts: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};
const HighlightedProducts = createSlice({
    name: 'highLightedProducts',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllHighLights.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllHighLights.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const highLightedProducts: Product[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    const highlight = response.data[i];
                    highLightedProducts.push(new Product({
                        id: highlight.id,
                        title: highlight.title,
                        code: highlight.code,
                        category_id: highlight.category_id,
                        price: highlight.price,
                        category: highlight.category,
                        high_light: highlight.high_light,
                        highLightId: highlight.highLightId,
                        description: highlight.description,
                        quantity: highlight.quantity,
                        media: highlight.media,
                        is_product_active: highlight.is_product_active,
                        inventory_warning: highlight.inventory_warning,
                    }));
                }
                state.highLightedProducts = highLightedProducts;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllHighLights.rejected, (state, action) => {
                state.loading = false;
            }
        )
    },
});

export default HighlightedProducts.reducer;