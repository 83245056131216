import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import answerRequests from "../../../requests/answerRequests";
import AnswerRequests from "../../../requests/answerRequests";
import FormQuestionRequests from "../../../requests/formQuestionRequests";
import {goToRoute} from "../../../utils/routing/baseRoutes";
import Routes from "../../../utils/routing/routes";

export interface AnswerCredentials {
    question_id: number[],
    form_question_id?: string,
    answer: string[],
}

export const submit = createAsyncThunk(
    'formQuestions/post',
    async (credentials: AnswerCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await AnswerRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            if (response.status === 200 || response.status === 201) {
                toastSuccess('نظرات شما ثبت شد')
                // goToRoute(Routes.successAnswerSubmitted)
            } else if (response.status === 422) {
                // console.log('errorValidation', response.data)
                const errors = await response.data as LaravelValidationErrorType;
                // console.log('errorValidation', errors)
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const getFormQuestionById = createAsyncThunk(
    'formQuestion/get',
    async (data: {[key: string]: any}): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await FormQuestionRequests.getFormQuestionById(data.id)
            if (response.status === 200) {

                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
