import Media from "./media";
import Category from "./category";
import {Shop} from "./shop";
import HighLight from "./highLightedProducts";
import Like from "./likes";

interface ProductInterface {
    id: number;
    title: string;
    description: string;
    code: string;
    price: number;
    old_price?: number;
    buy_price?: number;
    quantity: number;
    category_id?: number;
    category?: Category;
    high_light_id?: number;
    highLightId?: HighLight;
    high_light?: HighLight;
    shop?: Shop;
    media: Media[];
    cover?: Media[];
    // attribute?: Attribute[];
    like?: Like[];
    is_product_active: boolean;
    inventory_warning?: boolean;
    zero_inventory?: boolean;
}
class Product {

    id: number;
    title: string;
    description: string;
    code: string;
    price: number;
    old_price?: number;
    buy_price?: number;
    quantity: number;
    categoryId?: number;
    category?: Category;
    high_light_id?: number;
    high_light?: HighLight;
    highLightId?: HighLight;
    media: Media[];
    cover?: Media[];
    // attribute?: Attribute[];
    shop?: Shop;
    like?: Like[];
    is_product_active: boolean;
    inventory_warning?: boolean;
    zero_inventory?: boolean;

    constructor(data: ProductInterface) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.code = data.code;
        this.price = data.price;
        this.old_price = data.old_price;
        this.quantity = data.quantity;
        this.categoryId = data.category_id;
        this.high_light_id = data.high_light_id;
        this.high_light = data.high_light;
        this.highLightId = data.highLightId;
        this.category = data.category;
        this.media = data.media;
        this.cover = data.cover;
        // this.attribute = data.attribute;
        this.shop = data.shop;
        this.like = data.like;
        this.is_product_active = data.is_product_active;
        this.inventory_warning = data.inventory_warning;
        this.zero_inventory = data.zero_inventory;
    }

}

interface AttributeInterface {
    id?: number;
    name?: string;
    product_id?: number;
    attributeValues?: AttributeValues[];
}

class Attribute {

    id?: number;
    name?: string;
    product_id?: number;
    attributeValues?: AttributeValues[];

    constructor(data: AttributeInterface) {
        this.id = data.id;
        this.name = data.name;
        this.product_id = data.product_id;
        this.attributeValues = data.attributeValues;

    }

}

interface AttributeValuesInterface {
    id?: number;
    key?: string;
    value?: string;
    quantity?: number;
}

class AttributeValues {

    id?: number;
    key?: string;
    value?: string;
    quantity?: number;

    constructor(data: AttributeValuesInterface) {
        this.id = data.id;
        this.key = data.key;
        this.value = data.value;
        this.quantity = data.quantity;

    }

}

export default Product;