import {createSlice} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import FormQuestion from "../../../models/formQuestion";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toastSuccess} from "../../../utils/funcions";
import {getFormQuestionById, submit} from "redux/dashboard/answers/answersAction";
import Answer from "../../../models/answer";

interface State extends DefaultStates {
    question?: FormQuestion;
    answers?: Answer|null;
    submitLoading: boolean;
}

const initialState: State = {
    question: undefined,
    answers: null,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const AnswersSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.question = new FormQuestion(data);
                toastSuccess('فرم سوال با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })
        builder.addCase(getFormQuestionById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getFormQuestionById.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.question = response.data
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getFormQuestionById.rejected, (state, action) => {

                state.loading = false;
            })
    },
});

export default AnswersSlice.reducer;