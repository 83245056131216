import AddCategoryModal from './add';
import CategoriesList from "./list";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";
import React from "react";
import store from "../../../redux/store";


function Index() {

    const {user, shop} = store.getState().user;

    return (
        <div className={' lg:pl-28 md:pl-20 pl-4'}>
            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>دسته بندی</span>
            </Link>
            {
                <div
                    className="py-6 pb-0 mt-10 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">
                    <span className={'md:block hidden'}>دسته بندی ها</span>
                    <div></div>
                    {user!.id === shop!.id ? <div>
                        <label htmlFor="addCategory"
                               className="btn block bg-[#4192EF] border-none p-3 flex  rounded-xl text-[15px] text-white">
                            افزودن
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-plus" width="24"
                                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 5l0 14"/>
                                <path d="M5 12l14 0"/>
                            </svg>
                        </label>


                        <AddCategoryModal/>
                    </div> : ''

                    }

                </div>
            }
            <CategoriesList></CategoriesList>
        </div>
    );
}

export default Index;