import React, {useEffect, useRef, useState} from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import DefaultModal from "../../../components/modal";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import Product from "../../../models/product";
import { DefaultResponse } from "../../../redux/mainSlice";
import { getMediaTypes, MediaType } from "../../../models/media";
import MediaSelect from "../../../utils/media/media_select";
import { ProductUpdateCredentials, update } from "../../../redux/dashboard/products/productsAction";
import {IconArrowRight, IconInfoSquare, IconMinus, IconPlus} from "@tabler/icons-react";
import { NetworkImage, toastError } from "../../../utils/funcions";
import { serverAsset } from "../../../services/connectionConfig";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {DefaultBooleanField, DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";

interface Values {
    title: string;
    description: string;
    price: number;
    old_price?: number;
    buy_price?: number;
    categoryId?: number;
    category?: string;
    quantity: number;
    highLightId?: string | null;
    is_product_active: boolean;
    inventory_warning?: boolean;
    zero_inventory?: boolean;

}

const EditProductModal: React.FC<{ product: Product | null, onClose: (isOpen: boolean) => void }> = ({ product, onClose }) => {
    const [cover, setCover] = React.useState<File[] | string | null>(null);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { submitLoading } = useAppSelector((state: RootState) => state.adminProducts);
    const [media, setMedia] = React.useState<File[] | null>(null);
    const [mediaDeleted, setMediaDeleted] = React.useState<number[]>([]);

    const handleEditConfirm = async (values: Values) => {
        const credentials: ProductUpdateCredentials = {
            mediaUrls: media!,
            mediaTypes: getMediaTypes(media ?? []),
            id: product!.id,
            deleteMediaIds: mediaDeleted,
            ...values,
            coverUrls: cover,
            coverTypes: 'image',
        };
        const result: DefaultResponse = (await dispatch(update(credentials))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    };

    useEffect(() => {
        setMedia(null);
        setMediaDeleted([]);
    }, [product]);

    const { categories, loading } = useAppSelector((state: RootState) => state.adminCategories);

    const formikRef = useRef<FormikProps<any>>(null);
    const { isMerchantFilled } = useAppSelector((state: RootState) => state.user);
    const handleRadioClick = (value: boolean) => {
        if (!isMerchantFilled) {
            toastError('شما باید مرچنت آیدی خود را از قسمت تنظیمات -> مرچنت آیدی وارد کنید');
            return;
        }
        formikRef.current!.setFieldValue('is_product_active', value);
    };
    const handleRadioInventoryClick = (value: boolean) => {
        formikRef.current!.setFieldValue('inventory_warning', value);
    };

    const {highLights} = useAppSelector((state: RootState) => state.highlights);

    const [highlightShow, setHighlightShow] = useState<boolean>(true);

    const handleChangeHighlightShow = () => {
        setHighlightShow(!highlightShow);
    };

    return (
        <DefaultModal
            label={'editProduct'}  id='editProduct' isOpen={!!product} handleIsOpen={onClose} modalBoxClasses={`md:!max-w-3xl md:!w-max lg:!min-h-50-screen lg:min-w-31/100 px-3`}>
            {product ? (
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        title: product.title,
                        description: product.description,
                        price: product.price,
                        old_price: product.old_price,
                        categoryId: product.category?.id,
                        quantity: product.quantity,
                        category: product.category?.title,
                        highLightId: product.highLightId?.name,
                        is_product_active: product.is_product_active,
                        inventory_warning: product.inventory_warning,
                        buy_price: product.buy_price,
                        zero_inventory: product.zero_inventory,
                    }}
                    onSubmit={(values: Values) => {
                        try {
                            handleEditConfirm(values);
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                    validate={(values: Values) => {
                        const errors: any = {};
                        if (!values.title) {
                            errors.title = 'عنوان محصول را وارد کنید';
                        }
                        if (!values.description) {
                            errors.description = 'توضیحات محصول را وارد کنید';
                        }
                        if (!values.price) {
                            errors.price = 'قیمت محصول را وارد کنید';
                        }
                        // if (!values.categoryId) {
                        //     errors.categoryId = 'دسته بندی محصول را انتخاب کنید';
                        // }
                        if (!values.quantity) {
                            errors.quantity = 'تعداد محصول را وارد کنید';
                        }
                        return errors;
                    }}
                >
                    {({ values, handleChange, handleBlur }) => (
                        <Form method="dialog">
                            <label htmlFor={'editProduct'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className="flex md:flex-row justify-around items-start flex-col md:mt-6 mt-12">
                                <div className="md:w-1/2 w-full flex justify-center">
                                    <MediaSelect
                                        onInitialMediaDelete={(media) => {
                                            setMediaDeleted([...mediaDeleted, media]);
                                        }}
                                        initialMedia={product.media}
                                        isDeletable={((media?.length ?? 0) + (product.media.length - mediaDeleted.length)) > 1}
                                        mediaTypes={[MediaType.image, MediaType.video]}
                                        onFileSelect={(files: File[] | null) => {
                                            setMedia(files);
                                        }}
                                    />
                                </div>
                                <div className="md:w-5"></div>
                                <div className="md:w-1/2">
                                    <div className='flex-wrap mt-4 justify-start w-full flex'>
                                        <DefaultInput
                                            label='عنوان محصول'
                                            name="title"
                                            type="text"
                                            placeholder='عنوان محصول'
                                        />

                                        <DefaultInput
                                            label='قیمت محصول'
                                            name="price"
                                            type="number"
                                            placeholder='قیمت محصول را وارد کنید'
                                        />
                                        <DefaultInput
                                            label='قیمت خرید'
                                            name="buy_price"
                                            type="number"
                                            placeholder='123456'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='قیمت قبل تخفیف'
                                            name="old_price"
                                            type="number"
                                            placeholder='123456'
                                        ></DefaultInput>

                                        <DefaultInput
                                            label='تعداد محصول'
                                            name="quantity"
                                            type="number"
                                            placeholder='تعداد محصول را وارد کنید'
                                        />

                                        <div className={'flex w-full items-start justify-end'}>
                                            {
                                                highlightShow ? <DefaultSelect
                                                    label='برچسب های موجود'
                                                    addDiv={<div
                                                        className={'flex cursor-pointer text-[#4192EF]'}
                                                        onClick={handleChangeHighlightShow}>
                                                        افزودن برچسب
                                                        <IconPlus color={'#4192EF'}/>
                                                    </div>}
                                                    name="highLightId"
                                                    placeholder='انتخاب کنید'
                                                    className={'w-full'}
                                                    options={
                                                        highLights == null ? <div>
                                                            <div></div>
                                                        </div> : highLights.map((category) => {
                                                            return {
                                                                label: category.name,
                                                                value: category.name
                                                            }
                                                        }) as any
                                                    }
                                                ></DefaultSelect> : ''
                                            }

                                        </div>

                                        {!highlightShow ? <DefaultInput

                                            addDiv={<div
                                                className={'flex rounded-full cursor-pointer text-[#DE0046]'}
                                                onClick={handleChangeHighlightShow}>
                                                برچسب های موجود
                                                <IconMinus color={'#DE0046'}/>
                                            </div>}
                                            label='عنوان برچسب'
                                            name="highLightId"
                                            type="text"
                                            placeholder='عنوان برچسب'
                                        ></DefaultInput> : ''}


                                        <div className={`w-full flex justify-center mb-4 flex-col`}>
                                            <label htmlFor=""> کاور برچسب</label>
                                            <MediaSelect mediaTypes={[MediaType.image]}
                                                         onFileSelect={(files: File[] | null) => {
                                                             setCover(files);
                                                         }}></MediaSelect>
                                        </div>

                                        <DefaultTextArea
                                            name="description"
                                            label="توضیحات محصول"
                                            placeholder="توضیحات محصول را وارد کنید"
                                        />

                                        <DefaultSelect
                                            name='categoryId'
                                            label='دسته بندی'
                                            defaultValue={`${product.category?.id}`}
                                            options={categories.map((category) => ({
                                                value: category.id,
                                                label: category.title
                                            })) as any}
                                        />

                                        <div className={`mb-4 w-full`}>
                                            <label className="label">
                                                <span className="label-text">محصول قابل فروش است؟</span>
                                            </label>
                                            <div className="flex">
                                                <div className="form-control mr-2">
                                                    <label className="label cursor-pointer"
                                                           onClick={() => handleRadioClick(true)}>
                                                        <Field
                                                            type="radio"
                                                            value="true"
                                                            name="is_product_active"
                                                            disabled={!isMerchantFilled}
                                                            className="radio checked:bg-[#DE0046]"
                                                            checked={values.is_product_active === true}
                                                            onChange={() => handleRadioClick(true)}
                                                        />
                                                        <span className="label-text mr-2">بله</span>
                                                    </label>
                                                </div>
                                                <div className="form-control mr-2">
                                                    <label className="label cursor-pointer"
                                                           onClick={() => handleRadioClick(false)}>
                                                        <Field
                                                            type="radio"
                                                            value="false"
                                                            name="is_product_active"
                                                            disabled={!isMerchantFilled}
                                                            className="radio checked:bg-[#DE0046]"
                                                            checked={values.is_product_active === false}
                                                            onChange={() => handleRadioClick(false)}
                                                        />
                                                        <span className="label-text mr-2">خیر</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <DefaultBooleanField name={'inventory_warning'} placeholder={'هشدار اتمام موجودی محصول'} bool={{yesTitle: 'بله', noTitle: 'خیر'}}/>
                                        <DefaultBooleanField name={'zero_inventory'} placeholder={'نمایش موجودی صفر'} bool={{yesTitle: 'بله', noTitle: 'خیر'}}/>
                                    </div>
                                    <div className="modal-action w-full flex justify-start">
                                        <button type="submit"
                                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"} hover:text-[#4192EF]`}>
                                            {submitLoading ? 'درحال ویرایش' : 'ویرایش'}
                                        </button>
                                        <div className={'w-0.5 h-2'}></div>
                                        <div
                                            onClick={() => onClose(false)}
                                            className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85"
                                        >
                                            بستن
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </DefaultModal>
    );
};

export default EditProductModal;
