import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import UserRequests from "requests/userRequests";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ChatRequests from "../../../requests/chatRequests";


// ریکوئست میزنیم
export const getAllUsersChat = createAsyncThunk(
    'usersChat/getAllUsersChat',
    async (): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ChatRequests.getAllUsers()

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

// export const update = createAsyncThunk(
//     'categories/update',
//     async (credentials: {id: number, title: string, parentId?: number}): Promise<DefaultResponse>=> {
//         try {
//             const response: AxiosResponse = await CategoryRequests.update(credentials)
//             if (response.status === 200) {
//                 return {status: response.status, data: credentials}
//             }else if (response.status === 422) {
//
//                 const errors = await response.data as LaravelValidationErrorType;
//                 toast422(errors);
//                 return {
//                     status: response.status,
//                     data: errors
//                 }
//             }else {
//                 toastError();
//                 return {
//                     status: response.status,
//                     data: null
//                 }
//             }
//         }catch (e) {
//             // toastError();
//             return {
//                 status: 500,
//                 data: null
//             }
//         }
//     }
// )
